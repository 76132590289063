export const getDisplayName = (
    user:
        | { firstname?: string | null; lastname?: string | null; email?: string | null }
        | null
        | undefined,
    withEmail = false,
    separators = ['<', '>']
) => {
    if (!user) return 'Anonymous';
    if (user.firstname && user.lastname) {
        let displayName = `${user.firstname} ${user.lastname}`;
        if (withEmail) displayName += ` ${separators[0]}${user.email}${separators[1]}`;
        return displayName;
    }
    return user.email || 'Anonymous';
};
