import styles from './ContactOption.module.scss';
import { ContactType } from '@he-novation/config/types/contact.types';
import { AvatarSize, AvatarUser } from '../../../../avatars/Avatar/AvatarUser';
import React from 'react';

type ContactOptionProps = {
    contact: ContactType;
};

export function ContactOption({ contact }: ContactOptionProps) {
    return (
        <div className={styles.contactOption}>
            <AvatarUser {...contact} size={AvatarSize.Tiny} />
            {(contact.firstname || contact.lastname) && (
                <span className={styles.name}>{`${contact.firstname} ${contact.lastname}`}</span>
            )}
            <span className={styles.email}>{contact.email}</span>
        </div>
    );
}
