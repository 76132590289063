import styles from './TaskStatusSelector.module.css';
import React from 'react';
import {
    taskStatusToColor,
    taskStatusToI18n
} from '@he-novation/config/constants/projects.constants';
import { TaskStatus } from '@he-novation/config/types/task.types';
import {
    ActionType,
    MultiActionButton
} from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { DiscText } from '@he-novation/design-system/components/text/DiscText/DiscText';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';

import { Translator, useTranslate } from '$hooks/useTranslate';

type TaskStatusSelectorProps = {
    status: TaskStatus;
    setTaskStatus: (status: TaskStatus) => void;
    withChevron?: boolean;
};

export function TaskStatusSelector({
    status,
    setTaskStatus,
    withChevron
}: TaskStatusSelectorProps) {
    const { t } = useTranslate();

    return (
        <MultiActionButton
            style={{
                background: taskStatusToColor(status)
            }}
            className={cn(styles.status, 'rotate-icon-270')}
            icon={withChevron ? [{ name: 'chevron', stroke: 'black', fill: 'none' }] : undefined}
            iconAfter={withChevron}
            direction={[DirectionX.RightInner, DirectionY.Bottom]}
            actions={statusToActions(setTaskStatus, t)}
        >
            {t(taskStatusToI18n(status))}
        </MultiActionButton>
    );
}

function statusToActions(setStatus: (status: TaskStatus) => void, t: Translator) {
    return [
        statusToAction(TaskStatus.TO_DO, setStatus, t),
        statusToAction(TaskStatus.IN_PROGRESS, setStatus, t),
        statusToAction(TaskStatus.TO_VALIDATE, setStatus, t),
        statusToAction(TaskStatus.DONE, setStatus, t)
    ];
}

function statusToAction(
    taskStatus: TaskStatus,
    setStatus: (status: TaskStatus) => void,
    t: Translator
): ActionType {
    return {
        id: taskStatus,
        children: (
            <DiscText color={taskStatusToColor(taskStatus)}>
                {t(taskStatusToI18n(taskStatus))}
            </DiscText>
        ),
        onClick: () => setStatus(taskStatus)
    };
}
