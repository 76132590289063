import { FEEDBACK } from '@he-novation/config/paths/modals.constants';

import { ModalFeedbackPayload } from '$components/modal/modals/ModalFeedback/ModalFeedback';
import { useModal } from '$hooks/useModal';

export function useFeedbackModal() {
    const { openModal, closeModal } = useModal();

    const openFeedbackModal = (
        message: string,
        timeout = 3000,
        payload?: Omit<ModalFeedbackPayload, 'timeout' | 'message'>
    ) => {
        openModal(FEEDBACK, {
            message,
            timeout,
            disableOverlay: true,
            alignTop: true,
            ...payload
        });
    };

    return {
        openFeedbackModal,
        closeModal
    };
}
