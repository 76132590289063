import styles from './PlaylistItem.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { FrontFolderContentFile } from '@he-novation/front-shared/types/file.front-types';
import cn from 'classnames';

import { FileStatus } from '$components/file/FileStatus/FileStatus';
import PlaylistThumbnail from '$components/Playlist/PlaylistThumbnail/PlaylistThumbnail';
import { folderRoleSelector } from '$redux/content/folder/folderSelectors';

type ItemPlaylistProps = {
    fileUuid: string;
    file: FrontFolderContentFile;
    time?: number | null;
    onClick?: (e: React.MouseEvent<HTMLDivElement>, fileUuid: string) => void;
};

export default function PlaylistItem({ file, fileUuid, onClick }: ItemPlaylistProps) {
    const { folderRole } = useSelector(folderRoleSelector);

    return (
        <Tooltip
            id={file.uuid}
            content={file.name}
            className={cn(styles.playlistItem, {
                isSelected: fileUuid === file.uuid,
                disabled: !file.isPlayable,
                clickable: !!onClick
            })}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                onClick?.(e, file.uuid);
            }}
        >
            <PlaylistThumbnail file={file} />

            <div className={styles.indicators}>
                <FileStatus
                    className={styles.status}
                    fileUuid={file.uuid}
                    fileVersion={file.version}
                    fileStatus={file.status}
                    finalVotes={file.finals}
                    role={folderRole}
                />
                {file.versions.filter((v) => !v.expired).length > 1 && (
                    <span className={styles.version}>V.{file.version + 1}</span>
                )}
            </div>
        </Tooltip>
    );
}
