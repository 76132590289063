import { EventUpdateBody } from '@he-novation/config/types/payloads/event.payload';
import {
    asyncEventCreateDeletedDay,
    asyncEventsFetch,
    asyncEventUpdate
} from '@he-novation/front-shared/async/event.async';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionProgress, asyncActionSuccess } from '$helpers/asyncAction';

export const EVENTS = 'EVENTS';
export const FETCH = `${EVENTS}/FETCH`;
export const UPDATE = `${EVENTS}/UPDATE`;
export const APPEND = `${EVENTS}/APPEND`;
export const WS_EVENT_UPSERT = `${WS_PREFIX_IN}${EVENTS}/UPSERT`;
export const WS_EVENT_DELETE = `${WS_PREFIX_IN}${EVENTS}/DELETE`;
export const WS_EVENT_DELETE_USER = `${WS_PREFIX_IN}${EVENTS}/DELETE_USER`;
export const RESET = `${EVENTS}/RESET`;
export const CREATE_DELETED_DAY = `${EVENTS}/CREATE_DELETED_DAY`;

export const fetchEvents =
    ({ start, end, projectUuid }: { start?: Date; end?: Date; projectUuid?: string } = {}) =>
    async (dispatch) => {
        dispatch({ type: asyncActionProgress(FETCH) });
        const events = await asyncEventsFetch(start, end, projectUuid);
        dispatch({ type: asyncActionSuccess(FETCH), events });
    };

export const appendEvents =
    ({ start, end, projectUuid }) =>
    async (dispatch) => {
        dispatch({ type: asyncActionProgress(APPEND), range: { start, end } });
        const events = await asyncEventsFetch(start, end, projectUuid);
        dispatch({ type: asyncActionSuccess(APPEND), events });
    };

export const fetchGlobalEvents =
    ({ start, end }: { start: Date; end: Date }) =>
    async (dispatch) => {
        dispatch({ type: asyncActionProgress(FETCH), query: 'global' });
        const events = await asyncEventsFetch(start, end);

        dispatch({
            type: asyncActionSuccess(FETCH),
            events
        });
    };

export const updateEvent =
    ({ uuid, ..._event }: EventUpdateBody & { uuid: string }) =>
    async (dispatch) => {
        dispatch({ type: UPDATE });
        await asyncEventUpdate(uuid, _event);
        dispatch({ type: asyncActionSuccess(UPDATE), event: { uuid, ..._event } });
    };

export const eventSocketActions = {
    sioEventUpsert: (event) => ({
        type: WS_EVENT_UPSERT,
        event
    }),
    sioEventDelete: (eventUuid) => ({
        type: WS_EVENT_DELETE,
        eventUuid
    }),
    sioEventDeleteUser: ({ eventUuid, userUuid }) => ({
        type: WS_EVENT_DELETE_USER,
        eventUuid,
        userUuid
    })
};

export const resetEvents = () => ({ type: RESET });

export const createDeletedDay =
    ({ eventUuid, deletedDay }: { eventUuid: string; deletedDay: Date }) =>
    async (dispatch) => {
        await asyncEventCreateDeletedDay(eventUuid, deletedDay);
        dispatch({ type: CREATE_DELETED_DAY, eventUuid, deletedDay });
    };
