import styles from './DataLayoutCastFigure.module.css';
import React from 'react';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { FrontCastFolderChild } from '@he-novation/front-shared/types/cast.front-types';
import classNames from 'classnames';

import { useCast } from '$hooks/useCast';

type PublicFolderFigureProps<T extends FrontCastFolderChild> = {
    className?: string;
    item: T;
};

export function DataLayoutCastFigure<T extends FrontCastFolderChild>({
    className,
    item
}: PublicFolderFigureProps<T>) {
    const { colors } = useCast();
    return (
        <div className={classNames('public-folder-figure', className, styles.figure)}>
            {item.type === 'file' && item.thumbnail ? (
                <img src={item.thumbnail} alt="" />
            ) : (
                <Icon
                    icon={{
                        name: item.type === 'file' ? 'file_plain' : 'folder_plain',
                        stroke: 'none',
                        fill: colors.textColor
                    }}
                />
            )}
        </div>
    );
}
