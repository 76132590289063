import React, { ComponentType } from 'react';

export function withHook<P extends Record<string, unknown>, O>(
    useHook: (props: P) => O,
    outputName?: string
) {
    return function WithHook(C: ComponentType<P>) {
        return function WithHookComponent(props: P) {
            const hookProps = useHook(props);

            if (outputName) {
                props = { ...props, outputName: hookProps[outputName] };
            }
            return <C {...props} />;
        };
    };
}
