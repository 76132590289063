import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { keyToKeyCode } from '@he-novation/front-shared/utils/keys';

import { keyboardListenersSelector } from '$redux/ui/uiSelectors';

const defaultOptions = {
    useKeyboardListenersSelector: true
};
export const useOnKeyDown = <T extends number | string>(
    keys: T | T[],
    callback: (e: KeyboardEvent, key: T) => void,
    dependencies: any[] = [],
    options?: {
        useKeyboardListenersSelector?: boolean;
    }
) => {
    const o = options ? { ...defaultOptions, ...options } : defaultOptions;
    const { keyboardListeners } = useSelector(keyboardListenersSelector);
    useEffect(() => {
        if (!Array.isArray(keys)) keys = [keys];
        const keyCodes = keys.map(keyToKeyCode);
        const listener = (e) => {
            const i = keyCodes.indexOf(e.keyCode);
            if (i > -1 && (!o.useKeyboardListenersSelector || keyboardListeners)) {
                return callback(e, keys[i]);
            }
        };

        window.addEventListener('keydown', listener);
        return () => {
            window.removeEventListener('keydown', listener);
        };
    }, [keyboardListeners, ...dependencies]);
};
