import update from 'immutability-helper';

import { I18nAction, I18nActionResult, I18nState } from '$redux/i18n/i18nTypes';

export const i18nReducerName = 'i18n';
export const i18nInitialState: I18nState = {
    languagesAndCountryCodes: []
};
export const i18nReducer = (state: I18nState = i18nInitialState, action: I18nActionResult) => {
    switch (action.type) {
        case I18nAction.LanguagesAndCountryCodesFetchSuccess:
            return update(state, {
                languagesAndCountryCodes: { $set: action.languagesAndCountryCodes }
            });
    }
    return state;
};
