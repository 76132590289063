export enum Colors {
    Alert = '#ff5050',
    Success = '#49f281',
    Light = '#efefef',
    Medium = '#98999a',
    Dark = '#16181D',
    Darkest = '#000'
}

export enum Key {
    Enter = 'enter',
    Escape = 'escape',
    Down = 'down',
    Left = 'left',
    Right = 'right',
    Shift = 'shift',
    Tab = 'tab',
    Up = 'up',
    D = 'd',
    M = 'm'
}

export enum ProjectRole {
    Manager = 'PROJECT_MANAGER',
    Reporter = 'PROJECT_REPORTER',
    Provider = 'PROJECT_PROVIDER',
    Spectator = 'PROJECT_SPECTATOR',
    Public = 'PROJECT_PUBLIC'
}

export enum Theme {
    Dark = 'theme-dark',
    Light = 'theme-light'
}

export enum IconSize {
    XS = 'xs',
    S = 's',
    L = 'l'
}

export enum WidgetTone {
    Dark = 't-dark',
    Light = 't-light',
    Green = 't-green',
    Red = 't-red'
}
