export const CONFIG = 'CONFIG';
export const SET_SIZE = `${CONFIG}/SET_SIZE`;

export const setSize = (width: number, height: number) => ({
    type: SET_SIZE,
    width,
    height
});

export const CONFIG_SET_BRANDING = `${CONFIG}/SET_BRANDING`;

export function configSetBranding(branding: string) {
    return {
        type: CONFIG_SET_BRANDING,
        branding
    };
}
