import './RichNumber.scss';
import React, { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { FieldComponentProps } from 'react-modular-forms';
import ReactDOM from 'react-dom';
import {
    DirectionX,
    DirectionY,
    getAbsolutePosition
} from '../../../../../utils/getAbsolutePosition';
import { onClickOutside } from '../../../../../utils/onClickOutside';
import cn from 'classnames';

type RichNumberProps = React.HTMLProps<HTMLInputElement> &
    FieldComponentProps & {
        options: { label: ReactNode; value: any }[];
        allowNegativeNumbers?: boolean;
    };
export function RichNumber({
    type,
    disabled,
    value,
    setValue,
    onBlur,
    onFocus,
    onChange,
    errors,
    validation,
    componentRef,
    setComponentRef,
    children,
    formId,
    errorHtmlElement,
    options,
    allowNegativeNumbers,
    onInput,
    ...intrinsic
}: RichNumberProps) {
    const absoluteRef = useRef<HTMLElement>();
    const [displayMenu, setDisplayMenu] = useState(false);
    const [style, setStyle] = useState({});

    useEffect(() => {
        onClickOutside(
            componentRef,
            () => {
                setDisplayMenu(false);
            },
            { excludedClasses: ['rich-number-menu'] }
        );
    }, []);

    useEffect(() => {
        if (displayMenu)
            setStyle({
                ...getAbsolutePosition(
                    componentRef,
                    absoluteRef,
                    [DirectionX.LeftInner, DirectionY.Bottom],
                    true
                ),
                position: 'absolute',
                zIndex: 1000
            });
    }, [displayMenu]);

    const sharedProps = {
        ref: componentRef,
        onChange,
        onFocus: (e: SyntheticEvent) => {
            setDisplayMenu(true);
            onFocus?.(e);
        },
        onBlur,
        onInput: (e: any) => {
            if (
                !allowNegativeNumbers &&
                e.target.value.includes('-') &&
                !options?.find((o) => o.value === parseInt(e.target.value))
            ) {
                e.target.value = e.target.value.replace(/-\d/, '');
            }
            onInput?.(e);
        },
        'aria-invalid': !!errors,
        'aria-required': validation?.required,
        disabled,
        value,
        ...intrinsic
    };

    const activeOption = options.find(
        (o) => o.value === (typeof value === 'string' ? parseInt(value) : value)
    );

    return (
        <>
            <input
                type="number"
                {...sharedProps}
                className={cn(sharedProps.className, activeOption && 'has-option-label')}
            />{' '}
            {children}
            {activeOption && <span className="option-label">{activeOption.label}</span>}
            {ReactDOM.createPortal(
                <ul
                    className="rich-number-menu"
                    //@ts-expect-error weird ref
                    ref={absoluteRef}
                    style={displayMenu ? style : { display: 'none' }}
                >
                    {options.map((o) => (
                        <li key={o.value}>
                            <button
                                type="button"
                                onClick={() => {
                                    setValue(o.value);
                                    setTimeout(() => setDisplayMenu(false), 100);
                                }}
                            >
                                {o.label}
                            </button>
                        </li>
                    ))}
                </ul>,
                document.body
            )}
        </>
    );
}
