import sqlDatesToJsDates from '../../../content/projects/maps/sqlDatesToJsDates';
import mapFetchTeamsToUpdate from './mapFetchTeamsToUpdate';

const mapFetchProjectToUpdate = ({ tasks, folder_uuid, folder_name, projectRole, ...project }) => {
    const update = {};

    if (typeof folder_uuid !== 'undefined') update.folderUuid = folder_uuid;
    if (typeof folder_name !== 'undefined') update.folderName = folder_name;
    if (typeof projectRole !== 'undefined') update.projectRole = projectRole;
    if (typeof tasks !== 'undefined') update.tasks = tasks.map(sqlDatesToJsDates);
    return {
        ...update,
        ...sqlDatesToJsDates(project)
    };
};

export default mapFetchProjectToUpdate;
