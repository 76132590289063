import React from 'react';

import { KeyValue, KeyValueList } from '$components/base/KeyValueList/KeyValueList';

export type FileInfoMetadataCodecProps = {
    ffprobeResult: Record<string, any>;
    className?: string;
};

export function FileInfoMetadataCodec({ ffprobeResult, className }: FileInfoMetadataCodecProps) {
    return (
        <KeyValueList
            className={className}
            list={Object.keys(ffprobeResult)
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .reduce((acc, key) => {
                    const isObject = typeof ffprobeResult[key] === 'object';

                    if (isObject && key === 'AudioStreams') {
                        const audioStreams = ffprobeResult[key];
                        audioStreams.forEach((audioStream, i) => {
                            Object.keys(audioStream).forEach((key) => {
                                if (typeof audioStream[key] !== 'object') {
                                    acc.push({
                                        key: `AudioStream ${i + 1} ${key}`,
                                        value: audioStream[key]
                                    });
                                }
                            });
                        });
                    } else if (!isObject) {
                        acc.push({
                            key,
                            value: ffprobeResult[key]
                        });
                    }

                    return acc;
                }, [] as KeyValue[])}
        />
    );
}
