import styles from './ModalBottomButtons.module.css';
import React from 'react';
import { Button, ButtonProps } from '@he-novation/design-system/components/buttons/Button/Button';
import cn from 'classnames';

type ModalBottomButtonsProps = {
    buttons: (ButtonProps | undefined)[];
};

export default function ModalBottomButtons({ buttons }: ModalBottomButtonsProps) {
    return (
        <div className={cn(styles.modalBottomButtons, 'c-modal-bottom-buttons')}>
            {buttons
                .filter((b) => !!b)
                .map((b, i) => (
                    <Button key={i} {...b} />
                ))}
        </div>
    );
}
