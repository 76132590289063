import React from 'react';

import { KeyValueList } from '$components/base/KeyValueList/KeyValueList';

export type FileInfoMetadataExifProps = {
    exifResult: Record<string, any>;
    className?: string;
};

export function FileInfoMetadataExif({ exifResult, className }: FileInfoMetadataExifProps) {
    return (
        <KeyValueList
            className={className}
            list={Object.keys(exifResult)
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .map((key) => ({
                    key,
                    value: typeof exifResult[key] !== 'object' ? exifResult[key] : null
                }))}
        />
    );
}
