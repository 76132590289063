import React, { forwardRef, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { set, setFilters } from '$redux/route/routeActions';
import { routeSelector } from '$redux/route/routeSelectors';

export const isActive = (
    activeClassName: string | undefined,
    href: string | undefined,
    route: string,
    isRoot?: boolean
) => (activeClassName && (route === href || (isRoot && route === '/')) ? activeClassName : '');

type LinkProps = {
    className?: string;
    activeClassName?: string;
    children: React.ReactNode | React.ReactNode[];
    disabled?: boolean;
    download?: string;
    href?: string;
    isRoot?: boolean;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    onTouchStart?: (e: React.TouchEvent<HTMLAnchorElement>) => void;
    route?: string;
    target?: string;
    external?: boolean;
    filters?: Record<string, unknown>;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link = forwardRef(function InnerLink(
    {
        className,
        activeClassName,
        children,
        disabled,
        download,
        href,
        isRoot,
        onClick,
        target,
        external,
        onTouchStart,
        filters,
        ...rest
    }: LinkProps,
    ref: RefObject<HTMLAnchorElement>
) {
    const dispatch = useDispatch();
    const { route } = useSelector(routeSelector);
    return (
        <a
            download={download}
            className={`${className} ${isActive(activeClassName, href, route, isRoot)}`}
            href={disabled ? undefined : href}
            onClick={
                disabled || external
                    ? undefined
                    : (e) => {
                          if (typeof onClick === 'function') onClick(e);

                          if (!e.defaultPrevented) {
                              e.preventDefault();
                              dispatch(set(href));
                              if (filters) dispatch(setFilters(filters));
                          }
                      }
            }
            onTouchStart={(e) => {
                if (!onTouchStart) return;
                if (typeof onTouchStart === 'function') onTouchStart(e);
                e.preventDefault();
                dispatch(set(href));
                if (filters) dispatch(setFilters(filters));
            }}
            ref={ref}
            target={target}
            {...rest}
        >
            {children}
        </a>
    );
});

export default Link;
