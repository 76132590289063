import { atom } from 'jotai';

export const videoInitialState = {
    isMuted: false,
    isPlaying: false,
    isLooping: false,
    currentTime: 0,
    targetTime: undefined,
    volume: 0.5,
    playbackRate: 1,
    frameRate: 0,
    timecodestart: undefined,
    timecodeWithTimecodeStart: '00:00:00;00',
    secondsOffset: 0,
    duration: 0,
    frame: 0
};
export type VideoAtom = {
    isMuted: boolean;
    isPlaying: boolean;
    isLooping: boolean;
    currentTime: number;
    targetTime: number | undefined;
    playbackRate: number;
    volume: number;
    frameRate: number;
    timecodestart?: string;
    timecodeWithTimecodeStart: string;
    secondsOffset: number;
    duration: number;
    frame: number;
};
export const videoAtom = atom<VideoAtom>(videoInitialState);
