export const initialsFromEmail = (email: any = '') => {
    try {
        if (email.replace(/@.*/, '').indexOf('.') > -1) {
            email = email.split('.');
            return email[0][0] + email[1][0];
        }
        return email.substr(0, 2);
    } catch (e) {
        return '';
    }
};
