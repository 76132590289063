import styles from './AttachementButton.module.css';
import React, { useRef } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';

type AttachmentButtonProps = {
    attachments: File[];
    setAttachments: (attachments: File[]) => void;
};
export function AttachmentButton({ attachments, setAttachments }: AttachmentButtonProps) {
    const inputFileRef = useRef<HTMLInputElement>(null);
    return (
        <>
            <input
                className={styles.input}
                type="file"
                ref={inputFileRef}
                onChange={(e) => {
                    setAttachments([...attachments, ...Array.from(e.currentTarget.files!)]);
                    e.currentTarget.value = '';
                }}
            />
            <Button
                type="button"
                onClick={() => inputFileRef.current?.click()}
                tone={ButtonTone.Hoverable}
                icon={[
                    { name: 'paper_clip', stroke: 'white', fill: 'none' },
                    { name: 'plus', stroke: 'white', fill: 'none' }
                ]}
            />
        </>
    );
}
