import styles from './ProgressCircle.module.css';
import React, { CSSProperties, ReactNode, SyntheticEvent, useMemo } from 'react';
import cn from 'classnames';
import { colorText } from '../../../constants/constants.style';

type ProgressCircleProps = {
    value: number;
    total: number;
    color?: string;
    textColor?: string;
    className?: string;
    children?: ReactNode | ReactNode[];
    onClick?: (e: SyntheticEvent) => void;
};

export function ProgressCircle({
    total,
    value,
    color = colorText,
    textColor,
    className,
    children,
    onClick
}: ProgressCircleProps) {
    const percentage = useMemo(() => {
        if (total !== 0) {
            const _percentage = (value / total) * 100;
            if (!isNaN(_percentage)) {
                return Math.round(_percentage);
            }
        }
        return 0;
    }, [value, total]);

    const style = useMemo(() => {
        return {
            color,
            '--angle': 3.6 * percentage + 'deg'
        } as CSSProperties;
    }, [percentage]);

    return (
        <div
            className={cn(styles.progressCircle, 'c-progress-circle', className)}
            onClick={onClick}
            style={style}
        >
            <div className={styles.backgroundCircle}>
                <div className={styles.progressCircle}></div>
            </div>

            <p className={styles.progressValue} style={{ color: textColor || color }}>
                {children ? children : percentage + '%'}
            </p>
        </div>
    );
}
