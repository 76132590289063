import { AreaSelectionState } from '@he-novation/config/types/area-selection.types';
import update from 'immutability-helper';

import {
    AREA_SELECTION_DISPLAY,
    AREA_SELECTION_PUSH_SHAPE,
    AREA_SELECTION_RESET,
    AREA_SELECTION_SPLICE_SHAPE,
    AREA_SELECTION_START_SHAPE,
    AREA_SELECTION_TOGGLE
} from '$redux/content/areaSelection/areaSelectionActions';

export const areaSelectionInitialState: AreaSelectionState = {
    toggled: false,
    selection: []
};
export const areaSelectionReducer = (
    state: AreaSelectionState = areaSelectionInitialState,
    action
) => {
    switch (action.type) {
        case AREA_SELECTION_START_SHAPE:
            return update(state, {
                toggled: { $set: true },
                type: { $set: action.shape },
                uuid: { $set: action.uuid }
            });

        case AREA_SELECTION_PUSH_SHAPE:
            return update(state, {
                selection: {
                    $push: [action.data]
                }
            });

        case AREA_SELECTION_SPLICE_SHAPE:
            return update(state, {
                selection: {
                    $splice: [[action.index, 1]]
                }
            });

        case AREA_SELECTION_RESET:
            return update(state, {
                display: {
                    $set: undefined
                },
                selection: {
                    $set: []
                }
            });

        case AREA_SELECTION_TOGGLE:
            return update(state, {
                toggled: {
                    $set: typeof action.toggle === 'undefined' ? !state.toggled : action.toggle
                }
            });

        case AREA_SELECTION_DISPLAY:
            return update(state, {
                toggled: {
                    $set: !!action.data
                },
                display: {
                    $set: action.data
                }
            });

        default:
            return state;
    }
};
