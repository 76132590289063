import { property } from 'lodash/fp';

export function sorterFunctionFactory<T extends Record<string, any>>(
    type: 'bool' | 'alphanumeric' | 'date' | 'number',
    key: string
) {
    switch (type) {
        case 'bool':
            return (_a: T, _b: T) => {
                const a: boolean | undefined = property(key)(_a);
                const b: boolean | undefined = property(key)(_b);
                if ((a && b) || (!a && !b)) return 0;
                if (a && !b) return -1;
                return 1;
            };
        case 'alphanumeric':
            return (_a: T, _b: T) => {
                const a: string | number | undefined = property(key)(_a);
                const b: string | number | undefined = property(key)(_b);
                if (typeof a === 'undefined' || a === null) return 1;
                if (typeof b === 'undefined' || b === null) return -1;
                if (typeof a === 'number' && typeof b === 'number') return a - b;
                return a.toString().localeCompare(b.toString());
            };
        case 'date':
            return (_a: T, _b: T) => {
                const a: Date | undefined | null = property(key)(_a);
                const b: Date | undefined | null = property(key)(_b);
                if (!(a instanceof Date)) return -1;
                if (!(b instanceof Date)) return 1;
                const aT = a.getTime();
                const bT = b.getTime();
                if (aT === bT) return 0;
                if (aT < bT) return -1;
                return 1;
            };
        case 'number':
            return (_a: T, _b: T) => {
                const a: number | undefined = property(key)(_a);
                const b: number | undefined = property(key)(_b);
                if (typeof a === 'undefined') return -1;
                if (typeof b === 'undefined') return 1;
                return a - b;
            };
    }
}

export function sortByResolution(
    a: { resolution?: [number, number] | null },
    b: { resolution?: [number, number] | null }
) {
    // If both groups have resolution
    if (a.resolution && b.resolution) {
        // Compare x resolution first
        if (a.resolution[0] !== b.resolution[0]) {
            return a.resolution[0] - b.resolution[0];
        }
        // If x resolution is the same, compare y resolution
        return a.resolution[1] - b.resolution[1];
    }
    // If only one group has resolution, the one with resolution comes first
    if (a.resolution) {
        return -1;
    }
    if (b.resolution) {
        return 1;
    }
    // If neither group has resolution, they are equal
    return 0;
}
