import styles from './Error.module.css';
import React, { ReactNode } from 'react';
import cn from 'classnames';

type ErrorProps = {
    error?: string | ReactNode;
};

export const Error: React.FC<ErrorProps> = ({ error }) => {
    const props =
        typeof error === 'string'
            ? { dangerouslySetInnerHTML: { __html: error } }
            : { children: error };
    return <div className={cn(styles.error, 'c-error')} {...props} />;
};
