export const toNumberOfDigits = (
    num: number | string,
    digits: number,
    clamp: boolean = false,
    reverse = false
) => {
    if (typeof num === 'number') num = num.toString();
    while (num.length < digits) {
        num = reverse ? num + '0' : '0' + num;
    }
    if (clamp && num.length > digits) {
        num = parseFloat('0.' + num)
            .toFixed(digits)
            .replace(/^0\./, '');
    }
    return num;
};
const doubleDigits = (num: number): string => {
    if (!num) return '00';
    return num > 9 ? num.toString() : `0${num}`;
};

export const dropFrames = (frameRate: number) => {
    if (!frameRate) return false;
    return frameRate === 29.97 || frameRate === 59.94;
};

export const getDroppedFrames = (frames: number, frameRate: number) => {
    if (!frames || !frameRate) return 0;
    const framesToDropPerMinute = Math.round(frameRate * 0.066666);
    const framesPer10Minutes = Math.round(frameRate * 600);
    const framesPerMinute = Math.round(frameRate) * 60 - framesToDropPerMinute;
    const tenMinutesCounts = Math.floor(frames / framesPer10Minutes);
    const minutesCount = frames % framesPer10Minutes;
    if (minutesCount > framesToDropPerMinute) {
        return (
            framesToDropPerMinute * 9 * tenMinutesCounts +
            framesToDropPerMinute *
                Math.floor((minutesCount - framesToDropPerMinute) / framesPerMinute)
        );
    } else {
        return framesToDropPerMinute * 9 * tenMinutesCounts;
    }
};

export const secondsToFrame = (seconds: number, frameRate: number, frameOffset = 0) => {
    if (!seconds || !frameRate) return 0;
    return Math.max(0, Math.ceil(seconds * frameRate) + frameOffset);
};

export const frameToTimeCode = (frame: number, ceiledFrameRate: number, dropFrames: boolean) => {
    if (!frame || !ceiledFrameRate) return '00:00:00:00';
    const totalSeconds = Math.floor(frame / ceiledFrameRate);
    const date = new Date(totalSeconds * 1000);
    const remainingFrames = Math.floor(frame % ceiledFrameRate);
    return `${doubleDigits(date.getUTCHours())}:${doubleDigits(
        date.getUTCMinutes()
    )}:${doubleDigits(date.getUTCSeconds())}${dropFrames ? ';' : ':'}${doubleDigits(
        remainingFrames
    )}`;
};

export const secondsToTimeCode = (seconds: number, frameRate: number, frameOffset = 0) => {
    if (!seconds || !frameRate) return '00:00:00:00';
    if (frameRate === 23.98) frameRate = 23.976;
    const dropFrame = dropFrames(frameRate);
    const ceiledFrameRate = Math.ceil(frameRate);
    let frame = secondsToFrame(seconds, frameRate, frameOffset);
    let f;

    if (dropFrame) {
        frame += getDroppedFrames(frame, frameRate);
        f = Math.floor(frame % ceiledFrameRate);
    } else {
        f = Math.floor(frame % ceiledFrameRate);
    }

    const s = Math.floor(frame / ceiledFrameRate) % 60;
    const m = Math.floor(Math.floor(frame / ceiledFrameRate) / 60) % 60;
    const h = Math.floor(Math.floor(Math.floor(frame / ceiledFrameRate) / 60) / 60);

    return `${doubleDigits(h)}:${doubleDigits(m)}:${doubleDigits(s)}${
        dropFrame ? ';' : ':'
    }${doubleDigits(f)}`;
};

export const timeCodeToFrames = (timeCode: string, frameRate: number, frameOffset = 0) => {
    if (typeof timeCode !== 'string' || timeCode.length < 7 || !frameRate) return 0;
    if (frameRate === 23.98) frameRate = 23.976;
    const dropFrame = dropFrames(frameRate);
    const [h, m, s, f] = timeCode.split(/[;:]/g).map((n) => parseInt(n));

    const ceiledFrameRate = Math.ceil(frameRate);
    const framesPerMinutes = ceiledFrameRate * 60;
    const framesPerHour = framesPerMinutes * 60;

    if (dropFrame) {
        const framesToDropPerMinute = Math.round(frameRate * 0.066666);
        const totalMinutes = 60 * h + m;
        const frames =
            framesPerHour * h +
            framesPerMinutes * m +
            ceiledFrameRate * s +
            f -
            framesToDropPerMinute * (totalMinutes - Math.floor(totalMinutes / 10)) +
            frameOffset;
        return Math.max(0, frames);
    } else {
        return Math.max(
            framesPerHour * h + framesPerMinutes * m + ceiledFrameRate * s + f + frameOffset
        );
    }
};

export const timeCodeToSeconds = (timeCode: string, frameRate: number, frameOffset = 0) => {
    if (typeof timeCode !== 'string' || timeCode.length < 7 || !frameRate) return 0;
    if (frameRate === 23.98) frameRate = 23.976;
    const frames = timeCodeToFrames(timeCode.replace(/\.(\d\d\d?)/, ':$1'), frameRate, frameOffset);
    return frames / frameRate;
};

export const timeCodeFormat = (frameRate: number, timeCode: string) => {
    if (typeof timeCode !== 'string' || timeCode.length < 7 || !frameRate) return '00:00:00:00';
    if (frameRate === 23.98) frameRate = 23.976;
    const dropFrame = dropFrames(frameRate);
    const [h, m, s, f] = timeCode.split(/[;:]/g).map((n) => parseInt(n));
    return `${doubleDigits(h)}:${doubleDigits(m)}:${doubleDigits(s)}${
        dropFrame ? ';' : ':'
    }${doubleDigits(f)}`;
};

export const msTimeCodeToSeconds = (msTimeCode: string) => {
    const parts = msTimeCode.split(/[:.;]/);
    return (
        parseInt(parts[0]) * 3600 +
        parseInt(parts[1]) * 60 +
        parseInt(parts[2]) +
        parseInt(parts[3]) / 1000
    );
};

export const timeToColonTimeCode = (
    time: number,
    options: {
        colon?: boolean;
        fixedMS?: number;
        log?: boolean;
        frameRate?: number;
        frames?: boolean;
    } = {}
) => {
    let seconds = Math.floor(time);
    const ms = toNumberOfDigits(
        time.toString().replace(/^\d+(?:[.,](\d*))?$/, '$1') || '0',
        options.fixedMS || 3,
        true,
        true
    );

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    seconds = seconds - hours * 3600 - minutes * 60;
    const first = `${toNumberOfDigits(hours, 2)}:${toNumberOfDigits(minutes, 2)}:${toNumberOfDigits(
        seconds,
        2
    )}${options.colon ? ':' : '.'}`;

    const last =
        options.frames && options.frameRate
            ? toNumberOfDigits(Math.floor(parseInt(ms) / 1000 / (1 / options.frameRate)), 2, true)
            : ms;
    return first + last;
};
