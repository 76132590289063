import styles from './TimeCodes.module.css';
import React, { useState } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import cn from 'classnames';
import { useOnKeyDown } from '../../../../hooks/useOnKeyDown';

type TimeCodesProps = {
    timecode: string;
    tcIn: string;
    tcOut: string;
    setTcIn?: (value: string) => void;
    setTcOut?: (value: string) => void;
    separator?: React.ReactNode;
    formId: string;
    mask: string;
    timeCodeToSeconds: (timeCode: string) => number;
    disabled?: boolean;
    className?: string;
};
export default function TimeCodes({
    timecode,
    tcIn: _tcIn,
    tcOut: _tcOut,
    separator = <Icon icon="chevron" className={styles.separator} />,
    mask,
    formId,
    timeCodeToSeconds,
    disabled,
    className
}: TimeCodesProps) {
    const [tcIn, setTcIn] = useState<string>(_tcIn);
    const [tcOut, setTcOut] = useState<string>(_tcOut);

    useOnKeyDown('i', () => {
        setTcIn?.(timecode);
    });

    useOnKeyDown('o', () => {
        setTcOut?.(timecode);
    });

    const replacement = { _: /\d/ };

    return (
        <div className={cn(styles.timecodes, className, disabled && 'is-disabled')}>
            <Button
                className={styles.timecodeButton}
                type="button"
                tone={ButtonTone.Hoverable}
                onClick={() => setTcIn(timecode)}
                icon={[{ name: 'pin', fill: 'white' }]}
                disabled={disabled}
            />
            <FormField
                className={styles.timecode}
                formId={formId}
                name="tcIn"
                value={tcIn}
                type={'masked-field'}
                mask={mask}
                replacement={replacement}
                disabled={disabled}
            />
            {separator}
            <FormField
                className={styles.timecode}
                formId={formId}
                name="tcOut"
                value={tcOut}
                type={'masked-field'}
                mask={mask}
                replacement={replacement}
                disabled={disabled}
            />
            <Button
                className={styles.timecodeButton}
                type="button"
                tone={ButtonTone.Hoverable}
                onClick={() => {
                    const secondsOut = timeCodeToSeconds(timecode);
                    const secondsIn = timeCodeToSeconds(tcIn!);

                    if (secondsOut < secondsIn) {
                        setTcIn(timecode);
                    }

                    setTcOut(timecode);
                }}
                icon={[{ name: 'pin', fill: 'white' }]}
                disabled={disabled}
            />
        </div>
    );
}
