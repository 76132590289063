const mapFetchFileInfoToUpdate = (response) => ({
    folderUuid: response.parent,
    uuid: response.uuid,
    versionLocked: !!response.final,
    fileInfo: {
        general: {
            name: response.name,
            created: response.created,
            updated: response.updated,
            mime_type: response.mime_type,
            size: response.size
        },
        exif_result: response.exif_result || {},
        metadata_ale: response.metadata_ale || {},
        ffprobe_result: response.ffprobe_result || {},
        metadata: response.metadata || {},
        tags: response.tags || []
    }
});

export default mapFetchFileInfoToUpdate;
