import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { folderLink, projectsLink } from '@he-novation/config/paths/herawFrontUris';
import { signInLink } from '@he-novation/config/paths/herawLoginPaths';
import strToQueryParams from '@he-novation/config/paths/utils/strToQueryParams';
import * as sockets from '@he-novation/config/utils/sockets/sockets.client';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { DesignSystemProvider } from '@he-novation/design-system/DesignSystemProvider';
import { Theme } from '@he-novation/design-system/enums';
import {
    fetchPlugins,
    fetchPluginsEnabled,
    fetchStorage,
    fetchUserAppInfos,
    userLicensesFetch
} from '@he-novation/front-shared/async/user.async';
import { useHydrateAtoms } from 'jotai/utils';
import io from 'socket.io-client';
import App from './App';

import { localeAtom } from '$atoms/i18n-atoms';
import withProvider from '$components/HOC/withProvider';
import Link from '$components/router/Link';
import { setDatePickerConfiguration } from '$helpers/datepicker';
import { setActiveClientCss } from '$helpers/setActiveClientCSS';
import { useFeedbackModal } from '$hooks/useFeedbackModal';
import { mapActionsObjectWithDispatch, useSocketIO } from '$hooks/useSocketIO';
import { useTranslate } from '$hooks/useTranslate';
import { clientSocketActions } from '$redux/client/clientActions';
import { clientUuidSelector } from '$redux/client/clientSelectors';
import { setSize } from '$redux/config/configActions';
import { activeBrandingSelector } from '$redux/config/configSelectors';
import { setContacts } from '$redux/content/contacts/contactsActions';
import { fetchContacts } from '$redux/content/contacts/contactsAsync';
import { set } from '$redux/route/routeActions';
import store from '$redux/store';
import {
    setAccount,
    setAppInfos,
    setPlugins,
    setStorageAndLicenses
} from '$redux/user/userActions';

function getLoginRoute(plugins: string[], projects: number) {
    const hasProjectPlugin = plugins && plugins.includes('projects');
    // User Has Project Plugin Or Assigned on a Project
    if (hasProjectPlugin || projects) {
        return projectsLink();
    }
    return folderLink();
}

function useInitialFetch({
    host,
    locale,
    account
}: {
    host: string;
    locale: 'fr' | 'de' | 'en';
    account: any;
}) {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { openFeedbackModal } = useFeedbackModal();
    const { subscribe, unsubscribe } = useSocketIO();

    useEffect(() => {
        return () => {
            unsubscribe();
        };
    }, []);
    return async function initialFetch(): Promise<{
        locale: 'en' | 'de' | 'fr';
        isTeamsApp: boolean;
    }> {
        const { login } = strToQueryParams(window.location.href);
        initWebsockets(host);
        let isTeamsApp = false;
        const isCast = window.location.pathname.startsWith('/cast/');

        if (!account && !isCast) {
            window.location.href = signInLink('fr', {
                redirect: window.location.pathname.replace('?', '&')
            });
            return { locale, isTeamsApp };
        }

        if (account) {
            dispatch(setAccount(account));
            isTeamsApp = account.isTeamsApp;
            const [appInfos, plugins, pluginsEnabled] = await Promise.all([
                fetchUserAppInfos().then((r) => {
                    dispatch(setAppInfos(r));
                    return r;
                }),
                fetchPlugins(),
                fetchPluginsEnabled()
            ]);

            dispatch(setPlugins(plugins, pluginsEnabled));

            fetchContacts().then((contacts) => dispatch(setContacts(contacts)));

            if (!isCast) {
                const [storage, licenses] = await Promise.all([
                    fetchStorage(),
                    userLicensesFetch()
                ]);
                dispatch(setStorageAndLicenses(storage, licenses));
                if (login) {
                    dispatch(set(getLoginRoute(plugins, appInfos.projects)));
                }
            }
        }

        const action = async () => {
            openFeedbackModal(t('misc.New version available'), 0, {
                extraButtons: [
                    {
                        children: t('common.Reload'),
                        onClick: () => window.location.reload(),
                        tone: ButtonTone.Outlined,
                        theme: Theme.Light
                    }
                ]
            });
        };

        subscribe?.({
            room: 'misc',
            actions: {
                sioMiscReload: action,
                sioMiscNewVersion: action
            },
            socket: 'misc'
        });
        setDatePickerConfiguration(locale);
        return { locale, isTeamsApp };
    };
}

export function AppContainerInner({
    host,
    locale,
    account
}: {
    host: string;
    locale: 'fr' | 'de' | 'en';
    account: any;
}) {
    useHydrateAtoms([[localeAtom, locale]]);
    const dispatch = useDispatch();
    const { subscribe, unsubscribe } = useSocketIO();

    const fetch = useInitialFetch({ host, locale, account });

    const { activeBranding } = useSelector(activeBrandingSelector);
    const { clientUuid } = useSelector(clientUuidSelector);

    const [data, setData] = useState<{ locale: 'fr' | 'de' | 'en'; isTeamsApp: boolean } | null>(
        null
    );

    useEffect(() => {
        dispatch(setSize(window.innerWidth, window.innerHeight));
        window.addEventListener('resize', () =>
            dispatch(setSize(window.innerWidth, window.innerHeight))
        );
        fetch().then(({ locale, isTeamsApp }) => {
            setData({
                locale,
                isTeamsApp
            });
        });
    }, []);

    useEffect(() => {
        if (clientUuid) {
            subscribe?.({
                socket: 'client',
                room: clientUuid,
                actions: mapActionsObjectWithDispatch(dispatch, clientSocketActions)
            });
        }
        return () => unsubscribe();
    }, [clientUuid]);

    useEffect(() => {
        setActiveClientCss(activeBranding);
    }, [activeBranding]);

    return data ? (
        <DesignSystemProvider locale={data.locale} RouterLinkComponent={Link}>
            <App isTeamsApp={data.isTeamsApp} />
        </DesignSystemProvider>
    ) : (
        <Loader />
    );
}

export const AppContainer = withProvider(store)(AppContainerInner);

function initWebsockets(websocketHost: string) {
    const host =
        process.env.WEBSOCKET_HOST && process.env.WEBSOCKET_HOST !== 'NULL'
            ? process.env.WEBSOCKET_HOST
            : websocketHost;
    const hostAndPort = host + ':443';
    sockets.misc.clientInit(io, hostAndPort);
    sockets.activity.clientInit(io, hostAndPort);
    sockets.user.clientInit(io, hostAndPort);
    sockets.companies.clientInit(io, hostAndPort);
    sockets.events.clientInit(io, hostAndPort);
    sockets.items.clientInit(io, hostAndPort);
    sockets.projects.clientInit(io, hostAndPort);
    sockets.archives.clientInit(io, hostAndPort);
    sockets.folder.clientInit(io, hostAndPort);
    sockets.note.clientInit(io, hostAndPort);
    sockets.subtitles.clientInit(io, hostAndPort);
    sockets.folders.clientInit(io, hostAndPort);
    sockets.casts.clientInit(io, hostAndPort);
    sockets.client.clientInit(io, hostAndPort);
}

export default AppContainer;
