const toDate = (date) =>
    !date || date === '0000-00-00 00:00:00' || date === '0000-00-00' ? undefined : new Date(date);

const sqlDatesToJsDates = ({
    created,
    updated,
    start_date,
    startDate,
    endDate,
    end_date,
    estimate_end_date,
    estimatedEndDate,
    expires,
    ...rest
}: any) => {
    const dates: any = {};
    if (typeof created !== 'undefined') dates.created = toDate(created);
    if (typeof updated !== 'undefined') dates.updated = toDate(updated);
    if (typeof start_date !== 'undefined') dates.startDate = toDate(start_date);
    if (typeof startDate !== 'undefined') dates.startDate = toDate(startDate);
    if (typeof end_date !== 'undefined') dates.endDate = toDate(end_date);
    if (typeof endDate !== 'undefined') dates.endDate = toDate(endDate);
    if (typeof expires !== 'undefined') dates.expires = toDate(expires);
    if (typeof estimatedEndDate !== 'undefined') dates.estimatedEndDate = toDate(estimatedEndDate);
    if (typeof estimate_end_date !== 'undefined')
        dates.estimate_end_date = toDate(estimate_end_date);

    return {
        ...dates,
        ...rest
    };
};

export default sqlDatesToJsDates;

export const parseObjectDates = (dateFields: string[]) => (obj: Record<string, any>) => {
    for (const dateField of dateFields) {
        if (obj[dateField]) obj[dateField] = new Date(obj[dateField]);
    }
    return obj;
};
