import { useMemo } from 'react';
import {
    FrontFolderContentFile,
    FrontFolderContentFileFilter
} from '@he-novation/front-shared/types/file.front-types';
import { useAtom } from 'jotai';

import { playlistAtom } from '$atoms/file-atoms/playlist-atom';
import sortAlphabetically from '$helpers/sortAlphabetically';

type UsePlaylistType = {
    isPlaylistOpen: boolean;
    isPlaylistPlaying: boolean;
    playlistContent: FrontFolderContentFile[];
    playlistCurrentFileUuid: string | null;
    playlistFilters: FrontFolderContentFileFilter[];
    playlistFolderUuid: string | null;
    previousFile: FrontFolderContentFile | null;
    nextFile: FrontFolderContentFile | null;
    setPlaylistContent: (
        folderUuid: string,
        fetchContent: () => Promise<FrontFolderContentFile[]>
    ) => Promise<void>;
    setPlaylistCurrentFileUuid: (fileUuid: string | null) => void;
    setPlaylistFilters: (playlistFilters: FrontFolderContentFileFilter[]) => void;
    setPlaylistIsOpen: (isOpen: boolean) => void;
    setPlaylistIsPlaying: (isPlaying: boolean) => void;
};

export function usePlaylist(): UsePlaylistType {
    const [playlist, setPlaylist] = useAtom(playlistAtom);

    const playablePlaylistContent = useMemo(
        () => playlist.folderFiles?.filter((item) => item.isPlayable) || [],
        [playlist.folderFiles]
    );

    const [previousFile, nextFile] = useMemo(() => {
        const currentFileIndexInPlaylist = playablePlaylistContent.findIndex(
            (content) => content.uuid === playlist.fileUuid
        );

        let previous: FrontFolderContentFile | null = null;
        let next: FrontFolderContentFile | null = null;

        if (currentFileIndexInPlaylist > 0) {
            previous = playablePlaylistContent[currentFileIndexInPlaylist - 1];
        }

        if (currentFileIndexInPlaylist + 1 < playablePlaylistContent.length) {
            next = playablePlaylistContent[currentFileIndexInPlaylist + 1];
        }
        return [previous, next];
    }, [playablePlaylistContent, playlist.fileUuid]);

    const setPlaylistContent = async (
        folderUuid: string,
        fetchContent: () => Promise<FrontFolderContentFile[]>
    ) => {
        setPlaylistIsPlaying(false);

        const folderContent = await fetchContent();
        const files = sortAlphabetically(folderContent.concat(), 'name').filter(
            ({ type }) => type === 'file'
        );
        setPlaylist((playlist) => ({
            ...playlist,
            folderUuid,
            folderFiles: files
        }));
    };

    const setPlaylistCurrentFileUuid = (fileUuid: string | null) => {
        setPlaylist((playlist) => ({ ...playlist, fileUuid }));
    };

    const setPlaylistIsOpen = (isOpen: boolean) => {
        setPlaylist((playlist) => ({ ...playlist, isOpen }));
    };

    const setPlaylistIsPlaying = (isPlaying: boolean) => {
        if (playlist.fileUuid) {
            setPlaylist((playlist) => ({ ...playlist, isPlaying }));
        }
    };

    const setPlaylistFilters = (filters: FrontFolderContentFileFilter[]) => {
        setPlaylist((playlist) => ({
            ...playlist,
            filters
        }));
    };

    return {
        isPlaylistOpen: playlist.isOpen,
        isPlaylistPlaying: playlist.isPlaying,
        playlistContent: applyFilters(playlist?.folderFiles, playlist.filters),
        playlistCurrentFileUuid: playlist.fileUuid,
        playlistFilters: playlist.filters,
        playlistFolderUuid: playlist.folderUuid,
        previousFile,
        nextFile,
        setPlaylistContent,
        setPlaylistCurrentFileUuid,
        setPlaylistFilters,
        setPlaylistIsOpen,
        setPlaylistIsPlaying
    };
}

function applyFilters(
    playlistContent: FrontFolderContentFile[] | null,
    filters: FrontFolderContentFileFilter[]
): FrontFolderContentFile[] {
    if (!playlistContent) {
        return [];
    }

    if (filters.length === 0) {
        return playlistContent;
    }

    return playlistContent.filter(
        (file) => !!filters.find(({ key, value }) => file[key] === value)
    );
}
