import styles from './PlaylistThumbnail.module.css';
import React from 'react';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { FrontFolderContentFile } from '@he-novation/front-shared/types/file.front-types';

type PlaylistThumbnailProps = {
    file: FrontFolderContentFile;
};

export default function PlaylistThumbnail({ file }: PlaylistThumbnailProps) {
    const url = getThumbnailURl(file);

    return (
        <div className={styles.playlistThumbnail}>
            {url ? (
                <img
                    src={url}
                    alt={file.name}
                    className={file.thumbnail ? undefined : styles.iconThumbnail}
                />
            ) : (
                <Icon icon={'file'} className={styles.iconThumbnail} />
            )}
        </div>
    );
}

function getThumbnailURl(file: FrontFolderContentFile): string | undefined {
    if (file.thumbnail) {
        return file.thumbnail;
    }

    if (file.isBundle) {
        return '/assets/img/html5-icon.png';
    }

    if (
        file.mimeType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
        if (/\.pptx?$/.test(file.name)) {
            return '/assets/img/powerpoint-icon.png';
        }

        if (/\.docx?$/.test(file.name)) {
            return '/assets/img/word-icon.png';
        }

        if (/\.xlsx?$/.test(file.name)) {
            return '/assets/img/excel-icon.png';
        }
    }

    return undefined;
}
