import { TEAM_ADMIN } from '@he-novation/config/constants/projects.constants';
import { TeamWithMembers } from '@he-novation/config/types/team.types';
import { atom } from 'jotai';

export const projectTeamsProjectUuidAtom = atom<string | null>(null);
export const projectTeamsAtom = atom<TeamWithMembers[]>([]);

export const isProjectManagerAtom = atom((get) => {
    const teamName = get(projectTeamsAtom).find((t) => t.ownTeam)?.name;
    return teamName === TEAM_ADMIN;
});
