import styles from './ModalHeader.module.scss';
import React from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Icon, IconProp } from '@he-novation/design-system/components/graphics/Icon/Icon';
import classNames from 'classnames';

import { useModal } from '$hooks/useModal';

type ModalHeaderProps = {
    title: string;
    isError?: boolean;
    close?: () => void;
    icon?: IconProp;
    iconStroke?: string;
    className?: string;
    actions?: React.ReactNode;
};
function ModalHeader({
    title,
    isError,
    close,
    icon,
    iconStroke,
    className,
    actions
}: ModalHeaderProps) {
    const { closeModal } = useModal();

    return (
        <div className={classNames(styles.header, isError && styles.error, className)}>
            {icon && <Icon icon={icon} stroke={iconStroke || 'white'}></Icon>}
            <h1>{title}</h1>

            <span className={styles.actions}>
                {actions}
                <Button
                    tone={ButtonTone.HoverableLight}
                    className={styles.close}
                    icon="plus"
                    onClick={close || closeModal}
                    type={'button'}
                />
            </span>
        </div>
    );
}

export default ModalHeader;
