import { useEffect } from 'react';

export enum PubSubEvent {
    FileUpload = 'FileUpload',
    PlayNextInPlaylist = 'PlayNextInPlaylist',
    TaskCreated = 'TaskCreated'
}

const callbacks = new Map<string, ((payload?: never) => void)[]>();

export const subscribe = (event: PubSubEvent, callback: (payload?: unknown) => void) => {
    if (!callbacks.has(event)) callbacks.set(event, []);
    callbacks.get(event)?.push(callback);

    return function unsubscribe() {
        const eventCallbacks = callbacks.get(event);
        if (!eventCallbacks) return;
        eventCallbacks.splice(eventCallbacks.indexOf(callback) ?? 0, 1);
        if (eventCallbacks.length === 0) callbacks.delete(event);
    };
};

export const publish = (event: PubSubEvent, payload?: never) =>
    callbacks.get(event)?.forEach((callback) => callback(payload));

export function useSubscribe(
    event: PubSubEvent,
    callback: (payload?: never) => void,
    deps: any[] = [],
    { callOnMount }: { callOnMount?: boolean } = {}
) {
    if (callOnMount) useEffect(() => callback(), []);

    useEffect(() => {
        return subscribe(event, callback);
    }, deps);
}
