import { FullFolder } from '@he-novation/config/types/folder.types';
import { FolderState } from '@he-novation/front-shared/types/folder.front-types';
import update from 'immutability-helper';
import mapFetchFileInfoToUpdate from '../../content/folder/maps/mapFetchFileInfoToUpdate';
import {
    FETCH_FILE_VIEW,
    FETCH_FOLDER_VIEW,
    FETCH_TRASH_VIEW,
    RESET_CONTENT
} from '../contentActions';
import {
    FETCH_PLUGINS_ACCESS,
    FOLDER_ACCESS_MODIFY,
    FOLDER_LOADED,
    FOLDER_MEMBERS_FETCH,
    FOLDER_SET_FOLDER,
    FOLDER_SET_PLUGINS,
    GRANT_ACCESS,
    RESET,
    REVOKE_ACCESS,
    SETTINGS_SET,
    USER_METADATA_SET,
    WS_FOLDER_CAST,
    WS_FOLDER_CAST_DELETE,
    WS_FOLDER_MEMBERS_ADD,
    WS_FOLDER_MEMBERS_REMOVE,
    WS_SET_FOLDER
} from './folderActions';

import { asyncActionSuccess } from '$helpers/asyncAction';

export const folderInitialState: FolderState = {
    folder: null,
    isShared: false,
    isLoaded: false,
    content: null,
    fileVersions: {},
    fileReviews: {},
    fileSubtitles: {},
    members: [],
    fileInfo: mapFetchFileInfoToUpdate({}).fileInfo,
    plugins: []
};

export default (state = folderInitialState, action: any = {}) => {
    switch (action.type) {
        case RESET_CONTENT:
            return update(state, {
                $unset: ['uuid']
            });

        case RESET: {
            return update(folderInitialState, {
                isShared: { $set: state.isShared }
            });
        }

        case asyncActionSuccess(SETTINGS_SET): {
            if (action.folder.uuid !== state.folder?.uuid) return state;
            if (!action.folder.metadata) action.folder.metadata = {};
            if (!action.folder.metadata.user) {
                action.folder.metadata.user = state.metadata?.user;
            }
            const partial: Partial<FullFolder> = {
                admittance: action.folder.admittance,
                updated: new Date(action.folder.updated),
                isEncrypted: !!action.folder.encrypted,
                defaultPresets: !!action.folder.default_presets,
                exportMode: action.folder.export_mode,
                isPublic: !!action.folder.public,
                publicDownload: action.folder.public_download,
                publicPassword: action.folder.public_password,
                metadata: action.folder.metadata,
                tags: action.folder.tags,
                labels: Array.isArray(action.folder.labels)
                    ? action.folder.labels
                    : (action.folder.labels || '').split(','),
                watermark: action.folder.watermark,
                triggers: action.folder.triggers
            };

            return update(state, {
                folder: { $merge: partial }
            });
        }

        case asyncActionSuccess(USER_METADATA_SET):
            if (action.folderUuid !== state.folder?.uuid) return state;
            return update(state, {
                metadata_user: {
                    $set: action.metadata
                }
            });

        case FOLDER_LOADED:
            return update(state, {
                isLoaded: { $set: true }
            });

        case asyncActionSuccess(FETCH_FILE_VIEW):
        case asyncActionSuccess(FETCH_TRASH_VIEW):
            return update(state, { $merge: action.folderState });

        case FETCH_FOLDER_VIEW: {
            return update(state, {
                isLoaded: { $set: false }
            });
        }

        case asyncActionSuccess(FOLDER_ACCESS_MODIFY): {
            if (state.folder?.uuid !== action.folderUuid) return state;
            const memberIndex = state.members.findIndex((m) => m.uuid === action.userUuid);
            if (memberIndex < 0) return state;
            return update(state, {
                members: {
                    [memberIndex]: {
                        $merge: {
                            canDownload:
                                typeof action.canDownload !== 'undefined'
                                    ? action.canDownload
                                    : state.members[memberIndex].canDownload,
                            canExport:
                                typeof action.canExport !== 'undefined'
                                    ? action.canExport
                                    : state.members[memberIndex].canExport,
                            role: action.role || state.members[memberIndex].role
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(FETCH_PLUGINS_ACCESS): {
            if (action.plugins) {
                return update(state, {
                    plugins: {
                        $set: action.plugins
                    }
                });
            }
            return state;
        }

        case asyncActionSuccess(GRANT_ACCESS):
            if (action.folderUuid !== state.uuid) return state;

            if (action.members.length === 1) {
                const inPendingIndex = state.members.findIndex(
                    (member) => member.uuid === action.members[0].uuid
                );
                if (inPendingIndex >= 0) {
                    return update(state, {
                        members: {
                            [inPendingIndex]: {
                                $set: { ...action.members[0], pending: false }
                            }
                        }
                    });
                }
            }

            return update(state, {
                members: {
                    $set: state.members.concat(action.members)
                }
            });

        case asyncActionSuccess(REVOKE_ACCESS):
            if (action.folderUuid !== state.uuid) return state;
            return update(state, {
                members: {
                    $set: state.members.filter((m) => m.uuid !== action.userUuid)
                }
            });

        case WS_SET_FOLDER: {
            delete action.folder.role;
            delete action.folder.metadata_user;
            delete action.folder.parent;
            if (typeof action.folder?.tags === 'string') {
                action.folder.tags = action.folder?.tags.split('');
            }
            if (typeof action.folder?.labels === 'string') {
                action.folder.labels = action.folder?.labels.split('');
            }

            if (state.folder?.uuid === action.folder.uuid) {
                return update(state, {
                    folder: {
                        $merge: action.folder
                    }
                });
            }
            return state;
        }

        case asyncActionSuccess(FOLDER_MEMBERS_FETCH):
            if (state.folder?.uuid !== action.folderUuid) return state;
            return update(state, {
                members: {
                    $set: action.members
                }
            });

        case WS_FOLDER_CAST: {
            if (state.folder?.uuid === action.folderUuid) {
                return update(state, {
                    folder: {
                        castTriggers: {
                            $push: [action.cast]
                        }
                    }
                });
            }
            return state;
        }

        case WS_FOLDER_CAST_DELETE: {
            if (action.folderUuid === state.folder?.uuid) {
                return update(state, {
                    folder: {
                        castTriggers: {
                            $set: state.folder!.castTriggers.filter((c) => {
                                if (action.castFolderUuid) {
                                    return c.castFolderUuid !== action.castFolderUuid;
                                }
                                return c.castUid !== action.castUid;
                            })
                        }
                    }
                });
            }
            return state;
        }

        case WS_FOLDER_MEMBERS_ADD: {
            const members = state.members
                .filter((m) => !action.members.map((m) => m.uuid).includes(m.uuid))
                .concat(action.members);
            return update(state, {
                members: {
                    $set: members
                }
            });
        }

        case WS_FOLDER_MEMBERS_REMOVE: {
            return update(state, {
                members: {
                    $set: state.members?.filter((m) => !action.uuids.includes(m.uuid))
                }
            });
        }

        case FOLDER_SET_FOLDER:
            return update(state, {
                folder: {
                    $set: action.folder
                }
            });

        case FOLDER_SET_PLUGINS:
            return update(state, {
                plugins: {
                    $set: action.plugins
                }
            });

        default:
            break;
    }

    return state;
};
