import { FolderContent, FolderContentFolder } from '@he-novation/config/types/folder.types';
import { FrontFolderContent, FrontFolderContentFolder } from '../types/folder.front-types';
import { apiFolderContentFileToFrontFolderContentFile } from './file.mappers';

export function apiFolderContentFolderToFrontFolderContentFolder(
    folder: FolderContentFolder
): FrontFolderContentFolder {
    const created = folder.created ? new Date(folder.created) : new Date();
    const updated = folder.updated ? new Date(folder.updated) : created;

    return {
        uuid: folder.uuid,
        type: 'folder',
        name: folder.name,
        isPublic: folder.isPublic,
        created,
        updated,
        casts: folder.castTriggers.length,
        labels: folder.labels,
        creator: folder.creator,
        role: folder.role
    };
}

export function apiFolderContentToFrontFolderContent(content: FolderContent): FrontFolderContent {
    return content.map((item) => {
        if (item.type === 'folder') {
            return apiFolderContentFolderToFrontFolderContentFolder(item);
        } else {
            return apiFolderContentFileToFrontFolderContentFile(item);
        }
    });
}
