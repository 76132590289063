import React from 'react';
import { FieldComponentProps } from 'react-modular-forms';
import { useEffect, useState } from 'react';
import { load } from 'recaptcha-v3';
import { ModularFormField } from 'react-modular-forms';

type FormRecaptchaProps = FieldComponentProps & {
    siteKey: string;
    action: string;
};
export function FormRecaptcha({ formId, name, siteKey, action }: FormRecaptchaProps) {
    const [recaptcha, setRecaptcha] = useState<string>();
    useEffect(() => {
        load(siteKey).then(async (recaptcha) => {
            setRecaptcha(await recaptcha.execute(action));
        });
    }, []);

    return (
        <ModularFormField
            formId={formId}
            name={name}
            validation={{
                required: true
            }}
            type="hidden"
            value={recaptcha}
        />
    );
}
