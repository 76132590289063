import { MULTIPART_PART_SIZE } from '@he-novation/config/constants/uploads.constants';
import { Locale } from '@he-novation/config/types/i18n.types';
import { FileCreateResponse } from '@he-novation/config/types/responses/file.responses';
import { folders as foldersSocket } from '@he-novation/config/utils/sockets/sockets.client';
import { asyncCompleteMultipartUpload } from '@he-novation/front-shared/async/asset.async';
import {
    asyncFileCreate,
    asyncVersionCreate,
    readMagicBytes
} from '@he-novation/front-shared/async/file.async';
import { asyncInitSubtitlesFileUpload } from '@he-novation/front-shared/async/subtitle.async';
import { handleExceptions, isMimeTypeWhiteListed } from '@he-novation/utils/mimeType.utils';
import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';

import {
    FinishedUpload,
    MultipartPart,
    MultipartUpload,
    MultipartUploadBitrate,
    PendingUpload,
    Upload,
    UploadError,
    UploaderState,
    UploadFolder,
    UploadPart,
    UploadProgression
} from '$helpers/Uploader.types';
import { folderContentGhostCreate, folderContentGhostDelete } from '$hooks/useFolderContent';
import { Translator } from '$hooks/useTranslate';
import { openFeedbackModalFromAtom } from '$redux/helpers';
import { UserInfos } from '$redux/user/userSelectors';

const MAX_CONCURRENT_UPLOADS = 5;
const BITRATE_REFRESH_DELAY_MS = 1_000;

export class Uploader {
    public static uploads: MultipartUpload[] = [];
    public static invalidFiles: string[] = [];
    private static partsUploading = 0;
    private static pendingUploads: PendingUpload[] = [];
    private static finished: FinishedUpload[] = [];
    private static errors: UploadError[] = [];
    private static setStateFunctions: ((state: UploaderState) => void)[] = [];
    private static debounce = false;
    public static t: Translator = (key: string) => key;

    public static register(setState: (state: UploaderState) => void) {
        Uploader.setStateFunctions.push(setState);
        setState(Uploader.getData());
    }

    public static unregister(setState: (state: UploaderState) => void) {
        Uploader.setStateFunctions.splice(Uploader.setStateFunctions.indexOf(setState), 1);
    }

    public static resetInvalidFiles() {
        Uploader.invalidFiles = [];
    }

    public static async uploadFile(
        userInfos: UserInfos,
        upload: {
            file: File;
            folder: UploadFolder;
            uploadGroup: string;
            uploadIndex: number;
            uploadsTotal: number;
            parentFileUuid?: string;
            ghostUuid?: string;
        }
    ) {
        if (!upload.parentFileUuid) {
            upload.ghostUuid = Uploader.createGhost(
                upload.file.name,
                userInfos,
                upload.folder.uuid
            );
        }

        if (Uploader.partsUploading === MAX_CONCURRENT_UPLOADS) {
            Uploader.pendingUploads.push(upload);
            return;
        }

        const estimatedNumberOfParts = this.increaseParsUploadingByEstimation(upload.file);
        await Uploader.createFileUpload(upload, estimatedNumberOfParts);
    }

    public static async uploadSubtitles(upload: {
        file: File;
        uploadGroup: string;
        uploadIndex: number;
        uploadsTotal: number;
        fileUuid: string;
        fileVersion: number;
        locale: Locale;
    }) {
        const estimatedNumberOfParts = this.increaseParsUploadingByEstimation(upload.file);

        const { links, assetUuid, uploadId } = await asyncInitSubtitlesFileUpload(
            upload.fileUuid,
            upload.fileVersion,
            upload.locale,
            upload.file
        );

        await Uploader.startMultipartUpload(
            upload,
            estimatedNumberOfParts,
            links,
            upload.fileUuid,
            upload.fileVersion,
            assetUuid,
            uploadId
        );
    }

    // we increment with an estimate before upload starts to avoid starting other uploads, we will then decrement
    // after the async functions and each part starting will increment again
    private static increaseParsUploadingByEstimation(file: File) {
        let estimatedNumberOfParts = Math.ceil(file.size / MULTIPART_PART_SIZE);

        if (this.partsUploading + estimatedNumberOfParts > MAX_CONCURRENT_UPLOADS) {
            estimatedNumberOfParts = MAX_CONCURRENT_UPLOADS - this.partsUploading;
        }

        // we increment with an estimate before upload starts to avoid starting other uploads, we will then decrement
        // after the async functions and each part starting will increment again
        Uploader.partsUploading += estimatedNumberOfParts;
        return estimatedNumberOfParts;
    }

    private static async createFileUpload(
        pendingUpload: PendingUpload,
        estimatedNumberOfParts: number
    ) {
        const mimeType = await Uploader.checkMimeType(pendingUpload.file);

        if (!mimeType) {
            if (pendingUpload.ghostUuid) {
                folderContentGhostDelete(pendingUpload.ghostUuid);
            }
            return;
        }

        const fileCreationBody = {
            uploadGroup: pendingUpload.uploadGroup,
            folderUuid: pendingUpload.folder!.uuid,
            size: pendingUpload.file.size,
            mimeType,
            version: 0,
            name: pendingUpload.file.name
        };

        let response: FileCreateResponse | undefined;
        if (pendingUpload.parentFileUuid) {
            response = await asyncVersionCreate(pendingUpload.parentFileUuid, fileCreationBody);
        } else {
            response = await asyncFileCreate(fileCreationBody);
        }

        const { links, file, asset, uploadId } = response;

        await Uploader.startMultipartUpload(
            pendingUpload,
            estimatedNumberOfParts,
            links,
            file.uuid,
            file.version,
            asset.uuid,
            uploadId
        );
    }

    private static async startMultipartUpload(
        pendingUpload: PendingUpload,
        estimatedNumberOfParts: number,
        links: UploadPart[],
        fileUuid: string,
        fileVersion: number,
        assetUuid: string,
        awsUploadId: string
    ) {
        let total = 0;
        const parts: MultipartPart[] = links.map((link) => {
            const oldTotal = total;
            total = oldTotal + link.size;
            const slice = pendingUpload.file.slice(oldTotal, total);

            return {
                file: { uuid: fileUuid, version: fileVersion },
                fileSize: pendingUpload.file.size,
                url: link.url,
                part: link.part,
                slice
            };
        });

        Uploader.partsUploading -= estimatedNumberOfParts;

        Uploader.uploads.push({
            ...pendingUpload,
            awsUploadId,
            assetUuid,
            fileUuid,
            parts,
            bitrate: {
                depth: 10,
                refreshDelay: BITRATE_REFRESH_DELAY_MS,
                uploadHistory: []
            }
        });

        for (const part of parts) {
            if (Uploader.partsUploading < MAX_CONCURRENT_UPLOADS) {
                Uploader.uploadPart(
                    awsUploadId,
                    pendingUpload.file,
                    pendingUpload.folder,
                    pendingUpload.uploadGroup,
                    pendingUpload.uploadIndex,
                    pendingUpload.uploadsTotal,
                    assetUuid,
                    parts,
                    part
                ).catch((e) => {
                    console.error(e);
                });
            }
        }
    }

    private static async uploadPart(
        awsUploadId: string,
        fileToUpload: File,
        folder: UploadFolder | undefined,
        uploadGroup: string,
        uploadIndex: number,
        uploadsTotal: number,
        assetUuid: string,
        parts: MultipartPart[],
        part: MultipartPart
    ) {
        Uploader.partsUploading++;

        const upload: MultipartUpload | undefined = Uploader.uploads.find(
            (u) => u.uploadGroup === uploadGroup && u.uploadIndex === uploadIndex
        );
        const now = Date.now();
        if (!upload) {
            console.error('Upload not found', uploadGroup, uploadIndex);
            return;
        }

        part.uploadProgression = {
            bitrate: 0,
            lastTick: now,
            loaded: 0,
            progress: 0,
            startTime: now,
            total: part.slice!.size,
            remainingMs: null
        };

        axios
            .put(part.url, part.slice, {
                onUploadProgress: (e) => {
                    if (!part.uploadProgression) throw new Error('Missing upload');
                    const now = Date.now();
                    const total = e.total || part.uploadProgression.total;
                    const elapsed = now - part.uploadProgression.lastTick;

                    part.uploadProgression.bitrate = e.bytes / elapsed; // bytes per MS
                    part.uploadProgression.remainingMs =
                        (total - e.loaded) / part.uploadProgression.bitrate; //ms
                    part.uploadProgression.loaded = e.loaded;
                    part.uploadProgression.total = total;
                    part.uploadProgression.progress = (e.loaded / total) * 100;
                    part.uploadProgression.lastTick = now;

                    Uploader.onProgress(upload, false);
                }
            })
            .then((r) => {
                part.ETag = JSON.parse(r.headers.etag);
                Uploader.partsUploading--;
                part.uploadProgression!.loaded = part.uploadProgression!.total;

                if (upload.parts.every((p) => p.uploadProgression && p.ETag)) {
                    asyncCompleteMultipartUpload(assetUuid, {
                        uploadId: awsUploadId,
                        parts: parts.map((p) => ({
                            PartNumber: p.part,
                            ETag: p.ETag!
                        }))
                    });
                    Uploader.uploads.splice(Uploader.uploads.indexOf(upload), 1);
                    Uploader.finished.push({
                        uploadGroup,
                        uploadIndex,
                        uploadsTotal,
                        file: fileToUpload,
                        folder,
                        startedAt: upload.parts[0].uploadProgression!.startTime,
                        finishedAt: Date.now()
                    });
                    Uploader.onProgress(upload, true);
                }

                let pendingPart: MultipartPart | undefined;
                let inProgressUpload: MultipartUpload | undefined;

                for (const runningUpload of Uploader.uploads) {
                    pendingPart = runningUpload.parts.find(
                        (p) => p.uploadProgression === undefined
                    );
                    if (pendingPart) {
                        inProgressUpload = runningUpload;
                        break;
                    }
                }

                if (pendingPart && inProgressUpload) {
                    Uploader.uploadPart(
                        inProgressUpload.awsUploadId,
                        inProgressUpload.file,
                        inProgressUpload.folder,
                        inProgressUpload.uploadGroup,
                        inProgressUpload.uploadIndex,
                        inProgressUpload.uploadsTotal,
                        inProgressUpload.assetUuid,
                        inProgressUpload.parts,
                        pendingPart
                    );
                } else {
                    const pendingUpload = Uploader.pendingUploads.shift();
                    if (pendingUpload) {
                        const estimatedNumberOfParts = this.increaseParsUploadingByEstimation(
                            pendingUpload.file
                        );
                        Uploader.createFileUpload(pendingUpload, estimatedNumberOfParts);
                    }
                }
            })
            .catch((e) => {
                console.error(e);
                Uploader.partsUploading--;
                Uploader.uploads.splice(Uploader.uploads.indexOf(upload), 1);
                Uploader.errors.push({
                    uploadGroup,
                    uploadIndex,
                    uploadsTotal,
                    file: fileToUpload,
                    folder,
                    error: e
                });
            });
    }

    private static onProgress(upload: MultipartUpload, complete: boolean) {
        if (upload.debounce && !complete) return;
        upload.debounce = true;
        Uploader.update();

        if (upload.folder) {
            const u = Uploader.multipartUploadToUpload(upload);
            foldersSocket.emit(upload.folder.uuid, 'uploadProgress', {
                assetUuid: upload.assetUuid,
                fileUuid: upload.fileUuid,
                progress: u.progression
            });
        }

        setTimeout(() => (upload.debounce = false), 500);
    }

    private static async checkMimeType(fileToUpload: File) {
        let mimeType = fileToUpload.type;
        const serverMimeType = await readMagicBytes(fileToUpload);

        // Remove experimental 'x-' prefix to mime types
        const fixedMimeType = mimeType?.replace('/x-', '/');
        const fixedServerMimeType = serverMimeType?.replace('/x-', '/') || null;

        const exception = handleExceptions(fixedMimeType || '', fixedServerMimeType || '');

        if (exception) {
            mimeType = exception;
        } else {
            if (
                serverMimeType === null ||
                (fixedMimeType && fixedMimeType !== fixedServerMimeType)
            ) {
                Uploader.dispatchInvalidFile(fileToUpload.name);
                return;
            }

            if (!mimeType) {
                mimeType = serverMimeType || '';
            }
        }

        if (mimeType && isMimeTypeWhiteListed(mimeType)) {
            return mimeType;
        }

        Uploader.dispatchInvalidFile(fileToUpload.name);
        return;
    }

    private static createGhost(name: string, userInfos: UserInfos, folderUuid: string) {
        const uuid = uuidV4();
        const date = new Date();
        folderContentGhostCreate({
            folderUuid,
            name,
            uuid,
            type: 'ghost',
            creator: {
                email: userInfos.email,
                firstname: userInfos.firstname,
                lastname: userInfos.lastname
            },
            created: date,
            updated: date,
            version: 0
        });
        return uuid;
    }

    private static async dispatchInvalidFile(filename: string) {
        Uploader.invalidFiles.push(filename);

        openFeedbackModalFromAtom(Uploader.invalidFiles.join('<br/>'), 10_000, {
            title: Uploader.t('common.Unsupported file format'),
            isError: true
        });
    }

    private static multipartUploadToUpload(upload: MultipartUpload): Upload {
        const progress = upload.parts.reduce(
            (acc, part) => {
                const partUploadProgression = part.uploadProgression || {
                    bitrate: 0,
                    loaded: 0,
                    progress: 0,
                    startTime: Infinity,
                    lastTick: 0,
                    total: 0,
                    remainingMs: 0
                };

                return {
                    bitrate: 0,
                    loaded: acc.loaded + partUploadProgression.loaded,
                    progress: 0,
                    startTime: Math.min(acc.startTime, partUploadProgression.startTime),
                    total: part.fileSize,
                    lastTick: Math.max(acc.lastTick, partUploadProgression.lastTick),
                    remainingMs: 0
                };
            },
            {
                bitrate: 0,
                loaded: 0,
                progress: 0,
                startTime: Infinity,
                total: 0,
                lastTick: 0,
                remainingMs: 0
            } as UploadProgression
        );

        const remainingBytes = progress.total - progress.loaded; // bytes
        const bitrate = Uploader.computeBitrate(upload.bitrate, progress.loaded); // bytes per ms

        progress.remainingMs = bitrate ? remainingBytes / bitrate : 0; // ms
        progress.bitrate = bitrate;
        progress.progress = (progress.loaded / progress.total) * 100;

        return {
            ...upload,
            progression: progress
        };
    }

    private static computeBitrate(bitrate: MultipartUploadBitrate, loaded: number): number {
        const now = Date.now();

        if (
            bitrate.uploadHistory.length === 0 ||
            now - bitrate.uploadHistory[bitrate.uploadHistory.length - 1].timestamp >
                bitrate.refreshDelay
        ) {
            if (bitrate.uploadHistory.length >= bitrate.depth) {
                bitrate.uploadHistory.shift();
            }

            bitrate.uploadHistory.push({ loaded, timestamp: now });
        }

        const elapsedMs = now - bitrate.uploadHistory[0].timestamp; // ms
        if (elapsedMs > 0) {
            const loadedDiff = loaded - bitrate.uploadHistory[0].loaded; // bytes
            return loadedDiff / elapsedMs; // bytes per ms
        }

        return 0;
    }

    private static getData(): UploaderState {
        return {
            uploads: Uploader.uploads.map((u) => Uploader.multipartUploadToUpload(u)),
            pending: Uploader.pendingUploads.concat(),
            finished: Uploader.finished.concat(),
            errors: Uploader.errors.concat()
        };
    }

    private static update() {
        if (Uploader.debounce) return;

        Uploader.debounce = true;
        setTimeout(() => {
            const data = Uploader.getData();
            Uploader.setStateFunctions.forEach((setState) => setState(data));
            Uploader.debounce = false;
        }, 1000);
    }
}
