import { atom } from 'jotai/index';

import type modals from '$components/modal/modals';

export type ModalPayload = {
    [key: string]: unknown;
    alignTop?: boolean;
    disableOverlay?: boolean;
};

export type ModalExtraParams = Record<string, string | number | boolean | object | null>;

export type ModalName = keyof typeof modals;

export type ModalAtom = {
    modal: ModalName;
    payload?: ModalPayload | null;
    extraParams?: ModalExtraParams | null;
};

export const modalAtom = atom<ModalAtom | null>(null);
