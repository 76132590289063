import { FrontFolderContentFile } from '@he-novation/front-shared/types/file.front-types';
import {
    FrontFolderContent,
    FrontFolderContentFolder,
    FrontFolderContentGhost
} from '@he-novation/front-shared/types/folder.front-types';
import { atom } from 'jotai';
import { getDefaultStore } from 'jotai/index';

type Setter<T> = (setter: (value: T) => T) => void;

export type SetFolderContent = Setter<FrontFolderContent>;
export type SetFolderGhosts = Setter<FrontFolderContentGhost[]>;

type FolderCopy = {
    data: (FrontFolderContentFile | FrontFolderContentFolder)[];
    type?: 'tree';
    cut?: boolean;
};

export const folderUuidAtom = atom<string | null>(null);
export const folderGhostsAtom = atom<FrontFolderContentGhost[]>([]);
export const folderContentAtom = atom<FrontFolderContent | null>(null);
export const folderCopyAtom = atom<FolderCopy | null>(null);

export const folderContentWithGhostsAtom = atom((get) => {
    const folderContent = get(folderContentAtom);
    if (!folderContent) return null;

    const folderUuid = get(folderUuidAtom);
    if (!folderUuid) return folderContent;
    return folderContent.concat(get(folderGhostsAtom).filter((g) => g.folderUuid === folderUuid));
});

export const foldersStore = getDefaultStore();

export const isDraggingFolderContentAtom = atom<boolean>(false);
