import styles from './SidePanelFilters.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { useMemo } from 'react';
import { Title } from '@he-novation/design-system/components/text/Title/Title';

import {
    FilterCheckboxes,
    FilterCheckboxTag,
    FiltersCheckboxProps
} from '$components/Filters/FilterCheckboxes';
import { useTranslate } from '$hooks/useTranslate';

type SidePanelFiltersProps = {
    payload: {
        filters: FiltersCheckboxProps[];
    };
};

export function SidePanelFilters({ payload: { filters } }: SidePanelFiltersProps) {
    const { t } = useTranslate();
    const content = useMemo(() => {
        return filters.map((props, index) => (
            <FilterCheckboxes
                tag={FilterCheckboxTag.Accordion}
                key={index}
                {...props}
                className={formStyles.dark}
            />
        ));
    }, [filters]);

    return (
        <div className={styles.sidePanelFilters}>
            <Title tag={'h2'} type="tab">
                {t('common.Filters')}
            </Title>

            <main className={styles.content}>{content}</main>
        </div>
    );
}
