import paths, {
    folderPaths,
    pathWithParams,
    publicApiV1Paths
} from '@he-novation/config/paths/herawApiPaths';
import type { BasicFile } from '@he-novation/config/types/file.types';
import {
    type BasicFolder,
    BreadCrumb,
    FolderContent,
    FolderMember,
    type FolderRole,
    FolderSizeData,
    FullFolder
} from '@he-novation/config/types/folder.types';
import {
    FolderCopyTreeBody,
    FolderCreateBody,
    FolderInviteBody,
    FolderModifyAccessBody,
    FolderNotificationBody,
    FolderSpecific,
    FolderUpdateBody
} from '@he-novation/config/types/payloads/folder.payload';
import { FolderTeam } from '@he-novation/config/types/team.types';
import { apiFolderContentToFrontFolderContent } from '../mappers/folder.mappers';
import { FrontFolderContent } from '../types/folder.front-types';
import { apiFetch } from './apiFetch';

export const createFolder = ({
    folderUuid,
    name,
    copyParentProperties,
    teamUuids
}: {
    folderUuid: string;
    name: string;
    copyParentProperties?: boolean;
    teamUuids?: string[];
}): Promise<FullFolder> =>
    apiFetch(paths.folder.root, {
        method: 'POST',
        body: {
            name,
            parentFolderUuid: folderUuid,
            copyParentProperties,
            copyParentMembers: true,
            copyParentMembersPending: true,
            teamUuids
        } satisfies FolderCreateBody
    });

export const fetchFolder = (
    {
        uuid,
        password
    }: {
        uuid?: string;
        password?: string;
    },
    options?: {
        signal: AbortSignal;
    }
): Promise<FullFolder> =>
    apiFetch(
        uuid
            ? pathWithParams(paths.folder.specific, { folderUuid: uuid }, { p: password })
            : paths.folder.root,
        {
            method: 'GET',
            ...options
        }
    );

export const fetchFolderContent = (
    uuid?: string,
    options?: { signal: AbortSignal }
): Promise<FrontFolderContent> =>
    apiFetch(
        uuid ? pathWithParams(folderPaths.content, { folderUuid: uuid }) : paths.folder.rootContent,
        {
            method: 'GET',
            ...options
        }
    ).then(apiFolderContentToFrontFolderContent);

export function fetchFolderBasicContent(
    uuid?: string,
    options?: { signal: AbortSignal }
): Promise<(BasicFile | BasicFolder)[]> {
    return apiFetch(
        uuid ? pathWithParams(folderPaths.content, { folderUuid: uuid }) : paths.folder.rootContent,
        {
            method: 'GET',
            query: {
                basic: true
            },
            ...options
        }
    );
}

export const fetchSharedRoot = (options?: { signal: AbortSignal }): Promise<FrontFolderContent> =>
    apiFetch(paths.folder.shared, {
        method: 'GET',
        ...options
    });

export const asyncFolderUpdate = (folderUuid: string, body: FolderUpdateBody) =>
    apiFetch(paths.folder.specific, {
        method: 'PATCH',
        params: { folderUuid } satisfies FolderSpecific,
        body
    });

export const asyncFolderFetchNotificationSettings = (folderUuid: string) =>
    apiFetch(folderPaths.notifications, {
        params: { folderUuid } satisfies FolderSpecific
    });

export const asyncFolderUpdateNotificationSettings = (
    folderUuid: string,
    body: FolderNotificationBody
) =>
    apiFetch(folderPaths.notifications, {
        method: 'PATCH',
        params: { folderUuid } satisfies FolderSpecific,
        body
    });

export const asyncFolderMembersFetch = (uuid: string, pending?: boolean): Promise<FolderMember[]> =>
    apiFetch(`hpi/folder-members/${uuid}?pending=${pending ? 1 : 0}`, {
        method: 'GET'
    }).then((r) =>
        r.map((m) => ({
            ...m,
            download: m.download,
            export: m.export,
            created: new Date(m.created)
        }))
    );

export const fetchFolderSize = (
    folderUuid: string,
    {
        publicSize,
        password,
        flat
    }: {
        publicSize?: boolean;
        password?: string;
        flat?: boolean;
    } = {}
): Promise<FolderSizeData> =>
    apiFetch(
        pathWithParams(
            folderPaths.size,
            { folderUuid },
            { publicSize: publicSize ? 1 : undefined, password, flat: flat ? 1 : undefined }
        ),
        {
            method: 'GET'
        }
    );

export const fetchFolderPlugins = (
    { uuid }: { uuid: string },
    options?: { signal?: AbortSignal }
) =>
    apiFetch(
        pathWithParams(folderPaths.plugins, { folderUuid: uuid } satisfies FolderSpecific),
        options
    );

export const grantAccess = (
    folderUuid: string,
    members: {
        uuid: string;
        role: FolderRole;
        canDownload: boolean;
        canExport: boolean;
    }[],
    message?: string | null
) =>
    apiFetch(paths.folder.invite, {
        params: { folderUuid },
        method: 'POST',
        body: {
            members,
            message
        } satisfies FolderInviteBody
    });

export const fetchFolderPath = (folderUuid: string): Promise<BreadCrumb[]> =>
    apiFetch(
        pathWithParams(paths.folder.path, {
            folderUuid
        }),
        {
            method: 'GET'
        }
    );

export const revokeAccess = (folderUuid: string, userUuid?: string) =>
    apiFetch(folderPaths.membersSpecific, {
        params: {
            folderUuid,
            userUuid
        },
        method: 'DELETE'
    });

export const leaveSharedFolder = (folderUuid: string) =>
    apiFetch(folderPaths.leave, {
        method: 'DELETE',
        params: {
            folderUuid
        }
    });

export const fetchFolderTeams = (folderUuid: string): Promise<FolderTeam[]> =>
    apiFetch(`hpi/folder/${folderUuid}/teams`);

export const setFolderTeams = (folderUuid: string, teamUuids: string[]) =>
    apiFetch(`hpi/folder/${folderUuid}/teams`, {
        method: 'PUT',
        body: {
            teamUuids
        }
    });

export const modifyAccess = (
    folderUuid: string,
    userUuid: string,
    { role, canDownload }: FolderModifyAccessBody
) =>
    apiFetch(pathWithParams(folderPaths.membersSpecific, { folderUuid, userUuid }), {
        method: 'PATCH',
        body: {
            role,
            canDownload
        }
    });

export const folderRecursiveSettingsSet = ({
    public_download,
    public_password,
    export_mode,
    default_presets,
    uuid,
    tags,
    labels,
    ...folder
}) => {
    return apiFetch(pathWithParams(paths.folder.recursive, { folderUuid: uuid }), {
        method: 'PUT',
        body: JSON.stringify({
            folder: {
                ...folder,
                tags: tags ? (Array.isArray(tags) ? tags : tags.split(',').filter((v) => v)) : [],
                labels: labels
                    ? Array.isArray(labels)
                        ? labels
                        : labels.split(',').filter((v) => v)
                    : [],
                default_presets,
                public_download,
                public_password,
                export_mode
            }
        })
    });
};

export const asyncFolderCopyTree = (
    sourceFolderUuid: string,
    targetFolderUuid: string,
    { name, renameIfExists }: { name?: string; renameIfExists?: boolean } = {}
) =>
    apiFetch(folderPaths.copyTree, {
        method: 'POST',
        body: {
            sourceFolderUuid,
            targetFolderUuid,
            name,
            renameIfExists
        } satisfies FolderCopyTreeBody
    });

export const folderMove = (sourceUuid: string, destinationUuid: string) =>
    apiFetch(
        pathWithParams(paths.folder.move, {
            sourceFolderUuid: sourceUuid,
            targetFolderUuid: destinationUuid
        }),
        {
            method: 'PUT'
        }
    );

export const folderBulkDelete = (parentUuid: string, folderUuids: string[]) =>
    apiFetch(folderPaths.bulkDelete, {
        method: 'POST',
        body: {
            parentUuid,
            uuids: folderUuids
        }
    });

export const folderTrashFetch = () => apiFetch(folderPaths.trash, { method: 'GET' });

export const asyncFolderRestore = (folderUuid: string) =>
    apiFetch(folderPaths.restore, {
        method: 'POST',
        params: { folderUuid } satisfies FolderSpecific
    });

export const asyncFolderSharedWith = (userUuid: string) =>
    apiFetch(pathWithParams(paths.folder.sharedWith, { userUuid }), {
        method: 'GET'
    });

export const asyncOpenApiFolderFetch = (apiKey: string, folderUuid: string) =>
    apiFetch(publicApiV1Paths.folder.specific, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + apiKey
        },
        params: { folderUuid }
    });

export const asyncOpenApiFolderContentFetch = (
    apiKey: string,
    folderUuid: string,
    password?: string
): Promise<FolderContent> =>
    apiFetch(publicApiV1Paths.folderContent.specific, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + apiKey
        },
        params: { folderUuid },
        query: { p: password }
    });
