import './libs/_calendars.scss';
import React from 'react';
import { DesignSystemConfiguration, setConfiguration } from './configuration';

type DesignSystemProviderProps = DesignSystemConfiguration & {
    children: React.ReactNode | React.ReactNode[];
};

let bootstrapped = false;
export function DesignSystemProvider({
    children,
    i18n,
    locale,
    RouterLinkComponent
}: DesignSystemProviderProps) {
    if (!bootstrapped) {
        bootstrapped = true;
        setConfiguration({ i18n, locale, RouterLinkComponent });
    }
    return children;
}
