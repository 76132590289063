type Scale = { width: number; height: number };
/**
 * Finds which axis should be 100% to scale without cropping
 */
const findScaleAxis = (source: Scale, dest: Scale): 'vertical' | 'horizontal' => {
    const diffX = dest.width - source.width;
    const diffY = dest.height - source.height;
    return diffX > diffY ? 'vertical' : 'horizontal';
};

type Output = { x: number; y: number; width: number; height: number };

/**
 * Scales preserving ratio and without cropping
 */
export const scaleToFit = (source: Scale, dest: Scale): Output => {
    let thumbnail: Partial<Output> = {};
    let sourceRatio = source.width / source.height;
    if (findScaleAxis(source, dest) === 'horizontal') {
        thumbnail.x = 0;
        thumbnail.width = dest.width;
        thumbnail.height = dest.width / sourceRatio;
        thumbnail.y = (dest.height - thumbnail.height) * 0.5;
    } else {
        thumbnail.y = 0;
        thumbnail.height = dest.height;
        thumbnail.width = dest.height * sourceRatio;
        thumbnail.x = (dest.width - thumbnail.width) * 0.5;
    }
    return thumbnail as Output;
};
