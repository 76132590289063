import { LanguagesAndCountryCode } from '$types/localeTypes';

export type I18nState = {
    languagesAndCountryCodes: LanguagesAndCountryCode[];
};
export enum I18nAction {
    LanguagesAndCountryCodesFetch = `i18n/LanguagesAndCountryCodesFetch`,
    LanguagesAndCountryCodesFetchSuccess = `i18n/LanguagesAndCountryCodesFetch/success`
}

export type LanguagesAndCountryCodesFetchSuccess = {
    type: I18nAction.LanguagesAndCountryCodesFetchSuccess;
    languagesAndCountryCodes: LanguagesAndCountryCode[];
};

export type I18nActionResult = LanguagesAndCountryCodesFetchSuccess;
