import styles from './Title.module.css';
import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Icon, IconProp } from '../../graphics/Icon/Icon';

type TitleProps = {
    children: ReactNode | ReactNode[];
    className?: string;
    tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    type?: 'tab';
    icon?: IconProp;
};
export function Title({ children, className, icon, tag: Tag, type }: TitleProps) {
    return (
        <Tag className={cn(className, type && styles[type])}>
            {icon && <Icon icon={icon} />}
            {children}
        </Tag>
    );
}
