import { apiFetch } from '../async/apiFetch';

export async function downloadLink(link: string, name: string, external?: boolean) {
    const a = document.createElement('a');
    if (external) {
        const blob = await apiFetch(link, { rawResponse: true }).then((r) => r.blob());
        link = URL.createObjectURL(blob);
    }
    a.href = link;
    a.style.display = 'none';
    a.setAttribute('download', name);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
