import styles from '$views/cast/CastFolder/DataLayoutCastFolder/DataLayoutCastFolder.module.css';
import React from 'react';
import type { CastContentChild } from '@he-novation/config/types/cast.types';
import { asyncIncreaseCastViews } from '@he-novation/front-shared/async/cast.async';
import { FrontCastFolderChild } from '@he-novation/front-shared/types/cast.front-types';
import { getLocalizedDate } from '@he-novation/front-shared/utils/datetime';
import { bytesToSize } from '@he-novation/utils/bytes';
import { useAtom } from 'jotai';

import { castPasswordAtom } from '$atoms/cast-atoms';
import { DataLayoutColumn, DataLayoutGrouper } from '$components/DataLayout/DataLayout.types';
import {
    durationGrouperFactory,
    fileTypeGrouperFactory,
    resolutionGrouperFactory,
    statusGrouperFactory,
    versionNumberGrouperFactory
} from '$components/DataLayout/grouperFunctions';
import { sorterFunctionFactory } from '$components/DataLayout/sorterFunctions';
import { Translator, useTranslate } from '$hooks/useTranslate';
import { DataLayoutCastFigure } from '$views/cast/components/DataLayoutCastFigure/DataLayoutCastFigure';

export const getCastLocaleStorageKey = (castUid: string, type: string) => `cast-${castUid}-${type}`;

export const increaseCastViews = (castUid: string) => {
    const castLocalStorageKey = getCastLocaleStorageKey(castUid, 'views-timestamp');
    const now = Date.now();
    if (localStorage.getItem(castLocalStorageKey)) {
        const timestamp = parseInt(localStorage.getItem(castLocalStorageKey) || '0');
        if (now - timestamp < 3600000) return false;
    }
    localStorage.setItem(castLocalStorageKey, now.toString());
    return asyncIncreaseCastViews(castUid);
};

export function useCastPassword(castUid: string) {
    const [castPassword, setCastPassword] = useAtom(castPasswordAtom);
    const castLocalStoragePasswordKey = getCastLocaleStorageKey(castUid, 'password');

    const password = castPassword || localStorage.getItem(castLocalStoragePasswordKey) || undefined;
    return {
        password,
        setCastPassword(password: string) {
            setCastPassword(password);
            localStorage.setItem(castLocalStoragePasswordKey, password);
        }
    };
}

export function useDataLayoutCastColumns(
    locale: string
): DataLayoutColumn<FrontCastFolderChild, undefined, undefined>[] {
    const { t } = useTranslate();
    return [
        {
            key: 'full',
            grow: true,
            width: 700,
            header: t('common.Name'),
            sort: sorterFunctionFactory('alphanumeric', 'name'),
            render: (item: FrontCastFolderChild) => {
                const content = (
                    <>
                        <DataLayoutCastFigure className={styles.figure} item={item} />
                        <h3>{item.name}</h3>
                    </>
                );

                return <div className={styles.item}>{content}</div>;
            }
        },
        {
            key: 'size',
            width: 200,
            header: t('common.Size'),
            sort: (_a: FrontCastFolderChild, _b: FrontCastFolderChild) => {
                const a: number | undefined = getCastItemSize(_a);
                const b: number | undefined = getCastItemSize(_b);
                if (typeof a === 'undefined') return -1;
                if (typeof b === 'undefined') return 1;
                return a - b;
            },
            render: (item: FrontCastFolderChild) => {
                const castItemSize = getCastItemSize(item);
                if (castItemSize) return bytesToSize(castItemSize, locale);
                return null;
            }
        },
        {
            key: 'created',
            width: 200,
            header: t('common.Creation date'),
            sort: sorterFunctionFactory('date', 'created'),
            render: (item: FrontCastFolderChild) =>
                item.created &&
                getLocalizedDate(
                    typeof item.created === 'string' ? new Date(item.created) : item.created,
                    locale
                )
        }
    ];
}

export function getDataLayoutCastGroupers(
    t: Translator
): DataLayoutGrouper<CastContentChild<false>>[] {
    return [
        {
            key: 'type',
            label: t('folder.Type'),
            grouperFunction: fileTypeGrouperFactory(t)
        },
        {
            key: 'version',
            label: t('common.Version'),
            grouperFunction: versionNumberGrouperFactory(t)
        },
        {
            key: 'final',
            label: t('common.Status'),
            grouperFunction: statusGrouperFactory(t)
        },
        {
            key: 'duration',
            label: t('folder.Duration'),
            grouperFunction: durationGrouperFactory(t)
        },
        {
            key: 'resolution',
            label: t('folder.Resolution'),
            grouperFunction: resolutionGrouperFactory(t)
        }
    ];
}

export function getCastItemSize(item: FrontCastFolderChild): number | undefined {
    if (item.type === 'file') {
        return item.assets?.find((a) => a.quality === 'source')?.size;
    }
    return;
}
