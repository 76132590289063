import { projectsLink } from '@he-novation/config/paths/herawFrontUris';
import { Member, TeamUpdateBody } from '@he-novation/config/types/payloads/team.payload';
import { Task } from '@he-novation/config/types/task.types';
import { Team } from '@he-novation/config/types/team.types';
import {
    asyncProjectMembersInvite as _inviteMembersToTeam,
    asyncTasksFetch,
    asyncTeamUpdate,
    createTask as _createTask,
    deleteTask as _deleteTask,
    deleteTeam as _deleteTeam,
    fetchProject as _fetchProject,
    fetchProjectEntities as _fetchProjectCompanies,
    fetchProjectTasks as _fetchProjectTasks,
    updateMultipleTaskStatusAndOrdering as _updateMultipleTaskStatusAndOrdering,
    updateTask as _updateTask
} from '@he-novation/front-shared/async/project.async';
import { Dispatch } from 'redux';
import { set as setRoute } from '../../route/routeActions';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionSuccess } from '$helpers/asyncAction';

export const PROJECTS = 'PROJECTS';
export const CREATE_PROJECT = `${PROJECTS}/CREATE_PROJECT`;
export const ARCHIVE_PROJECT = `${PROJECTS}/ARCHIVE_PROJECT`;
export const RESTORE_PROJECT = `${PROJECTS}/RESTORE_PROJECT`;
export const CREATE_TASK = `${PROJECTS}/CREATE_TASK`;
export const DELETE_TASK = `${PROJECTS}/DELETE_TASK`;
export const DELETE_TEAM = `${PROJECTS}/DELETE_TEAM`;
export const UPDATE_TEAM = `${PROJECTS}/UPDATE_TEAM`;
export const SET_SELECTED_TEAM = `${PROJECTS}/SET_SELECTED_TEAM`;
export const SET_LAST_PROJECT_UUID = `${PROJECTS}/SET_LAST_PROJECT_UUID`;
export const FETCH_PROJECT = `${PROJECTS}/FETCH_PROJECT`;
export const FETCH_PROJECT_TASKS = `${PROJECTS}/FETCH_PROJECT_TASKS`;
export const FETCH_PLANNING_TASKS = `${PROJECTS}/FETCH_PLANNING_TASKS`;
export const FETCH_PROJECTS = `${PROJECTS}/FETCH_PROJECTS`;
export const FETCH_PROJECT_ACTIVITY = `${PROJECTS}/FETCH_PROJECT_ACTIVITY`;
export const FETCH_PROJECT_LAST_ACTIVITY = `${PROJECTS}/FETCH_PROJECT_LAST_ACTIVITY`;
export const FETCH_PROJECT_COMPANIES = `${PROJECTS}/FETCH_PROJECT_COMPANIES`;
export const FETCH_TASK_ASSETS = `${PROJECTS}/FETCH_TASK_ASSETS`;
export const TEAM_INVITE_MEMBERS = `${PROJECTS}/TEAM_INVITE_MEMBERS`;
export const FETCH_CURRENT_USER_PROJECTS = `${PROJECTS}/FETCH_CURRENT_USER_PROJECTS`;
export const UPDATE_CURRENT_PROJECT = `${PROJECTS}/UPDATE_CURRENT_PROJECT`;
export const UPDATE_TASK = `${PROJECTS}/UPDATE_TASK`;
export const UPDATE_TASK_STATUS_ORDER = `${PROJECTS}/UPDATE_TASK_STATUS_ORDER`;
export const UPDATE_USER_METADATA = `${PROJECTS}/UPDATE_USER_METADATA`;

export const WS_ARCHIVE_PROJECT = `${WS_PREFIX_IN}${ARCHIVE_PROJECT}`;
export const WS_RESTORE_PROJECT = `${WS_PREFIX_IN}${RESTORE_PROJECT}`;
export const WS_UPDATE_CURRENT_PROJECT = `${WS_PREFIX_IN}${UPDATE_CURRENT_PROJECT}`;
export const WS_TASK_CREATE = `${WS_PREFIX_IN}${CREATE_TASK}`;
export const WS_TASK_UPDATE = `${WS_PREFIX_IN}${UPDATE_TASK}`;
export const WS_TASK_DELETE = `${WS_PREFIX_IN}${DELETE_TASK}`;

export const createTask = (body, cb, onError?: (error: unknown) => void) => async (dispatch) => {
    dispatch({ type: CREATE_TASK });
    try {
        const r = await _createTask(body);
        cb?.(r);
    } catch (e) {
        onError?.(e);
    }
};

export const deleteTask = (taskUuid) => async (dispatch) => {
    dispatch({ type: DELETE_TASK });
    await _deleteTask(taskUuid);
};

export const deleteTeam = (teamUuid, projectUuid, cb) => async (dispatch) => {
    dispatch({ type: DELETE_TEAM });
    await _deleteTeam(teamUuid, projectUuid);
    if (typeof cb === 'function') cb();
};

export const updateTeam =
    (teamUuid: string, body: TeamUpdateBody, cb?: (team: Team) => void) =>
    async (dispatch: Dispatch) => {
        dispatch({ type: UPDATE_TEAM });
        const team = await asyncTeamUpdate(teamUuid, body);
        dispatch({ type: asyncActionSuccess(UPDATE_TEAM), team });
        cb?.(team);
    };

export const fetchProject = (uuid) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT });
    try {
        const response = await _fetchProject(uuid);
        dispatch({
            type: asyncActionSuccess(FETCH_PROJECT),
            project: {
                ...response
            }
        });
    } catch (e) {
        if (e.code === 404) dispatch(setRoute(projectsLink(), false, true));
    }
};

export const fetchProjectTasks = (projectUuid) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT_TASKS });
    const tasks = await _fetchProjectTasks(projectUuid);
    dispatch({ type: asyncActionSuccess(FETCH_PROJECT_TASKS), tasks });
};

export const fetchPlanningTasks = (startDate, endDate) => async (dispatch) => {
    dispatch({ type: FETCH_PLANNING_TASKS });
    const planningTasks = await asyncTasksFetch({ startDate, endDate });

    dispatch({ type: asyncActionSuccess(FETCH_PLANNING_TASKS), planningTasks });
};

export const fetchProjectCompanies = () => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT_COMPANIES });
    const companies = await _fetchProjectCompanies();
    dispatch({ type: asyncActionSuccess(FETCH_PROJECT_COMPANIES), companies });
};

export const inviteMembersToTeam =
    (
        projectUuid: string,
        teamName: string,
        members: Member[],
        castTeamAccess?: boolean,
        message?: string,
        color?: string,
        cb?: (error: unknown | null) => void
    ) =>
    async (dispatch) => {
        dispatch({ type: TEAM_INVITE_MEMBERS });
        try {
            await _inviteMembersToTeam(
                projectUuid,
                teamName,
                members,
                castTeamAccess,
                message,
                color
            );
            dispatch({ type: asyncActionSuccess(TEAM_INVITE_MEMBERS) });
            if (typeof cb === 'function') cb(null);
        } catch (e) {
            if (typeof cb === 'function') cb(e);
        }
    };

export const updateTask =
    (taskUuid: string, data: any, cb?: (task: Task) => void, onError?: (e: unknown) => void) =>
    async (dispatch) => {
        dispatch({ type: UPDATE_TASK });
        try {
            const task = await _updateTask({ taskUuid, ...data });
            if (typeof cb === 'function') cb(task);
        } catch (e) {
            onError?.(e);
        }
    };

export const updateMultipleTaskStatusAndOrdering = (changes) => async (dispatch) => {
    dispatch({ type: UPDATE_TASK_STATUS_ORDER });
    await _updateMultipleTaskStatusAndOrdering(changes);
};

export const sioProjectTasksSocketAction = {
    sioTaskCreate: (task) => ({
        type: WS_TASK_CREATE,
        task
    }),
    sioTaskUpdate: ({ task, orderChangesByUuid }) => ({
        type: WS_TASK_UPDATE,
        task,
        orderChangesByUuid
    }),
    sioTaskDelete: (taskUuid) => ({
        type: WS_TASK_DELETE,
        taskUuid
    })
};

export const sioProjectSocketAction = {
    sioProjectUpdate: (project) => ({
        type: WS_UPDATE_CURRENT_PROJECT,
        project
    })
};

export const PROJECT_SET_NOTIFICATION_SETTINGS = `${PROJECTS}/SET_NOTIFICATION_SETTINGS`;

export function projectSetNotificationSettings() {
    return {
        type: PROJECT_SET_NOTIFICATION_SETTINGS
    };
}
