import styles from './Chips.module.css';
import React, { ReactNode, SyntheticEvent } from 'react';
import cn from 'classnames';

export enum ChipsTheme {
    Rounded = 'rounded',
    Square = 'square'
}
interface ChipsProps {
    onClick?: (e: SyntheticEvent<HTMLSpanElement, MouseEvent>) => void;
    onDelete?: () => void;
    children: ReactNode | ReactNode[];
    color?: string;
    background?: string;
    className?: string;
    theme?: ChipsTheme;
}

function Chips({
    onDelete,
    children,
    onClick,
    color,
    background,
    className,
    theme = ChipsTheme.Rounded
}: ChipsProps) {
    return (
        <span
            className={cn(styles.chips, className, styles[theme])}
            onClick={onClick}
            role={onClick ? 'button' : undefined}
            style={{
                color,
                background
            }}
        >
            {children}
            {onDelete && (
                <button onClick={() => onDelete()} type="button">
                    delete
                </button>
            )}
        </span>
    );
}

export default Chips;
