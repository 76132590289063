import {
    OPEN_TOAST,
    DISMISS_TOAST,
    ACTIVITY_NEW_INCREMENT,
    ACTIVITY_NEW_RESET,
    TOGGLE_KEYBOARD_LISTENERS
} from './uiActions';
import update from 'immutability-helper';

export const initialState = {
    countNewActivities: 0,
    keyboardListeners: true
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case OPEN_TOAST:
            return {
                ...state,
                toast: action.toast
            };

        case DISMISS_TOAST:
            return {
                ...state,
                toast: null
            };

        case ACTIVITY_NEW_INCREMENT:
            return {
                ...state,
                countNewActivities: state.countNewActivities + 1
            };

        case ACTIVITY_NEW_RESET:
            return {
                ...state,
                countNewActivities: 0
            };

        case TOGGLE_KEYBOARD_LISTENERS:
            return update(state, {
                keyboardListeners: { $set: action.active }
            });

        default:
            return state;
    }
};
