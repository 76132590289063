/*
 * VIEWS
 */
export const VIEW_PROJECTS = 'view_project';
export const VIEW_TASKS = 'view_tasks';
export const VIEW_CONTACTS = 'view_contacts';
export const VIEW_ACTIVITY = 'view_activity';

/*
 * DISPLAY
 */
export const DISPLAY = 'display';
export const DISPLAY_LIST = 'list';
export const DISPLAY_MOSAIC = 'mosaic';
export const DISPLAY_COLUMNS = 'columns';

/*
 * STORAGE
 */
export const STORAGE_GB = 1024 * 1024 * 1024;
export const STORAGE_MB = 1024 * 1024;

/*
 * CLIENT
 */
export const CLIENT_PRIVATE_NAME = 'private';
