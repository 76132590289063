export function userToUuid(
    u:
        | { uuid: string; user_uuid?: string; userUuid?: string }
        | { user_uuid: string; userUuid?: string; uuid?: string }
        | { userUuid: string; user_uuid?: string; uuid?: string }
): string {
    return (
        typeof u.user_uuid !== 'undefined'
            ? u.user_uuid
            : typeof u.userUuid !== 'undefined'
            ? u.userUuid
            : u.uuid
    ) as string;
}
