export function isBinary(file: File) {
    return new Promise<boolean>((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = async function () {
            const arrayBuffer = await file.arrayBuffer();
            const uint8Array = new Uint8Array(arrayBuffer);
            let isBinary = false;
            for (let i = 0; i < uint8Array.length; i++) {
                const byte = uint8Array[i];
                if (byte > 127 || (byte < 32 && byte !== 9 && byte !== 10 && byte !== 13)) {
                    isBinary = true;
                    break;
                }
            }
            return resolve(isBinary);
        };
        reader.readAsArrayBuffer(file);
    });
}
