import React from 'react';
import type { FieldComponentProps } from 'react-modular-forms';
import { InputMask } from '@react-input/mask';
import type { MaskProps } from '@react-input/mask/types';

export function ReactInputMask({
    disabled,
    value,
    onBlur,
    onFocus,
    onChange,
    errors,
    validation,
    componentRef,
    setComponentRef,
    children,
    formId,
    errorHtmlElement,
    setValue,
    mask,
    replacement,
    type,
    ...intrinsic
}: React.HTMLProps<HTMLInputElement> & FieldComponentProps & MaskProps) {
    const sharedProps = {
        ref: componentRef,
        onChange,
        onFocus,
        onBlur,
        'aria-invalid': !!errors,
        'aria-required': validation?.required,
        disabled,
        value,
        mask,
        replacement,
        ...intrinsic
    };

    return (
        <>
            <InputMask type="text" {...sharedProps} /> {children}
        </>
    );
}
