import { Note } from '@he-novation/config/types/note.types';
import { atom } from 'jotai';

export const selectedNoteAtom = atom<string | undefined | null>(undefined);
export const creatingNoteAtom = atom<boolean>(false);
export const creatingTaskAtom = atom<boolean>(false);

export const notesAtom = atom<Note[]>([]);
export const castNotesAtom = atom<Record<string, Note>>({});

export const displayAllVersionsAtom = atom<boolean>(false);
