import type { ToastProps } from '@he-novation/design-system/components/widgets/Toast/Toast';

export const UI = 'UI';
export const OPEN_TOAST = `${UI}/OPEN_TOAST`;
export const DISMISS_TOAST = `${UI}/DISMISS_TOAST`;
export const ACTIVITY_NEW_INCREMENT = `${UI}/ACTIVITY_NEW_INCREMENT`;
export const ACTIVITY_NEW_RESET = `${UI}/ACTIVITY_NEW_RESET`;
export const TOGGLE_KEYBOARD_LISTENERS = `${UI}/TOGGLE_KEYBOARD_LISTENERS`;

export const openToast = (payload: ToastProps) => ({
    type: OPEN_TOAST,
    toast: payload
});

export const dismissToast = () => ({
    type: DISMISS_TOAST
});

export const activityNewIncrement = () => ({
    type: ACTIVITY_NEW_INCREMENT
});

export const activityNewReset = () => ({
    type: ACTIVITY_NEW_RESET
});

export const toggleKeyboardListeners = (active: boolean) => ({
    type: TOGGLE_KEYBOARD_LISTENERS,
    active
});
