import {
    AreaSelectionCircle,
    AreaSelectionFreehand,
    AreaSelectionOutputData,
    AreaSelectionRectangle
} from '@he-novation/config/types/area-selection.types';
import { FileEnum } from '@he-novation/config/types/file.types';
import { scaleToFit } from '@he-novation/utils/scale';

type ThumbnailOptions = Partial<AreaSelectionOutputData> & {
    width?: number;
    height?: number;
    zoomed?: boolean;
};
const drawZoomed = (
    ctx: CanvasRenderingContext2D,
    rectangle: AreaSelectionRectangle,
    htmlMediaElement: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement,
    eWidth: number,
    eHeight: number,
    canvasWidth: number,
    canvasHeight: number,
    circle?: AreaSelectionCircle[],
    freehand?: AreaSelectionFreehand[]
) => {
    const scaledSourceRectangle = {
        x: eWidth * rectangle.x,
        y: eHeight * rectangle.y,
        width: eWidth * rectangle.width,
        height: eHeight * rectangle.height
    };

    const thumbnail = scaleToFit(scaledSourceRectangle, {
        width: canvasWidth,
        height: canvasHeight
    });

    if (!freehand && circle && !!circle.length) {
        const CIRCLE_RADIUS = 50;
        const scaledSourceCircleRectangle = {
            x: eWidth * (circle[0].x - CIRCLE_RADIUS / canvasWidth),
            y: eHeight * (circle[0].y - CIRCLE_RADIUS / canvasHeight),
            width: eWidth * ((CIRCLE_RADIUS * 2) / canvasWidth),
            height: eHeight * ((CIRCLE_RADIUS * 2) / canvasHeight)
        };

        const thumbnail = scaleToFit(scaledSourceCircleRectangle, {
            width: canvasWidth,
            height: canvasHeight
        });

        ctx.drawImage(
            htmlMediaElement,
            scaledSourceCircleRectangle.x,
            scaledSourceCircleRectangle.y,
            scaledSourceCircleRectangle.width,
            scaledSourceCircleRectangle.height,
            thumbnail.x,
            thumbnail.y,
            thumbnail.width,
            thumbnail.height
        );
    } else {
        ctx.drawImage(
            htmlMediaElement,
            scaledSourceRectangle.x,
            scaledSourceRectangle.y,
            scaledSourceRectangle.width,
            scaledSourceRectangle.height,
            thumbnail.x,
            thumbnail.y,
            thumbnail.width,
            thumbnail.height
        );
    }
};

const drawUnzoomed = (
    ctx: CanvasRenderingContext2D,
    freehand: AreaSelectionFreehand[] | undefined,
    rectangle: AreaSelectionRectangle,
    htmlMediaElement: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement,
    eWidth: number,
    eHeight: number,
    canvasWidth: number,
    canvasHeight: number
) => {
    const thumbnail = scaleToFit(
        {
            width: eWidth,
            height: eHeight
        },
        {
            width: canvasWidth,
            height: canvasHeight
        }
    );

    ctx.drawImage(
        htmlMediaElement,
        0,
        0,
        eWidth,
        eHeight,
        thumbnail.x,
        thumbnail.y,
        thumbnail.width,
        thumbnail.height
    );

    const left = rectangle.x * thumbnail.width + thumbnail.x;
    const top = rectangle.y * thumbnail.height + thumbnail.y;
    const right = left + rectangle.width * thumbnail.width;
    const bottom = top + rectangle.height * thumbnail.height;

    if (!freehand) {
        ctx.fillStyle = 'rgba(0,0,0,.5)';
        ctx.fillRect(0, 0, left, canvasHeight);
        ctx.fillRect(left, 0, canvasWidth, top);
        ctx.fillRect(left, bottom, canvasWidth - left, canvasHeight);
        ctx.fillRect(right, top, canvasWidth - right, rectangle.height * canvasHeight);

        ctx.strokeStyle = 'white';
        ctx.lineWidth = 3;
        ctx.translate(0.5, 0.5);
        ctx.moveTo(left, top);
        ctx.lineTo(right, top);
        ctx.lineTo(right, bottom);
        ctx.lineTo(left, bottom);
        ctx.lineTo(left, top);
        ctx.stroke();
    }
};

export const htmlElementToScaledBase64 = (
    htmlMediaElement: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement,
    { width, height, rectangle, freehand, circle, zoomed = true }: ThumbnailOptions = {}
): string => {
    const canvas = document.createElement('canvas');

    const eWidth =
        htmlMediaElement instanceof HTMLVideoElement
            ? htmlMediaElement.videoWidth
            : htmlMediaElement.width;
    const eHeight =
        htmlMediaElement instanceof HTMLVideoElement
            ? htmlMediaElement.videoHeight
            : htmlMediaElement.height;

    canvas.width = width || eWidth;
    canvas.height = height || eHeight;

    const ctx = canvas.getContext('2d')!;
    ctx.fillStyle = 'black';
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fill();

    if (!rectangle) rectangle = { x: 0, y: 0, width: 1, height: 1 };

    if (zoomed)
        drawZoomed(
            ctx,
            rectangle,
            htmlMediaElement,
            eWidth,
            eHeight,
            canvas.width,
            canvas.height,
            circle,
            freehand
        );
    else
        drawUnzoomed(
            ctx,
            freehand,
            rectangle,
            htmlMediaElement,
            eWidth,
            eHeight,
            canvas.width,
            canvas.height
        );

    return canvas.toDataURL('image/jpeg', 1);
};

export const imageToScaledBase64 = (src: string, options: ThumbnailOptions): Promise<string> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => resolve(htmlElementToScaledBase64(img, options));
        img.src = src;
    });
};

export function metadataToThumbnailOptions(
    metadata: AreaSelectionOutputData,
    zoomed?: boolean
): ThumbnailOptions {
    return {
        width: 672,
        height: 378,
        rectangle: metadata.rectangle,
        freehand: metadata.freehand,
        circle: metadata.circle,
        zoomed
    };
}

export type ThumbnailGenerationData =
    | {
          fileType: FileEnum.Image | FileEnum.PDF;
          src: string;
      }
    | {
          fileType: FileEnum.HTML;
          iframeCapture: string;
      }
    | {
          fileType: FileEnum.Video;
          element: HTMLVideoElement;
      }
    | {
          fileType: FileEnum.Audio;
          element: HTMLCanvasElement;
      };
export const getThumbnail = (
    data: ThumbnailGenerationData,
    metadata: AreaSelectionOutputData,
    zoomed?: boolean
) => {
    const options = metadataToThumbnailOptions(metadata, zoomed);
    switch (data.fileType) {
        case FileEnum.HTML:
            return imageToScaledBase64(data.iframeCapture, options);
        case FileEnum.Image:
        case FileEnum.PDF:
            return imageToScaledBase64(data.src, options);
        case FileEnum.Video:
        case FileEnum.Audio:
            return htmlElementToScaledBase64(data.element, options);
    }
};
