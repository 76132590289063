import {
    AreaSelectionCircle,
    AreaSelectionFreehand,
    AreaSelectionOutputData,
    AreaSelectionState,
    AreaSelectionType
} from '@he-novation/config/types/area-selection.types';

type Point = {
    x: number;
    y: number;
};
export const normalizePoints = (sc: HTMLCanvasElement, points: Point[]) => {
    return points.map((p) => ({
        x: p.x / sc.width,
        y: p.y / sc.height
    }));
};

export const getBoundingRectangle = (points: Point[]) => {
    return points.reduce((acc, p) => {
        if (!acc[0] || p.x < acc[0]) acc[0] = p.x;
        if (!acc[1] || p.y < acc[1]) acc[1] = p.y;
        if (!acc[2] || p.x > acc[2]) acc[2] = p.x;
        if (!acc[3] || p.y > acc[3]) acc[3] = p.y;
        return acc;
    }, [] as number[]) as [number, number, number, number];
};

export const pointsToRectangle = (points: Point[]) => {
    const _boundingRectangle = getBoundingRectangle(points);
    return {
        x: _boundingRectangle[0],
        y: _boundingRectangle[1],
        width: _boundingRectangle[2] - _boundingRectangle[0],
        height: _boundingRectangle[3] - _boundingRectangle[1]
    };
};

export const getShapeOptions = (type?: AreaSelectionType, bg?: boolean) => {
    const obj: Partial<{
        rounded: boolean;
        renderPoints: boolean;
        isCircle: boolean;
        renderingContext: (c2d: CanvasRenderingContext2D) => void;
    }> = {};
    let lineWidth = 3;

    if (type === AreaSelectionType.Rectangle) {
        //background is only there on note add
        if (!bg) {
            obj.rounded = true;
            obj.renderPoints = false;
            lineWidth = 2;
        } else {
            lineWidth = 2;
            obj.renderPoints = true;
        }
    }

    if (type === AreaSelectionType.Circle) {
        obj.isCircle = true;
    }

    obj.renderingContext = function (c2d) {
        c2d.lineWidth = lineWidth;
        c2d.shadowColor = '#000';
        c2d.shadowBlur = 10;
        c2d.shadowOffsetX = 1;
        c2d.shadowOffsetY = 1;
        if (bg) c2d.setLineDash([8, 4]);
    };
    return obj;
};

export const mapAreaSelectionToMetadata = (state: AreaSelectionState): AreaSelectionOutputData => {
    if (state.type === AreaSelectionType.Freehand) {
        const freehand = state.selection
            .filter((s) => !!s.freehand)
            .map((s) => s.freehand) as AreaSelectionFreehand[];
        const circle = state.selection
            .filter((s) => !!s.circle)
            .map((s) => s.circle) as AreaSelectionCircle[];

        const circleRectanglePoints = circle.map(({ x, y, radiusToWidth, radiusToHeight }) => [
            { x: x - radiusToWidth, y: y - radiusToHeight },
            { x: x + radiusToWidth, y: y - radiusToHeight },
            { x: x + radiusToWidth, y: y + radiusToHeight },
            { x: x - radiusToWidth, y: y + radiusToHeight }
        ]);

        return {
            freehand,
            circle,
            rectangle: pointsToRectangle(
                freehand.concat(circleRectanglePoints).reduce((acc, curr) => [...acc, ...curr], [])
            )
        };
    }

    return {
        rectangle: state.selection?.[0]?.rectangle
    };
};
