import plugins from '@he-novation/config/utils/plugins';
import { camelCase, includes, pipe, property, upperFirst } from 'lodash/fp';
import filter from 'lodash/fp/filter';
import { hasPluginSelector, pluginsSelector } from '../user/userSelectors';
import { folderPluginsSelector } from './folder/folderSelectors';
import { CONTENT } from './contentActions';

import combineSelectors from '$helpers/combineSelectors';
import type { ReduxState } from '$redux/store';
import { FOLDER } from '$redux/storeNames';
import { USER } from '$redux/user/userActions';

export const stateSelector = (state: ReduxState) => state[CONTENT];

export const hasFolderPlugin = (plugin: string) => {
    return (state: ReduxState) => {
        const folder = state[CONTENT][FOLDER];
        if (folder.shared) {
            return folder.plugins.includes(plugin);
        } else {
            return state[USER].plugins.includes(plugin);
        }
    };
};

export const combinedPluginsSetSelector = pipe(
    combineSelectors(folderPluginsSelector, pluginsSelector),
    ({ folderPlugins, plugins }) => new Set([...folderPlugins, ...plugins])
);
export const hasPluginOrFolderPluginSelector = (plugin: string, shared?: boolean) =>
    shared
        ? pipe(
              stateSelector,
              property(FOLDER),
              property('plugins'),
              includes(plugin),
              (hasPlugin) => ({
                  [`hasPlugin${upperFirst(camelCase(plugin))}`]: hasPlugin
              })
          )
        : hasPluginSelector(plugin);

export const exportPluginsOrFolderPluginsSelector = (shared?: boolean) =>
    pipe(
        shared ? folderPluginsSelector : pluginsSelector,
        property(shared ? 'folderPlugins' : 'plugins'),
        filter((plugin: string) => plugins.getTypes.export().includes(plugin)),
        (exportPlugins) => ({ exportPlugins })
    );
