import { atom } from 'jotai';

export const audioInitialState = {
    isMuted: false,
    isPlaying: false,
    isLooping: false,
    currentTime: 0,
    targetTime: undefined,
    timecode: '00:00:00.000',
    volume: 0.5,
    selectedRange: null,
    sampleRate: 0,
    duration: 0
};
export type AudioAtom = {
    isMuted: boolean;
    isPlaying: boolean;
    isLooping: boolean;
    currentTime: number;
    targetTime: number | undefined;
    timecode: string;
    volume: number;
    selectedRange: [number, number] | null;
    sampleRate: number;
    duration: number;
};
export const audioAtom = atom<AudioAtom>(audioInitialState);
