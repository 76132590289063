import styles from './ColorPicker.module.css';
import React, { useMemo } from 'react';
import { FormField, FormFieldProps } from '../../FormField';
import cn from 'classnames';

type ColorPickerProps = Omit<FormFieldProps, 'type'>;

export function ColorPicker({
    id,
    formId,
    name,
    className,
    value,
    children,
    ...props
}: ColorPickerProps) {
    const currentValue = useMemo(() => value, [value]);

    return (
        <FormField
            {...props}
            id={id}
            formId={formId}
            name={name}
            className={cn(styles.colorPicker, className)}
            value={value}
            type="color"
        >
            <p>{currentValue}</p>
            {children}
        </FormField>
    );
}
