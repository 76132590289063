import { pipe, property, pick } from 'lodash/fp';
import { UI } from './uiActions';

export const stateSelector = (obj) => property(UI)(obj);

export const toastSelector = pipe(stateSelector, property('toast'));

export const activityCountSelector = pipe(stateSelector, pick('countNewActivities'));

export const keyboardListenersSelector = pipe(stateSelector, pick('keyboardListeners'));
