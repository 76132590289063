import { useCallback } from 'react';
import { fileCopy, fileMove } from '@he-novation/front-shared/async/file.async';
import { asyncFolderCopyTree, folderMove } from '@he-novation/front-shared/async/folder.async';
import {
    FrontFolderContentFile,
    FrontFolderContentFileStatus,
    FrontFolderContentFileTranscodingCopying
} from '@he-novation/front-shared/types/file.front-types';
import {
    FrontFolderContentFolder,
    FrontFolderContentGhost
} from '@he-novation/front-shared/types/folder.front-types';
import update from 'immutability-helper';
import { useAtomValue, useSetAtom } from 'jotai';
import { cloneDeep } from 'lodash/fp';

import {
    folderContentAtom,
    folderContentWithGhostsAtom,
    folderCopyAtom,
    folderGhostsAtom,
    foldersStore
} from '$atoms/folder-atoms';
import { Translator } from '$hooks/useTranslate';
import { openFeedbackModalFromAtom } from '$redux/helpers';

export function useFolderContent() {
    const setFolderCopy = useSetAtom(folderCopyAtom);
    const folderContent = useAtomValue(folderContentWithGhostsAtom, {
        store: foldersStore
    });
    const setFolderContent = useSetAtom(folderContentAtom, {
        store: foldersStore
    });

    const updateFolderContentItem = useCallback((itemUuid: string, operation: any) => {
        setFolderContent((folderContent) => {
            if (!folderContent) return folderContent;
            const itemIndex = folderContent.findIndex((c) => c.uuid === itemUuid);
            if (itemIndex === -1) return folderContent;

            return update(folderContent, {
                [itemIndex]: operation
            });
        });
    }, []);

    const deleteFolderContentItem = useCallback((itemUuid: string) => {
        setFolderContent((folderContent) => {
            if (!folderContent) return folderContent;
            return folderContent.filter((f) => itemUuid !== f.uuid);
        });
    }, []);

    const pasteFolder = useCallback(
        async (
            copy:
                | {
                      cut?: boolean;
                      data: (FrontFolderContentFile | FrontFolderContentFolder)[];
                      type?: string;
                  }
                | undefined
                | null,
            targetFolderUuid: string,
            t: Translator,
            currentFolderUuid?: string
        ) => {
            if (!copy) return;

            const data = cloneDeep(copy.data);

            if (currentFolderUuid && currentFolderUuid === targetFolderUuid) {
                setFolderCopy(null);
            }

            if (currentFolderUuid && currentFolderUuid === targetFolderUuid) {
                setFolderContent((folderContent) =>
                    (folderContent ?? []).concat(
                        data
                            .filter((i) => i.type !== 'folder')
                            .map((item) => ({
                                ...item,
                                created: new Date(item.created),
                                updated: new Date(item.updated),
                                processingStatus: copy.cut
                                    ? item.processingStatus
                                    : ({
                                          status: FrontFolderContentFileStatus.COPYING
                                      } as FrontFolderContentFileTranscodingCopying)
                            }))
                    )
                );
            }

            const foldersToCopy = data.filter((f) => f.type === 'folder');
            if (copy.type === 'tree') {
                await doCopyTree(targetFolderUuid, foldersToCopy);
            } else {
                const filesToCopy = data.filter((f) => f.type === 'file');
                let fileExists: boolean;
                if (copy.cut) {
                    fileExists = await doCut(targetFolderUuid, filesToCopy, foldersToCopy);
                } else {
                    fileExists = await doCopy(
                        targetFolderUuid,
                        filesToCopy,
                        updateFolderContentItem,
                        deleteFolderContentItem
                    );
                }

                if (fileExists)
                    openFeedbackModalFromAtom(
                        t('folder.A file with the same name already exists in this folder')
                    );
            }
        },
        [updateFolderContentItem, deleteFolderContentItem]
    );

    return {
        folderContent,
        setFolderContent,
        deleteFolderContentItem,
        pasteFolder,
        updateFolderContentItem
    };
}

export function folderContentGhostCreate(ghost: FrontFolderContentGhost) {
    foldersStore.set(folderGhostsAtom, (folderGhosts) => [...folderGhosts, ghost]);
}

export function folderContentGhostDelete(ghostUuid: string) {
    foldersStore.set(folderGhostsAtom, (folderGhosts) =>
        folderGhosts.filter((g) => ghostUuid !== g.uuid)
    );
}

async function doCopy(
    folderUuid: string,
    filesToCopy: FrontFolderContentFile[],
    updateFolderContentItem: (itemUuid: string, operation: any) => void,
    deleteFolderContentItem: (itemUuid: string) => void
) {
    // split filesToCopy in batches of 5 files
    let fileExists = false;
    const fileBatches: FrontFolderContentFile[][] = [];
    for (const file of filesToCopy) {
        if (fileBatches.length === 0 || fileBatches[fileBatches.length - 1].length === 5) {
            fileBatches.push([]);
        }
        fileBatches[fileBatches.length - 1].push(file);
    }
    for (const batch of fileBatches) {
        const r = await Promise.all(
            batch.map(async (f) => {
                try {
                    return await fileCopy(f.uuid.replace(/-copy$/, ''), f.version, folderUuid);
                } catch (e) {
                    return { ...f, error: e?.error?.message };
                }
            })
        );
        batch.forEach((item, i) => {
            if (!r[i].error) {
                updateFolderContentItem(item.uuid, {
                    uuid: {
                        $set: r[i].uuid
                    }
                });
            } else {
                if (r[i].error === 'ERR_FILE_ALREADY_EXISTS') fileExists = true;
                deleteFolderContentItem(item.uuid);
            }
        });
    }

    return fileExists;
}

async function doCut(
    folderUuid: string,
    filesToCopy: FrontFolderContentFile[],
    foldersToCopy: FrontFolderContentFolder[]
) {
    let fileExists = false;
    await Promise.all(
        filesToCopy.map(async (f) => {
            try {
                await fileMove(f.uuid, folderUuid);
            } catch (e) {
                fileExists = true;
                if (e?.error?.message === 'ERR_FILE_ALREADY_EXISTS') fileExists = true;
                return { ...f, error: e?.error?.message };
            }
        })
    );
    await Promise.all(foldersToCopy.map((f) => folderMove(f.uuid, folderUuid)));

    return fileExists;
}

async function doCopyTree(folderUuid: string, foldersToCopy: FrontFolderContentFolder[]) {
    await Promise.all(
        foldersToCopy.map((f) => {
            return asyncFolderCopyTree(f.uuid, folderUuid, {
                renameIfExists: true
            });
        })
    );
}
