import React, { useRef } from 'react';
import { Calendar } from 'react-calendars';
import { AbsoluteContentButton } from '@he-novation/design-system/components/buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';

type TaskDatePickerProps = {
    date?: Date;
    pickDate: (date: Date) => void;
};

export function TaskDatePicker({ date, pickDate }: TaskDatePickerProps) {
    const ref = useRef<{ setIsVisible: (isVisible: boolean) => void }>();
    return (
        <AbsoluteContentButton
            tagName={Button}
            type="button"
            tone={ButtonTone.Hoverable}
            controlRef={ref}
            direction={[DirectionX.LeftInner, DirectionY.Top]}
            icon={[{ name: 'calendar', stroke: 'white', fill: 'none' }]}
            content={
                <div style={{ width: 290 }}>
                    <Calendar
                        selectedDay={date}
                        month={date?.getMonth()}
                        year={date?.getFullYear()}
                        onSelect={(_e, date) => {
                            pickDate(date);
                            ref.current?.setIsVisible(false);
                        }}
                    />
                </div>
            }
        />
    );
}
