import styles from './SidePanelFolderInfo.module.css';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FullFolder } from '@he-novation/config/types/folder.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { TagsField } from '@he-novation/design-system/components/form/TagsField/TagsField';
import { getLocalizedDate } from '@he-novation/front-shared/utils/datetime';
import { bytesToSize } from '@he-novation/utils/bytes';
import { getDisplayName } from '@he-novation/utils/user';

import Accordion from '$components/Accordion/Accordion';
import { KeyValueList } from '$components/base/KeyValueList/KeyValueList';
import { useTranslate } from '$hooks/useTranslate';
import { preferencesSelector } from '$redux/user/userSelectors';

type SidePanelFolderInfoProps = {
    onChange: (diff: { tags?: string[]; labels?: string[]; description?: string }) => void;
    folder: FullFolder;
    folderSize: number;
};

export function SidePanelFolderInfo({ folder, folderSize, onChange }: SidePanelFolderInfoProps) {
    const { preferences } = useSelector(preferencesSelector);
    const { t, locale } = useTranslate();

    const descriptionRef = useRef<HTMLTextAreaElement>();

    const infos = useMemo(() => {
        const infos = [
            {
                key: t('folder.Uploaded by {{user}}', { user: '' }),
                value: getDisplayName(folder.user) || ''
            },
            {
                key: t('common.Creation date'),
                value: getLocalizedDate(new Date(folder.created), locale)
            }
        ];

        if (folder.updated) {
            infos.push({
                key: t('common.Last updated'),
                value: getLocalizedDate(new Date(folder.created), locale)
            });
        }

        infos.push({ key: t('common.Size'), value: bytesToSize(folderSize, locale) });

        return infos;
    }, [folder, folderSize]);

    const { availableTags, availableLabels } = useMemo(() => {
        const availableLabels = new Set([...folder.labels, ...(preferences.labels || [])].sort());
        const availableTags = new Set([...folder.tags, ...(preferences.tags || [])].sort());

        return { availableLabels: [...availableLabels], availableTags: [...availableTags] };
    }, [folder, preferences]);

    return (
        <>
            <Accordion
                title={t('folder.General information')}
                icon={['file', 'magnifier']}
                isOpen={true}
                className={styles.infoAccordion}
                contentClassName={styles.infos}
            >
                <KeyValueList list={infos} />
            </Accordion>

            <Accordion
                title={t('common.Description')}
                icon={'text_left'}
                isOpen={true}
                className={styles.infoAccordion}
                contentClassName={styles.description}
            >
                <FormField
                    id={'folder-settings-form-description'}
                    type={'textarea'}
                    value={folder.metadata?.notes}
                    name={'description'}
                    componentRef={descriptionRef}
                />
                <Button
                    tone={ButtonTone.Primary}
                    className={styles.descriptionButton}
                    onClick={() => onChange({ description: descriptionRef.current?.value })}
                >
                    {t('common.Save')}
                </Button>
            </Accordion>

            <Accordion
                title={
                    <>
                        <span>{t('common.Labels')}</span>
                        <span className={styles.counterChips}>{folder.labels.length}</span>
                    </>
                }
                icon={'label'}
                isOpen={true}
                className={styles.infoAccordion}
            >
                <TagsField
                    tags={folder.labels}
                    autocompleteList={availableLabels}
                    setTags={(labels) => onChange({ labels })}
                    toggleOnSpace
                />
            </Accordion>

            <Accordion
                title={
                    <>
                        <span>{t('common.Tags')}</span>
                        <span className={styles.counterChips}>{folder.tags.length}</span>
                    </>
                }
                icon={'label'}
                isOpen={true}
                className={styles.infoAccordion}
            >
                <TagsField
                    tags={folder.tags}
                    autocompleteList={availableTags}
                    setTags={(tags) => onChange({ tags })}
                    toggleOnSpace
                />
            </Accordion>
        </>
    );
}
