import styles from './MainMenuAddButton.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    ADD_CONTACT,
    EVENT,
    ITEM,
    PROJECT_FORM,
    TASK_FORM
} from '@he-novation/config/paths/modals.constants';
import {
    ActionType,
    MultiActionButton
} from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { colorBgLight, zIndexMenu } from '@he-novation/design-system/constants/constants.style';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';

import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { canCreateProjectsSelector } from '$redux/user/userSelectors';

export function MainMenuAddButton() {
    const { t } = useTranslate();

    const { openModal } = useModal();

    const { canCreateProjects } = useSelector(canCreateProjectsSelector);

    const actions: ActionType[] = [
        {
            icon: ['calendar', 'plus'],
            children: t('common.New booking'),
            onClick: () => openModal(EVENT)
        },
        {
            icon: ['check_encircled', 'plus'],
            children: t('common.New task'),
            onClick: () => openModal(TASK_FORM)
        },
        // {
        //     icon: 'stop_watch',
        //     children: __('New Time Reporting'),
        //     onClick: () => {}
        // },
        {
            icon: ['user', 'plus'],
            children: t('common.New contact'),
            onClick: () => openModal(ADD_CONTACT)
        },
        {
            icon: ['shapes', 'plus'],
            children: t('common.New item'),
            onClick: () => openModal(ITEM)
        }
    ];

    if (canCreateProjects) {
        actions.unshift({
            icon: ['briefcase', 'plus'],
            children: t('common.New project'),
            onClick: () => openModal(PROJECT_FORM)
        });
    }

    return (
        <MultiActionButton
            icon={[{ name: 'plus', stroke: colorBgLight }]}
            className={styles.button}
            direction={[DirectionX.Right, DirectionY.BottomInner]}
            actions={actions}
            contentZIndex={zIndexMenu}
        ></MultiActionButton>
    );
}
