import pipe from 'lodash/fp/pipe';

export const MS_PER_SECOND = 1000;
export const MS_PER_MINUTE = MS_PER_SECOND * 60;
export const MS_PER_HOUR = MS_PER_MINUTE * 60;
export const MS_PER_DAY = MS_PER_HOUR * 24;
export const MS_PER_WEEK = MS_PER_DAY * 7;
const pad = (num: number) => ('00' + num).slice(-2);

export function isoStringToDate(isoString: string) {
    const b = isoString.split(/\D+/).map((n) => parseInt(n));
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

export function isoStringToUTCDate(isoString: string) {
    const b = isoString.split(/\D+/).map((n) => parseInt(n));
    return new Date(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]);
}

export function objectIsoStringsToDates<T>(keys: (keyof T)[]): (obj: T) => T {
    return (obj: T) => {
        for (const key of keys) {
            if (typeof obj[key] === 'string')
                obj[key as keyof T] = isoStringToDate(obj[key as keyof T] as string) as any;
        }
        return obj;
    };
}

export const dateToSqlDatetime = (date: Date) =>
    date.getUTCFullYear() +
    '-' +
    pad(date.getUTCMonth() + 1) +
    '-' +
    pad(date.getUTCDate()) +
    ' ' +
    pad(date.getUTCHours()) +
    ':' +
    pad(date.getUTCMinutes()) +
    ':' +
    pad(date.getUTCSeconds());

export const roundToMinutes = (
    date: Date | string | number,
    minutes = 15,
    method: 'round' | 'floor' | 'ceil' = 'round'
) => {
    date = new Date(date);
    date.setMinutes(Math[method](date.getMinutes() / minutes) * minutes);
    date.setSeconds(0);
    return date;
};

export const getTimeInHours = (date: Date): number =>
    date.getHours() + date.getMinutes() / 60 + date.getSeconds() / 3600;

export const addHours = (date: Date | string | number, hours: number, withMinutes?: boolean) => {
    date = new Date(date);
    date.setHours(date.getHours() + Math.floor(hours));
    if (withMinutes) {
        date.setMinutes(date.getMinutes() + (hours % 1) * 60);
    }
    return date;
};

export const subHours = (date: Date | string | number, hours: number, withMinutes?: boolean) => {
    date = new Date(date);
    date.setHours(date.getHours() - Math.floor(hours));
    if (withMinutes) {
        date.setMinutes(date.getMinutes() - (hours % 1) * 60);
    }
    return date;
};

export const addDays = (date: Date | string | number, days: number) => {
    date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
};

export const subDays = (date: Date | string | number, days: number) => {
    date = new Date(date);
    date.setDate(date.getDate() - days);
    return date;
};

export const msToSeconds = (ms: number) => ms / 1000;
export const msToMinutes = pipe(msToSeconds, (s: number) => s / 60);
export const msToHours = pipe(msToMinutes, (s: number) => s / 60);
export const msToDays = pipe(msToHours, (s: number) => s / 24);

export const secondsToMs = (s: number) => s * 1000;
export const minutesToMs = pipe((m: number) => m * 60, secondsToMs);
export const hoursToMs = pipe((h: number) => h * 60, minutesToMs);
export const daysToMs = pipe((d: number) => d * 24, hoursToMs);
export const isSameDay = (d1: Date, d2: Date): boolean =>
    d1?.getFullYear() === d2?.getFullYear() &&
    d1?.getMonth() === d2?.getMonth() &&
    d1?.getDate() === d2?.getDate();

export const setEarliestHour = (_date: Date | string, utc?: boolean) => {
    const date = new Date(_date);
    date[utc ? 'setUTCHours' : 'setHours'](0, 0, 0, 0);
    return date;
};
export const setLatestHour = (_date: Date | string, utc?: boolean) => {
    const date = new Date(_date);
    date[utc ? 'setUTCHours' : 'setHours'](23, 59, 59, 999);
    return date;
};

export const dateAsUTCDate = (date: Date) => {
    const UTCDate = new Date(date);
    UTCDate.setUTCDate(date.getDate());
    UTCDate.setUTCFullYear(date.getFullYear());
    UTCDate.setUTCMonth(date.getMonth());
    UTCDate.setUTCHours(date.getHours());
    UTCDate.setUTCMinutes(date.getMinutes());

    return UTCDate;
};

export function offsetSeconds(date: Date, seconds: number): Date {
    const result = new Date(date);
    result.setSeconds(result.getSeconds() + seconds);
    return result;
}

export function offsetMinutes(date: Date, minutes: number): Date {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
}

export const offsetDays = (date: Date | number, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const offsetMonths = (date: Date | number, months: number) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
};

export const offsetYears = (date: Date | number, years: number) => {
    const result = new Date(date);
    result.setFullYear(result.getFullYear() + years);
    return result;
};

export const getDateMinusAndPlusXDays = (
    date: Date,
    days: number
): { minusXDays: Date; plusXDays: Date } => {
    const minusXDays = new Date(date);
    minusXDays.setDate(date.getDate() - days);

    const plusXDays = new Date(date);
    plusXDays.setDate(date.getDate() + days);

    // return only the absolute dates without time
    minusXDays.setHours(0, 0, 0, 0);
    plusXDays.setHours(0, 0, 0, 0);

    return { minusXDays, plusXDays };
};

export const getDateMinusAndPlusMonth = (
    date: Date
): { minusOneMonth: Date; plusOneMonth: Date } => {
    const minusOneMonth = new Date(date);
    minusOneMonth.setMonth(date.getMonth() - 1);

    const plusOneMonth = new Date(date);
    plusOneMonth.setMonth(date.getMonth() + 1);

    // return only the absolute dates without time
    minusOneMonth.setHours(0, 0, 0, 0);
    plusOneMonth.setHours(0, 0, 0, 0);

    return { minusOneMonth, plusOneMonth };
};

export const getAllDaysBetweenDates = (startDate: Date, endDate: Date): Date[] => {
    const dates: Date[] = [];

    // Ensure startDate is before endDate
    const currentDate = new Date(startDate.getTime());

    // Loop until the currentDate exceeds endDate
    while (currentDate <= endDate) {
        // Push a new Date instance to the array to avoid mutation issues
        dates.push(new Date(currentDate));
        // Increment the currentDate by one day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
};

export const countDifferentDays = (date1: string, date2: string): number => {
    // Parse the input dates
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    // Determine the earlier and later date to handle date2 being before date1
    const firstDay = startDate < endDate ? startDate : endDate;
    const lastDay = startDate < endDate ? endDate : startDate;

    // Extract the year, month, and day to count unique days
    const startDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate());
    const endDay = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate());

    // Calculate the difference in milliseconds and convert it to days
    const diffTime = endDay.getTime() - startDay.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // Add 1 to count both start and end days

    return diffDays;
};

export const areDatesDifferent = (date1: Date, date2: Date): boolean => {
    // Extract the year, month, and day from both dates
    const firstYear = date1.getFullYear();
    const firstMonth = date1.getMonth();
    const firstDay = date1.getDate();

    const secondYear = date2.getFullYear();
    const secondMonth = date2.getMonth();
    const secondDay = date2.getDate();

    // Compare year, month, and day to determine if the dates are different
    return firstYear !== secondYear || firstMonth !== secondMonth || firstDay !== secondDay;
};

export const isDateInRange = (date: Date, startDate: Date, endDate: Date): boolean => {
    return date >= startDate && date <= endDate;
};

export const isValidDate = (date: string | Date): boolean => {
    const parsedDate = typeof date === 'string' ? new Date(date) : date;
    return !isNaN(parsedDate.getTime());
};

export const formatDateToISO = (date: Date) => date.toISOString().substring(0, 10);
