import { userSettingsLink } from '@he-novation/config/paths/herawFrontUris';
import { type FolderRole, FullFolder } from '@he-novation/config/types/folder.types';
import { FolderModifyAccessBody } from '@he-novation/config/types/payloads/folder.payload';
import {
    asyncFolderMembersFetch,
    grantAccess as _grantAccess,
    modifyAccess,
    revokeAccess as _revokeAccess
} from '@he-novation/front-shared/async/folder.async';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionError, asyncActionSuccess } from '$helpers/asyncAction';
import { Translator } from '$hooks/useTranslate';
import { set } from '$redux/route/routeActions';
import { FOLDER } from '$redux/storeNames';
import { openToast } from '$redux/ui/uiActions';

export const FETCH_FOLDER = `${FOLDER}/FETCH`;
export const FOLDER_ACCESS_MODIFY = `${FOLDER}/ACCESS_MODIFY`;

export const FOLDER_LOADED = `${FOLDER}/LOADED`;

export const GRANT_ACCESS = `${FOLDER}/GRANT_ACCESS`;
export const REVOKE_ACCESS = `${FOLDER}/REVOKE_ACCESS`;
export const FETCH_PLUGINS_ACCESS = `${FOLDER}/FETCH_PLUGINS_ACCESS`;
export const FOLDER_MEMBERS_FETCH = `${FOLDER}/MEMBERS_FETCH`;
export const SET_FOLDER_FILE = `${FOLDER}/SET_FOLDER_FILE`;
export const SETTINGS_SET = `${FOLDER}/SETTINGS_SET`;
export const USER_METADATA_SET = `${FOLDER}/USER_METADATA_SET`;

export const WS_SET_FOLDER = `${WS_PREFIX_IN}/FOLDER_SET`;
export const WS_SET_FOLDER_FILE = `${WS_PREFIX_IN}${SET_FOLDER_FILE}`;
export const WS_FOLDER_CAST = `${WS_PREFIX_IN}${FOLDER}/WS_FOLDER_CAST`;
export const WS_FOLDER_CAST_DELETE = `${WS_PREFIX_IN}${FOLDER}/WS_FOLDER_CAST_DELETE`;

export const WS_FOLDER_MEMBERS_ADD = `${WS_PREFIX_IN}${FOLDER}/WS_FOLDER_MEMBERS_ADD`;

export const WS_FOLDER_MEMBERS_REMOVE = `${WS_PREFIX_IN}${FOLDER}/WS_FOLDER_MEMBERS_REMOVE`;

export const WS_FILE_VERSION_DELETE = `${WS_PREFIX_IN}${FOLDER}/FILE_VERSION_DELETE`;
export const WS_FILE_VERSION_RESTORE = `${WS_PREFIX_IN}${FOLDER}/FILE_VERSION_RESTORE`;

export const folderMembersFetch = (folderUuid: string, pending?: boolean) => async (dispatch) => {
    dispatch({ type: FOLDER_MEMBERS_FETCH });
    const members = await asyncFolderMembersFetch(folderUuid, pending);
    dispatch({ type: asyncActionSuccess(FOLDER_MEMBERS_FETCH), folderUuid, members });
};

export const grantFolderAccess =
    (
        t: Translator,
        folderUuid: string,
        members: {
            uuid: string;
            role: FolderRole;
            canDownload: boolean;
            canExport: boolean;
        }[],
        message?: string | null
    ) =>
    async (dispatch) => {
        dispatch({ type: GRANT_ACCESS });
        try {
            await _grantAccess(folderUuid, members, message);
            dispatch({
                type: asyncActionSuccess(GRANT_ACCESS),
                members,
                folderUuid
            });
        } catch (e) {
            console.error(e);
            if (e.error?.message === 'ERR_LICENSES_INSUFFICIENT') {
                dispatch(
                    openToast({
                        content: t('misc.You don’t have enough licenses left'),
                        autoCloseAfterMs: 6000,
                        buttons: [
                            {
                                children: t('common.My account'),
                                onClick: () => dispatch(set(userSettingsLink('profile')))
                            }
                        ]
                    })
                );
            }
            dispatch({ type: asyncActionError(GRANT_ACCESS) });
        }
    };

export const folderAccessModify =
    (folderUuid: string, userUuid: string, { role, canDownload }: FolderModifyAccessBody) =>
    async (dispatch) => {
        dispatch({ type: FOLDER_ACCESS_MODIFY });

        await modifyAccess(folderUuid, userUuid, {
            role,
            canDownload
        });

        dispatch({
            type: asyncActionSuccess(FOLDER_ACCESS_MODIFY),
            folderUuid,
            userUuid,
            role,
            canDownload
        });
    };

export const revokeAccess = (folderUuid: string, userUuid: string) => async (dispatch) => {
    dispatch({ type: REVOKE_ACCESS });
    await _revokeAccess(folderUuid, userUuid);
    dispatch({ type: asyncActionSuccess(REVOKE_ACCESS), folderUuid, userUuid });
};

export const RESET = `${FOLDER}/RESET`;

export function resetFolder() {
    return {
        type: RESET
    };
}

export const FOLDER_SET_FOLDER = `${FOLDER}/SET_FOLDER`;

export function folderSetFolder(folder: FullFolder) {
    return {
        type: FOLDER_SET_FOLDER,
        folder
    };
}

export const FOLDER_SET_PLUGINS = `${FOLDER}/SET_PLUGINS`;

export function folderSetPlugins(plugins: string[]) {
    return {
        type: FOLDER_SET_PLUGINS,
        plugins
    };
}
