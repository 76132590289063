import React, { useEffect, useState } from 'react';
import { ModularForm } from 'react-modular-forms';
import { UserNotificationSettingsSchema } from '@he-novation/config/types/payloads/user.payload';
import { DEFAULT_NOTIFICATIONS_SETTINGS } from '@he-novation/config/types/user.types';
import { FormFields } from '@he-novation/design-system/components/form/FormField/FormField';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';

import { useTranslate } from '$hooks/useTranslate';

export function FormNotifications({
    notificationSettings,
    onChange,
    disabled,
    loading,
    formId
}: {
    notificationSettings: UserNotificationSettingsSchema | null;
    onChange: (settings: UserNotificationSettingsSchema) => void;
    defaultField?: boolean;
    disabled?: boolean;
    loading?: boolean;
    formId?: string;
}) {
    const _formId = formId || 'notification-settings';
    const { t } = useTranslate();
    const [settings, setSettings] = useState<UserNotificationSettingsSchema>(
        notificationSettings || DEFAULT_NOTIFICATIONS_SETTINGS
    );

    useEffect(() => {
        if (notificationSettings) setSettings(notificationSettings);
    }, [notificationSettings]);

    const settingKeys = [
        'commentCreated',
        'fileCreated',
        'fileFinalized',
        'fileDownloaded',
        'fileUnfinalized',
        'eventCreated',
        'eventInvitation',
        'eventUpdated',
        'memberJoined',
        'taskCreated',
        'taskUpdated',
        'taskAssigned'
    ];
    return (
        <ModularForm id={_formId} style={{ position: 'relative' }}>
            {loading && <Loader style={{ position: 'absolute', top: 0, left: 0 }} />}
            <FormFields
                formId={_formId}
                fields={[
                    {
                        name: 'digestFrequencyMinutes',
                        id: `${_formId}-digest-frequency`,
                        type: 'select',
                        disabled,
                        value: settings.digestFrequencyMinutes,
                        onChange: (e) => {
                            const newSettings = {
                                ...settings,
                                digestFrequencyMinutes: parseInt(e.target.value)
                            };
                            if (newSettings.digestFrequencyMinutes === 0) {
                                settingKeys.forEach((setting) => {
                                    newSettings[setting] = false;
                                });
                            }
                            setSettings(newSettings);
                            onChange(newSettings);
                        },
                        children: [
                            { value: 0, label: t('settings.Never') },
                            { value: 5, label: t('settings.Every {{n}} minutes', { n: 5 }) },
                            { value: 60, label: t('settings.Every hour') },
                            { value: 1440, label: t('settings.Every day') }
                        ].map((o) => (
                            <option key={o.value} value={o.value}>
                                {o.label}
                            </option>
                        ))
                    },
                    ...settingKeys.map((setting) => ({
                        name: setting,
                        id: `${_formId}-setting-field-${setting}`,
                        type: 'checkbox',
                        className: 'is-switch',
                        disabled,
                        label: t(`settings.__notification_${setting}`),
                        checked: settings[setting],
                        onChange: (e) => {
                            const newSettings = {
                                ...settings,
                                [setting]: e.target.checked
                            };
                            setSettings(newSettings);
                            onChange(newSettings);
                        }
                    }))
                ]}
            />
        </ModularForm>
    );
}
