import styles from './SidePanelList.module.css';
import React from 'react';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import {
    ActionType,
    MultiActionButton
} from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { ContextMenuActions } from '@he-novation/design-system/components/widgets/ContextMenu/ContextMenu';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';

import Link from '$components/router/Link';
import { useTranslate } from '$hooks/useTranslate';

export type SidePanelListItemType = {
    [other: string]: unknown;
    uuid: string;
    className?: string;
    href?: string;
    name: string;
    count: number;
};

type SidePanelListProps = {
    canEdit?: (item: SidePanelListItemType) => boolean;
    deleteItem?: (item: SidePanelListItemType) => void;
    editItem?: (item: SidePanelListItemType) => void;
    className?: string;
    contextMenuActions?: ContextMenuActions;
    items: SidePanelListItemType[];
};

export function SidePanelList({
    editItem,
    deleteItem,
    items,
    canEdit,
    className
}: SidePanelListProps) {
    return (
        <ul className={cn(styles.sidePanelList, className)}>
            {items.map((item, i) => (
                <SidePanelListItem
                    key={i}
                    item={item}
                    canEdit={canEdit}
                    editItem={editItem}
                    deleteItem={deleteItem}
                />
            ))}
        </ul>
    );
}

type SidePanelListItemProps = {
    item: SidePanelListItemType;
    canEdit: SidePanelListProps['canEdit'];
    editItem: SidePanelListProps['editItem'];
    deleteItem: SidePanelListProps['deleteItem'];
};

function SidePanelListItem({ item, canEdit, editItem, deleteItem }: SidePanelListItemProps) {
    const { t } = useTranslate();

    const actions: ActionType[] = [];

    if (editItem && (!canEdit || canEdit(item))) {
        actions.push({
            icon: 'pencil',
            children: t('common.Edit'),
            onClick: () => editItem(item)
        });
    }

    if (deleteItem && (!canEdit || canEdit(item))) {
        actions.push({
            icon: 'trash',
            children: t('common.Delete'),
            onClick: () => deleteItem(item)
        });
    }

    return (
        <li className={item.className}>
            <Link href={item.href} className={styles.link}>
                <div className={styles.count}>{item.count}</div>
                <h3 className={styles.name}>{item.name}</h3>
            </Link>
            {actions.length > 0 && (
                <MultiActionButton
                    actions={actions}
                    tone={ButtonTone.Hoverable}
                    direction={[DirectionX.RightInner, DirectionY.Top]}
                    icon={[{ name: 'three_dots', fill: 'white' }]}
                />
            )}
        </li>
    );
}
