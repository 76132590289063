import { ComponentType } from 'react';
import { i18n } from './i18n';
import cloneDeep from 'lodash/fp/cloneDeep';

export type DesignSystemConfiguration = {
    i18n?: Record<'en' | 'fr' | 'de', Record<string, string>>;
    locale: 'en' | 'fr' | 'de';
    RouterLinkComponent?: ComponentType;
};
export let RouterLink: ComponentType;

export const ICONS_URL: string = process.env.ICONS_URL!;

export const WORDING = cloneDeep(i18n);
export let LOCALE = 'en';

export function setConfiguration(c: DesignSystemConfiguration) {
    if (c.RouterLinkComponent) RouterLink = c.RouterLinkComponent;

    if (c.locale) {
        LOCALE = c.locale;
    }

    if (c.i18n) {
        for (const locale in c.i18n) {
            if (c.i18n[locale]) {
                WORDING[locale] = {
                    ...WORDING[locale],
                    ...c.i18n[locale]
                };
            }
        }
    }
}
