import styles from './ModalContent.module.css';
import React, { HTMLProps } from 'react';
import cn from 'classnames';

type ModalContentProps = {
    children: React.ReactNode | React.ReactNode[];
} & HTMLProps<HTMLDivElement>;
export function ModalContent({ children, className, ...props }: ModalContentProps) {
    return (
        <section className={cn('modal-content', styles.content, className)} {...props}>
            {children}
        </section>
    );
}
