import { asyncActionSuccess } from '$helpers/asyncAction';
import {
    ITEMS_FETCH,
    ITEMS_WITH_PROJECT_ITEMS_FETCH,
    ITEMS_KITS_FETCH,
    WS_ITEM_KIT_CREATE,
    WS_ITEM_KIT_UPDATE,
    WS_ITEM_KIT_DELETE,
    WS_ITEM_KIT_ADD_ITEM,
    WS_ITEM_KIT_REMOVE_ITEM,
    WS_ITEMS_CREATE,
    WS_ITEMS_DELETE,
    WS_ITEMS_UPDATE,
    ITEM_SET_PICTURE_ASSET
} from '../../content/items/itemsActions';
import update from 'immutability-helper';
import { v4 } from 'uuid';

export const itemsInitialState = {
    isLoaded: false,
    list: [],
    itemsWithProjectItemsList: [],
    kits: {
        isLoaded: false,
        list: []
    }
};

export default (state = itemsInitialState, action = {}) => {
    switch (action.type) {
        case asyncActionSuccess(ITEMS_FETCH):
            return update(state, {
                isLoaded: { $set: true },
                list: { $set: action.items }
            });
        case asyncActionSuccess(ITEMS_WITH_PROJECT_ITEMS_FETCH):
            return update(state, {
                isLoaded: { $set: true },
                itemsWithProjectItemsList: { $set: action.items }
            });
        case asyncActionSuccess(ITEMS_KITS_FETCH):
            return update(state, {
                kits: {
                    isLoaded: { $set: true },
                    list: { $set: action.itemKits }
                }
            });

        case ITEM_SET_PICTURE_ASSET: {
            const i = state.list.findIndex(({ uuid }) => uuid === action.itemUuid);
            if (i === -1) return state;
            return update(state, {
                list: {
                    [i]: {
                        pictureAsset: {
                            $set: {
                                url: action.url,
                                urlExpires: null,
                                uuid: `tmp-${v4()}`,
                                key: action.key
                            }
                        }
                    }
                }
            });
        }

        case WS_ITEMS_CREATE:
            return update(state, {
                list: { $push: [action.item] }
            });

        case WS_ITEMS_UPDATE: {
            const i = state.list.findIndex(({ uuid }) => uuid === action.item.uuid);
            if (i === -1) return state;

            return update(state, {
                list: {
                    [i]: { $merge: action.item }
                }
            });
        }

        case WS_ITEMS_DELETE: {
            const i = state.list.findIndex(({ uuid }) => uuid === action.uuid);
            if (i === -1) return state;

            return update(state, {
                list: { $splice: [[i, 1]] }
            });
        }

        case WS_ITEM_KIT_CREATE:
            return update(state, {
                kits: {
                    list: {
                        $push: [{ ...action.kit, items: [] }]
                    }
                }
            });
        case WS_ITEM_KIT_UPDATE: {
            const i = state.kits.list.findIndex(({ uuid }) => uuid === action.kit.uuid);
            if (i === -1) return state;

            return update(state, {
                kits: {
                    list: {
                        [i]: { $merge: action.kit }
                    }
                }
            });
        }
        case WS_ITEM_KIT_DELETE: {
            const i = state.kits.list.findIndex(({ uuid }) => uuid === action.uuid);
            if (i === -1) return state;

            return update(state, {
                kits: {
                    list: {
                        $splice: [[i, 1]]
                    }
                }
            });
        }
        case WS_ITEM_KIT_ADD_ITEM: {
            const i = state.kits.list.findIndex(({ uuid }) => uuid === action.data.itemKitUuid);
            const item = state.list.find(({ uuid }) => uuid === action.data.itemUuid);
            return update(state, {
                kits: {
                    list: {
                        [i]: {
                            items: {
                                $push: [item]
                            }
                        }
                    }
                }
            });
        }
        case WS_ITEM_KIT_REMOVE_ITEM: {
            const i = state.kits.list.findIndex(({ uuid }) => uuid === action.data.itemKitUuid);
            const itemIndex = state.kits.list[i].items.findIndex(
                ({ uuid }) => uuid === action.data.itemUuid
            );
            return update(state, {
                kits: {
                    list: {
                        [i]: {
                            items: {
                                $splice: [[itemIndex, 1]]
                            }
                        }
                    }
                }
            });
        }
    }

    return state;
};
