import React from 'react';
import {
    FormFields,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';

import { useTranslate } from '$hooks/useTranslate';

type FormCastFolderProps = {
    formId: string;
    submit: (e: any, data: any) => void;
};

export function FormCastFolder({ formId, submit }: FormCastFolderProps) {
    const { t } = useTranslate();
    return (
        <ModularForm id={formId} onSubmit={submit}>
            <FormFields
                formId={formId}
                fields={[
                    {
                        type: 'text',
                        name: 'name',
                        id: 'form-cast-folder-name',
                        label: t('common.Name'),
                        autoFocus: true
                    }
                ]}
            />
        </ModularForm>
    );
}
