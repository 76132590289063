import styles from './SidePanelGroups.module.css';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactGroupLink, contactsLink } from '@he-novation/config/paths/herawFrontUris';
import { ADD_GROUP, DELETE_CONFIRM, EDIT_GROUP } from '@he-novation/config/paths/modals.constants';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Title } from '@he-novation/design-system/components/text/Title/Title';
import { SidePanelList, SidePanelListItemType } from '../../SidePanel/SidePanelList/SidePanelList';

import combineSelectors from '$helpers/combineSelectors';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { deleteGroup } from '$redux/content/contacts/contactsActions';
import { groupsSelector } from '$redux/content/contacts/contactsSelectors';
import { set } from '$redux/route/routeActions';
import { routeParamsSelector } from '$redux/route/routeSelectors';
import { hasClientRightSelector } from '$redux/user/userSelectors';

export default function SidePanelGroups() {
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const { openModal, closeModal } = useModal();

    const {
        groups: { list },
        routeParams,
        hasClientRightContactAdmin
    } = useSelector(
        combineSelectors(
            groupsSelector,
            routeParamsSelector,
            hasClientRightSelector('contactAdmin')
        )
    );

    const addGroup = useCallback(() => openModal(ADD_GROUP), []);
    const removeGroup = useCallback(
        (uuid: string, routeParams: { route?: string; subroute?: string }) =>
            openModal(DELETE_CONFIRM, {
                onDelete: () => {
                    if (routeParams.route === 'contact-group' && routeParams.subroute === uuid)
                        dispatch(set(contactsLink()));

                    dispatch(deleteGroup(uuid));
                    closeModal();
                },
                message: t('common.Are you sure you want to delete this?'),
                title: t('common.Delete'),
                cancel: t('common.cancel')
            }),
        []
    );
    const editGroup = useCallback(
        (values: SidePanelListItemType) =>
            openModal(EDIT_GROUP, {
                values
            }),
        []
    );

    return (
        <div className={styles.goupsSidePanel}>
            <Title tag="h2" type="tab" className={styles.title} icon="users">
                {t('contact.Contact groups')}
            </Title>

            <div className={styles.content}>
                <SidePanelList
                    className={styles.list}
                    items={list.map(({ name, count_contacts, uuid, isClientGroup }) => ({
                        name,
                        count: count_contacts,
                        href: contactGroupLink(uuid),
                        uuid,
                        isClientGroup,
                        className: isClientGroup ? 'is-client-group' : undefined
                    }))}
                    canEdit={({ isClientGroup }) => !isClientGroup || hasClientRightContactAdmin}
                    editItem={editGroup}
                    deleteItem={({ uuid }) => removeGroup(uuid, routeParams)}
                />

                <Button icon={['user', 'plus']} tone={ButtonTone.Outlined} onClick={addGroup}>
                    {t('contact.Add contact group')}
                </Button>
            </div>
        </div>
    );
}
