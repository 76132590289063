import type { ComponentType } from 'react';
import React from 'react';
import { useAtom, WritableAtom } from 'jotai';
import { createStore } from 'jotai/vanilla';

import { capitalizeFirstLetter } from '$helpers/capitalizeFirstLetter';

export function withJotaiAtom(
    atom: WritableAtom<any, any, any>,
    name: string,
    store?: ReturnType<typeof createStore>
) {
    return (Component: ComponentType) => {
        return function WithJotaiAtom(props: Record<string, unknown>) {
            const [state, setState] = useAtom(atom, { store });
            return (
                <Component
                    {...{
                        ...props,
                        [name]: state,
                        ['set' + capitalizeFirstLetter(name)]: setState
                    }}
                />
            );
        };
    };
}
