import { ReactNode } from 'react';
import { ActionType } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { Direction } from '@he-novation/design-system/utils/getAbsolutePosition';
import { atom } from 'jotai';

export const absoluteMenuAtom = atom<{
    visible: boolean;
    content: ActionType[] | ReactNode | null;
    position: [number, number];
    direction?: Direction;
    triggerSize?: [number, number];
}>({
    visible: false,
    content: null,
    position: [0, 0]
});
