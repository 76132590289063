import { ContactsState } from '@he-novation/config/types/contact.types';
import update from 'immutability-helper';
import { map } from 'lodash/fp';
import cleanUser from '../../../helpers/cleanUser';
import {
    ADD_CONTACT,
    ADD_GROUP,
    ADD_TO_GROUP,
    DELETE_CONTACT,
    DELETE_FROM_GROUP,
    DELETE_GROUP,
    EDIT_CONTACT,
    EDIT_GROUP,
    FETCH,
    FETCH_CURRENT_USER_CONTACTS,
    FETCH_FOLDERS,
    FETCH_GROUP,
    FETCH_GROUPS,
    MODIFY_ACCESS,
    SET_CONTACTS
} from './contactsActions';

import { asyncActionError, asyncActionSuccess } from '$helpers/asyncAction';
import { mapUserAndProfileToUser } from '$redux/helpers';

export const contactsInitialState = {
    groups: {
        list: [],
        isLoaded: false
    },
    contacts: {
        list: [],
        isLoaded: false
    }
};

export const mapContact = (contact) => ({
    ...mapUserAndProfileToUser(contact),
    sharedFolders: {
        folders: [],
        isFoldersFetched: false
    }
});

const mapContacts = map(mapContact);

const addFolderToContact = (state, action) =>
    state.contacts.list.map((contact) => {
        if (contact.uuid === action.contactUuid && action.content) {
            contact.sharedFolders = { folders: [...action.content] };
        }
        contact.sharedFolders.isFoldersFetched = true;
        return contact;
    });

export default (
    state: ContactsState = contactsInitialState,
    action: { type: string; [key: string]: any }
) => {
    switch (action.type) {
        case asyncActionSuccess(FETCH_CURRENT_USER_CONTACTS): {
            const contacts = action.response.result.map((c) => cleanUser(c));
            return update(state, {
                contacts: {
                    list: { $set: contacts }
                }
            });
        }

        case asyncActionSuccess(MODIFY_ACCESS):
            return {
                ...state,
                message: action.response
            };

        case asyncActionError(MODIFY_ACCESS):
            return {
                ...state,
                message: 'An error occurred'
            };

        case asyncActionSuccess(FETCH_FOLDERS):
            return {
                ...state,
                contacts: {
                    list: addFolderToContact(state, action),
                    isLoaded: true
                }
            };

        case asyncActionError(FETCH_FOLDERS):
            return {
                ...state,
                error: 'An error occurred'
            };

        case asyncActionSuccess(FETCH):
            return {
                ...state,
                contacts: {
                    list: mapContacts(action.response),
                    isLoaded: true
                }
            };

        case asyncActionSuccess(FETCH_GROUPS):
            return {
                ...state,
                groups: {
                    list: action.groups || [],
                    isLoaded: true
                }
            };
        case asyncActionError(FETCH_GROUPS):
            return {
                ...state,
                message: 'An error occurred'
            };

        case FETCH_GROUP:
            return {
                ...state,
                activeGroup: {
                    list: [],
                    isLoaded: false
                }
            };

        case asyncActionSuccess(FETCH_GROUP):
            return {
                ...state,
                activeGroup: {
                    list: mapContacts(action.response),
                    uuid: action.uuid,
                    isLoaded: true
                }
            };

        case asyncActionError(FETCH):
            return {
                ...state,
                error: 'An error occured'
            };

        case asyncActionSuccess(ADD_CONTACT): {
            const contactIndex = state.contacts.list.findIndex(
                (c) => c.uuid === action.contact.uuid
            );
            if (contactIndex > -1) return state;
            return update(state, {
                contacts: {
                    list: {
                        $push: [
                            {
                                ...action.contact,
                                sharedFolders: {
                                    isFoldersFetched: false,
                                    folders: []
                                }
                            }
                        ]
                    }
                }
            });
        }

        case asyncActionSuccess(EDIT_CONTACT): {
            const contactIndex = state.contacts.list.findIndex((c) => c.uuid === action.uuid);
            if (contactIndex < 0) return state;
            return update(state, {
                contacts: {
                    list: {
                        [contactIndex]: {
                            $merge: action.contact
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(ADD_GROUP):
            return update(state, {
                groups: {
                    list: {
                        $push: [
                            {
                                name: action.name,
                                uuid: action.uuid,
                                count_contacts: 0,
                                isClientGroup: action.isClientGroup
                            }
                        ]
                    }
                }
            });

        case asyncActionSuccess(DELETE_CONTACT): {
            return {
                ...state,
                contacts: {
                    list: state.contacts.list.filter(({ uuid }) => uuid !== action.uuid),
                    isLoaded: true
                },
                activeGroup: state.activeGroup
                    ? {
                          list: state.activeGroup.list.filter(({ uuid }) => uuid !== action.uuid),
                          isLoaded: state.activeGroup.isLoaded
                      }
                    : state.activeGroup
            };
        }

        case asyncActionSuccess(ADD_TO_GROUP): {
            const group = state.groups.list.findIndex((g) => g.uuid === action.groupUuid);
            if (group < 0) return state;
            return update(state, {
                groups: {
                    list: {
                        [group]: {
                            count_contacts: {
                                $set:
                                    state.groups.list[group].count_contacts +
                                    action.contactUuids.length
                            }
                        }
                    }
                }
            });
        }

        case asyncActionSuccess(DELETE_FROM_GROUP): {
            if (!state.activeGroup || action.groupUuid !== state.activeGroup.uuid) return state;
            return update(state, {
                activeGroup: {
                    list: {
                        $set: state.activeGroup.list.filter(
                            (c) => !action.contactUuids.find((uuid) => c.uuid === uuid)
                        )
                    }
                }
            });
        }

        case asyncActionSuccess(DELETE_GROUP):
            return update(state, {
                groups: {
                    list: {
                        $splice: [[state.groups.list.findIndex((g) => g.uuid === action.uuid), 1]]
                    }
                }
            });

        case asyncActionSuccess(EDIT_GROUP):
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    list: state.contacts.list.map((c) => {
                        const updatedContact = action?.updatedContacts.find(
                            (uc) => uc.uuid === c.uuid
                        );
                        if (updatedContact) {
                            return { ...c, ...updatedContact };
                        }
                        return c;
                    })
                },
                groups: {
                    list: state.groups.list.map(({ uuid, name, ...rest }) => ({
                        uuid,
                        ...rest,
                        name: uuid === action.uuid ? action.name : name
                    })),
                    isLoaded: state.groups.isLoaded
                }
            };

        case SET_CONTACTS:
            return update(state, {
                contacts: {
                    list: { $set: action.contacts },
                    isLoaded: { $set: true }
                }
            });

        default:
            return state;
    }
};
