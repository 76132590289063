import { connect } from 'react-redux';
import { Toast } from '@he-novation/design-system/components/widgets/Toast/Toast';
import { branch, compose, renderNothing } from 'recompose';

import combineSelectors from '$helpers/combineSelectors';
import { dismissToast } from '$redux/ui/uiActions';
import { toastSelector } from '$redux/ui/uiSelectors';

export default compose(
    connect(combineSelectors(toastSelector), (dispatch) => ({
        onClose: () => dispatch(dismissToast())
    })),
    branch(({ content, button }) => !content && !button, renderNothing)
)(Toast);
