import styles from './TeamMemberPicker.module.css';
import React, { useRef } from 'react';
import { TeamMember, TeamWithMembers } from '@he-novation/config/types/team.types';
import {
    AvatarSize,
    AvatarUser
} from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { AbsoluteContentButton } from '@he-novation/design-system/components/buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { getDisplayName } from '@he-novation/utils/user';

import { useTranslate } from '$hooks/useTranslate';

type TeamMemberPickerProps = {
    team: TeamWithMembers;
    teamMembers: TeamMember[];
    pickMember: (member: TeamMember) => void;
};
export function TeamMemberPicker({ team, teamMembers, pickMember }: TeamMemberPickerProps) {
    const ref = useRef<{ setIsVisible: (isVisible: boolean) => void }>();
    const members = team.members.filter((m) => !teamMembers.find((tm) => tm.uuid === m.uuid));
    const { t } = useTranslate();
    return (
        <AbsoluteContentButton
            tagName={Button}
            type="button"
            tone={ButtonTone.Hoverable}
            direction={[DirectionX.LeftInner, DirectionY.Top]}
            contentClassName={styles.teamMemberPicker}
            icon={[{ name: 'at', stroke: 'white', fill: 'none' }]}
            controlRef={ref}
            content={
                <ul className={styles.list}>
                    {members.length ? (
                        members.map((member) => {
                            return (
                                <li key={member.uuid}>
                                    <button
                                        onClick={() => {
                                            pickMember(member);
                                            ref.current?.setIsVisible(false);
                                        }}
                                        type="button"
                                    >
                                        <AvatarUser size={AvatarSize.Small} {...member} />{' '}
                                        <p>{getDisplayName(member)}</p>
                                    </button>
                                </li>
                            );
                        })
                    ) : (
                        <li className={styles.none}>{t('project.No user available')}</li>
                    )}
                </ul>
            }
        />
    );
}
