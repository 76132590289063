import styles from './SearchField.module.scss';
import React, { ChangeEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormField } from '../FormField/FormField';
import { Icon } from '../../graphics/Icon/Icon';
import { Button, ButtonTone } from '../../buttons/Button/Button';
import { useTranslate } from '../../../hooks/useTranslate';

type SearchFieldProps = {
    value?: string | null;
    formId?: string;
    onInput?: (e: ChangeEvent<HTMLInputElement>) => void;
    onReset?: () => void;
    onFocus?: (isFocused: boolean) => void;
    isActive?: boolean;
    className?: string;
};

export function SearchField({
    className,
    formId,
    isActive,
    onInput,
    onReset,
    onFocus,
    value: _value
}: SearchFieldProps) {
    const [value, setValue] = useState(_value || '');

    useEffect(() => {
        setValue(_value || '');
    }, [_value]);

    const id = formId ? `${formId}-search-field` : 'search-field';
    const { t } = useTranslate();

    return (
        <div className={styles.searchField}>
            <Icon icon="magnifier" stroke="white" />
            <FormField
                id={id}
                name="search"
                formId={formId}
                type="text"
                placeholder={t('Search')}
                value={value}
                className={cn(className, 'search', isActive ? 'is-active' : undefined)}
                onInput={onInput}
                onChange={(e) => setValue(e.currentTarget.value)}
                onFocus={(e) => {
                    e.preventDefault();
                    onFocus?.(true);
                }}
                onBlur={(e) => {
                    e.preventDefault();
                    onFocus?.(false);
                }}
            />
            {value && onReset && (
                <Button
                    tone={ButtonTone.Alert}
                    type={'button'}
                    className={styles.reset}
                    icon="plus"
                    onClick={() => {
                        setValue('');
                        onReset();
                    }}
                />
            )}
        </div>
    );
}
