import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { useEffect, useState } from 'react';
import { UserNotificationSettingsSchema } from '@he-novation/config/types/payloads/user.payload';
import {
    asyncProjectUserNotificationSettingsFetch,
    asyncUserNotificationSettingsPut
} from '@he-novation/front-shared/async/user.async';

import Accordion from '$components/Accordion/Accordion';
import { FormNotifications } from '$components/form/FormNotifications';
import { useTranslate } from '$hooks/useTranslate';

export function SidePanelProjectNotifications({
    payload
}: {
    payload: { clientName: string; projectUuid: string };
}) {
    const { t } = useTranslate();
    const [notificationSettings, setNotificationSettings] =
        useState<UserNotificationSettingsSchema | null>(null);

    const [creating, setCreating] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        asyncProjectUserNotificationSettingsFetch(payload.clientName, payload.projectUuid).then(
            (settings) => {
                if (settings) {
                    setNotificationSettings(settings);
                }
                setLoading(false);
            }
        );
    }, [payload.clientName, payload.projectUuid]);

    return (
        <Accordion isOpen title={t('settings.Notifications')} className={formStyles.dark} noToggle>
            <FormNotifications
                notificationSettings={notificationSettings}
                disabled={creating}
                loading={loading}
                onChange={(s) => {
                    if (!notificationSettings) {
                        setCreating(true);
                        setNotificationSettings(s);
                        asyncUserNotificationSettingsPut(
                            payload.clientName,
                            payload.projectUuid,
                            s
                        ).then(() => {
                            setCreating(false);
                        });
                    } else {
                        setNotificationSettings(s);
                        asyncUserNotificationSettingsPut(
                            payload.clientName,
                            payload.projectUuid,
                            s
                        );
                    }
                }}
            />
        </Accordion>
    );
}
