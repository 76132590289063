import { ItemsState } from '@he-novation/config/types/item.types';
import { pick, pipe } from 'lodash/fp';
import { stateSelector as contentSelector } from '../../content/contentSelectors';
import { ITEMS } from '../../content/items/itemsActions';

import { ReduxState } from '$redux/store';

export const stateSelector: (state: ReduxState) => ItemsState = pipe(
    contentSelector,
    (state) => state[ITEMS]
);

export const itemsSelector = pipe(stateSelector, ({ list }) => list);
export const kitsSelector = pipe(stateSelector, pick('kits'));

export const kitsAsArraySelector = pipe(stateSelector, ({ kits }) => ({
    kits: kits.list
}));

export const kitSelectorFromUuid = (_uuid: string) =>
    pipe(
        stateSelector,
        (state) => state.kits,
        ({ isLoaded, list }) => ({ isLoaded, ...(list.find(({ uuid }) => uuid === _uuid) || {}) }),
        (kit) => ({ kit })
    );

export const itemsWithProjectItemsSelector = pipe(
    stateSelector,
    pick('itemsWithProjectItemsList'),
    (v) => ({ itemsWithProjectItems: v.itemsWithProjectItemsList })
);
