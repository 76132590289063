import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signUpLink } from '@he-novation/config/paths/herawLoginPaths';
import { Asset } from '@he-novation/config/types/asset.types';
import { FileEnum } from '@he-novation/config/types/file.types';
import { Comment, Note, NoteSorter, NoteSorting } from '@he-novation/config/types/note.types';
import { NoteCreateBody } from '@he-novation/config/types/payloads/note.payload';
import { TaskStatus } from '@he-novation/config/types/task.types';
import { Team } from '@he-novation/config/types/team.types';
import { ContentUser } from '@he-novation/config/types/user.types';
import {
    asyncCommentCreate,
    asyncCommentDelete,
    asyncCommentEdit,
    asyncNoteCreate,
    asyncUploadAttachments,
    deleteNote
} from '@he-novation/front-shared/async/note.async';
import { timeCodeToSeconds } from '@he-novation/lib-timecodes';
import { bytesToSize } from '@he-novation/utils/bytes';
import update, { Spec } from 'immutability-helper';
import { useAtom, useSetAtom } from 'jotai';
import { useSocketIO } from './useSocketIO';

import { creatingNoteAtom, creatingTaskAtom, notesAtom } from '$atoms/note-atoms';
import { FILE_PANEL } from '$constants/constants.sidePanels';
import isJsonString from '$helpers/isJsonString';
import { useFeedbackModal } from '$hooks/useFeedbackModal';
import { usePanel } from '$hooks/usePanel';
import { Translator, useTranslate } from '$hooks/useTranslate';
import { fileTypeSelector, fileUuidSelector } from '$redux/content/file/fileSelectors';
import sqlDatesToJsDates from '$redux/content/projects/maps/sqlDatesToJsDates';
import { updateUserPreferences } from '$redux/user/userActions';
import { preferencesSelector } from '$redux/user/userSelectors';

function sortNotes(notes: Note[], sorting: NoteSorting) {
    switch (sorting) {
        case 'created':
            return notes.sort((a, b) => a.created.getTime() - b.created.getTime());
        case 'timecode':
            return notes.sort((a, b) => {
                if (a.metadata?.tcIn !== b.metadata?.tcIn) {
                    const tcInA = a.metadata.tcIn ? timeCodeToSeconds(a.metadata.tcIn, 25) : 0;
                    const tcInB = b.metadata.tcIn ? timeCodeToSeconds(b.metadata.tcIn, 25) : 0;
                    return tcInA - tcInB;
                }
                return a.created.getTime() - b.created.getTime();
            });
        case 'page':
            return notes.sort((a, b) => {
                if (a.metadata?.page !== b.metadata?.page) {
                    return (a.metadata?.page || 0) - (b.metadata?.page || 0);
                }
                return a.created.getTime() - b.created.getTime();
            });
        default:
            return notes;
    }
}

export function useNotes() {
    const [notes, setNotes] = useAtom(notesAtom);
    const [sorting, setSorting] = useState<NoteSorting>('created');
    const { openPanel } = usePanel();
    const setCreatingNote = useSetAtom(creatingNoteAtom);
    const setCreatingTask = useSetAtom(creatingTaskAtom);
    const [taskStatusFilters, setTaskStatusFilters] = useState<TaskStatus[]>([]);
    const [teamFilters, setTeamFilters] = useState<string[]>([]);
    const { fileType } = useSelector(fileTypeSelector);
    const { t } = useTranslate();

    const sortedNotes = useMemo(() => {
        let _notes = [...notes];
        if (taskStatusFilters.length) {
            _notes = _notes.filter(
                (note) => note.task && taskStatusFilters.includes(note.task.status)
            );
        }

        if (teamFilters.length) {
            _notes = _notes.filter(
                (note) =>
                    (note.team && teamFilters.includes(note.team.uuid)) ||
                    (note.task && note.task.team && teamFilters.includes(note.task.team.uuid))
            );
        }

        return sortNotes(_notes, sorting);
    }, [notes, sorting, taskStatusFilters, teamFilters]);

    const teams = useMemo(() => {
        const t: Team[] = [];
        notes.forEach((note) => {
            if (note.task && note.task.team && !t.find((t) => t.uuid === note.task!.team.uuid)) {
                t.push(note.task.team);
            }
            if (note.team && !t.find((t) => t.uuid === note.team!.uuid)) {
                t.push(note.team);
            }
        });
        return t;
    }, [notes]);

    const sorters: NoteSorter[] = [
        {
            value: 'created',
            label: t('common.Date')
        }
    ];

    if ([FileEnum.Video, FileEnum.Audio].includes(fileType)) {
        sorters.push({
            value: 'timecode',
            label: t('player.Timecode')
        });
    }
    if ([FileEnum.PDF].includes(fileType)) {
        sorters.push({
            value: 'page',
            label: t('player.Page')
        });
    }

    return {
        notes: sortedNotes,
        setNotes,
        sorting,
        setSorting,
        sorters,
        taskStatusFilters,
        setTaskStatusFilters,
        teams,
        teamFilters,
        setTeamFilters,
        openNoteCreation(task?: boolean) {
            setCreatingNote(true);
            if (task) {
                setCreatingTask(true);
            }
            openPanel(FILE_PANEL);
        }
    };
}

export function useNoteSockets() {
    const { subscribe, unsubscribe } = useSocketIO();
    const setNotes = useSetAtom(notesAtom);

    return {
        unsubscribe,
        subscribe: (roomUuids: string[]) => {
            for (const uuid of roomUuids) {
                subscribe?.({
                    socket: 'note',
                    room: uuid,
                    actions: {
                        sioNoteCreate: ({ note, file, user }) => {
                            const _note = {
                                uuid: note.uuid,
                                file: file,
                                type: note.type,
                                assets: note.assets,
                                detailAsset: (
                                    (note.assets &&
                                        note.assets.find(({ quality }) => quality === 'detail')) ||
                                    {}
                                ).url,
                                metadata: note.metadata,
                                created: new Date(note.created),
                                team: note.team,
                                task: note.task ? sqlDatesToJsDates(note.task) : null,
                                user: user,
                                updated: new Date(),
                                comments: note.comments.map((c) => ({
                                    ...c,
                                    user: user,
                                    created: new Date(c.created)
                                }))
                            };

                            setNotes((notes) => {
                                const noteIndex = notes.findIndex(
                                    ({ uuid, comments }) =>
                                        uuid === _note.uuid ||
                                        (uuid === 'new' &&
                                            comments[0].content === _note.comments[0].content)
                                );
                                return update(
                                    notes,
                                    noteIndex > -1
                                        ? { [noteIndex]: { $merge: _note } }
                                        : { $unshift: [_note] }
                                );
                            });
                        },
                        sioNoteDelete: ({ noteUuid }: { noteUuid: string }) => {
                            setNotes((notes) => {
                                return update(notes, {
                                    $apply: (list) => list.filter(({ uuid }) => uuid !== noteUuid)
                                });
                            });
                        },
                        sioNoteUpdate: ({
                            content,
                            uuid,
                            task
                        }: {
                            content: string;
                            uuid: string;
                            task?: { status: TaskStatus };
                        }) => {
                            setNotes((notes) => {
                                const noteIndex = notes.findIndex((n) => n.uuid === uuid);
                                const _update: Spec<Note[], never> = {
                                    [noteIndex]: {
                                        comments: {
                                            [notes[noteIndex].comments.length - 1]: {
                                                content: { $set: content }
                                            }
                                        },
                                        task:
                                            notes[noteIndex].task && task
                                                ? {
                                                      description: { $set: content },
                                                      status: { $set: task.status }
                                                  }
                                                : undefined
                                    }
                                };
                                return update(notes, _update);
                            });
                        },
                        sioCommentCreate: ({
                            comment,
                            user
                        }: {
                            comment: Omit<Comment, 'file' | 'user'>;
                            user: ContentUser;
                        }) => {
                            setNotes((notes) => {
                                const index = notes.findIndex(
                                    ({ uuid }) => comment?.note?.uuid === uuid
                                );
                                if (!notes[index]) return notes;
                                const commentIndex = notes[index].comments.findIndex(
                                    (c) => c.uuid === comment.uuid
                                );
                                if (commentIndex > -1) return notes;
                                return update(notes, {
                                    [index]: {
                                        comments: {
                                            $unshift: [
                                                {
                                                    user,
                                                    ...comment,
                                                    created: new Date(comment.created)
                                                }
                                            ]
                                        }
                                    }
                                });
                            });
                        },
                        sioCommentDelete: ({
                            noteUuid,
                            uuid
                        }: {
                            noteUuid: string;
                            uuid: string;
                        }) => {
                            setNotes((notes) => {
                                const noteIndex = notes.findIndex((n) => n.uuid === noteUuid);
                                if (noteIndex === -1) {
                                    return notes;
                                }

                                if (
                                    notes[noteIndex].comments.length === 1 &&
                                    notes[noteIndex].comments.find((c) => c.uuid === uuid)
                                ) {
                                    return update(notes, { $splice: [[noteIndex, 1]] });
                                }
                                return update(notes, {
                                    [noteIndex]: {
                                        comments: {
                                            $set: notes[noteIndex].comments.filter(
                                                (c) => c.uuid !== uuid
                                            )
                                        }
                                    }
                                });
                            });
                        },
                        sioNoteAttachment: ({
                            asset,
                            comment,
                            note
                        }: {
                            asset: Asset;
                            comment: { uuid: string };
                            note: { uuid: string };
                        }) => {
                            setNotes((notes) => {
                                const noteIndex = notes.findIndex(({ uuid }) => uuid === note.uuid);
                                if (noteIndex === -1) return notes;
                                const commentIndex = notes[noteIndex].comments.findIndex(
                                    ({ uuid }) => uuid === comment.uuid
                                );
                                return update(notes, {
                                    [noteIndex]: {
                                        comments: {
                                            [commentIndex]: {
                                                assets: {
                                                    $push: [asset]
                                                }
                                            }
                                        }
                                    }
                                });
                            });
                        }
                    }
                });
            }
        }
    };
}

const _attachmentUploadErrorHandlerFactory =
    (
        openFeedbackModal: ReturnType<typeof useFeedbackModal>['openFeedbackModal'],
        t: Translator,
        locale: string
    ) =>
    (e) => {
        let message;
        if (e.code === 413) {
            message = (
                <>
                    {t('player.The attachment exceed the maximum size of {{size}}', {
                        size: bytesToSize(e.data.limit, locale)
                    })}
                    {e.data.status === 'ANONYMOUS' && (
                        <>
                            <br />
                            <a href={signUpLink('fr', 'user')}>
                                {t('misc.Create an account to increase this limit')}
                            </a>
                        </>
                    )}
                </>
            );
        }

        if (e.code === 429)
            message = t('misc.You have reached the file upload limit, please try again later');
        if (message)
            openFeedbackModal(message, undefined, {
                title: t('common.Sorry, something went wrong'),
                isError: true
            });
    };

export function useCreateNotesAndComments() {
    const dispatch = useDispatch();

    const { openFeedbackModal } = useFeedbackModal();

    const setNotes = useSetAtom(notesAtom);

    const { fileUuid: currentFileUuid } = useSelector(fileUuidSelector);
    const {
        preferences: { tags: userTags }
    } = useSelector(preferencesSelector);
    const { t, locale } = useTranslate();

    const noteCreate = useCallback(
        async (
            {
                assignees,
                attachments,
                content,
                draft,
                estimatedEndDate,
                fileUuid,
                fileVersion,
                isTask,
                notify,
                teamUuid,
                tags,
                type,
                metadata,
                imageData,
                postedAs,
                recaptcha,
                castFileUuid
            }: NoteCreateBody & { attachments: File[] },
            isCastFile?: boolean,
            password?: string
        ) => {
            const note = await asyncNoteCreate(
                {
                    assignees,
                    content,
                    draft,
                    estimatedEndDate,
                    fileUuid,
                    fileVersion,
                    castFileUuid,
                    isTask,
                    notify,
                    teamUuid,
                    tags,
                    type,
                    metadata,
                    imageData,
                    postedAs,
                    recaptcha
                },
                isCastFile,
                password
            );
            if (attachments.length) {
                const lastComment = note.comments[note.comments.length - 1];
                await asyncUploadAttachments({
                    commentUuid: lastComment.uuid,
                    noteUuid: note.uuid,
                    attachments,
                    castFileUuid
                }).catch(_attachmentUploadErrorHandlerFactory(openFeedbackModal, t, locale));
            }

            if (tags && !password) {
                dispatch(
                    updateUserPreferences({
                        tags: [...new Set([...(userTags || []), ...tags])]
                    })
                );
            }

            if (currentFileUuid !== fileUuid) {
                return;
            }

            setNotes((notes) => {
                const _note = {
                    ...note,
                    task: note.task && sqlDatesToJsDates(note.task),
                    file_version: fileVersion,
                    created: new Date(note.created),
                    metadata: isJsonString(note.metadata)
                        ? JSON.parse(note.metadata)
                        : note.metadata,
                    user: note.user,
                    comments: note.comments.map((c) => ({
                        ...c,
                        created: new Date(note.created),
                        user: note.user,
                        note_uuid: note.uuid,
                        user_profile: {
                            firstname: note.user.firstname,
                            lastname: note.user.lastname
                        }
                    }))
                };
                const noteIndex = notes.findIndex(
                    (n) =>
                        n.uuid === note.uuid ||
                        (n.uuid === 'new' && n.comments[0].content === note.comments[0].content) ||
                        (n.type === 'global' && note.type === 'global')
                );
                if (noteIndex > -1)
                    return update(notes, {
                        [noteIndex]: {
                            $merge: _note
                        }
                    });
                return update(notes, {
                    $unshift: [_note]
                });
            });
        },
        [currentFileUuid]
    );
    return {
        noteCreate,
        noteDelete: async (uuid: string) => {
            setNotes((notes) => {
                return notes.filter(({ uuid: noteUuid }) => noteUuid !== uuid);
            });
            await deleteNote(uuid);
        },
        commentCreate: async (
            noteUuid: string,
            {
                attachments,
                content,
                isDraft,
                castFileUuid,
                shouldNotify,
                keywords,
                postedAs
            }: {
                attachments: File[];
                content: string;
                isDraft?: boolean;
                castFileUuid?: string;
                shouldNotify?: boolean;
                keywords?: string[];
                postedAs?: string;
            },
            password?: string
        ) => {
            const comment = await asyncCommentCreate(
                noteUuid,
                {
                    content,
                    draft: !!isDraft,
                    castFileUuid,
                    notify: !!shouldNotify,
                    tags: keywords,
                    postedAs
                },
                password
            );
            if (attachments.length) {
                await asyncUploadAttachments({
                    commentUuid: comment.uuid,
                    noteUuid,
                    attachments,
                    castFileUuid
                }).catch(_attachmentUploadErrorHandlerFactory(openFeedbackModal, t, locale));
            }

            setNotes((notes) => {
                const noteIndex = notes.findIndex(({ uuid }) => uuid === noteUuid);
                if (noteIndex === -1) return notes;
                const commentIndex = notes[noteIndex].comments.findIndex(
                    (c) => c.uuid === comment.uuid
                );
                if (commentIndex > -1) return notes;
                return update(notes, {
                    [noteIndex]: {
                        comments: {
                            $unshift: [
                                {
                                    ...comment,
                                    created: new Date(comment.created)
                                }
                            ]
                        }
                    }
                });
            });
        },

        commentEdit: async (
            noteUuid: string,
            commentUuid: string,
            {
                attachments,
                content,
                isDraft,
                tags
            }: {
                attachments?: File[];
                content: string;
                isDraft?: boolean;
                tags: string[];
            }
        ) => {
            const comment = await asyncCommentEdit(noteUuid, commentUuid, {
                content,
                draft: isDraft,
                tags
            });

            if (attachments && attachments.length > 0) {
                await asyncUploadAttachments({
                    commentUuid: comment.uuid,
                    noteUuid: noteUuid,
                    attachments: attachments
                }).catch(_attachmentUploadErrorHandlerFactory(openFeedbackModal, t, locale));
            }
            setNotes((notes) => {
                const noteIndex = notes.findIndex((n) => n.uuid === noteUuid);
                if (noteIndex === -1) return notes;
                const commentIndex = notes[noteIndex].comments.findIndex(
                    (c) => c.uuid === commentUuid
                );
                if (commentIndex === -1) return notes;
                return update(notes, {
                    [noteIndex]: {
                        comments: {
                            [commentIndex]: {
                                $merge: {
                                    ...comment,
                                    created: new Date(comment.created),
                                    updated: new Date()
                                }
                            }
                        }
                    }
                });
            });
        },

        commentDelete: async (noteUuid: string, commentUuid: string) => {
            setNotes((notes) => {
                const noteIndex = notes.findIndex((n) => n.uuid === noteUuid);
                if (noteIndex === -1) return notes;

                if (
                    notes[noteIndex].comments.length === 1 &&
                    notes[noteIndex].comments.find((c) => c.uuid === commentUuid)
                ) {
                    return update(notes, {
                        $splice: [[noteIndex, 1]]
                    });
                }

                return update(notes, {
                    [noteIndex]: {
                        comments: {
                            $set: notes[noteIndex].comments.filter((c) => c.uuid !== commentUuid)
                        }
                    }
                });
            });
            await asyncCommentDelete(noteUuid, commentUuid);
        }
    };
}
