import { useDispatch, useSelector } from 'react-redux';
import {
    folderLink,
    playerLink,
    projectFoldersLink
} from '@he-novation/config/paths/herawFrontUris';
import { Contact } from '@he-novation/config/types/contact.types';
import { AnyLogPayload } from '@he-novation/config/types/payloads/logs/log.payload';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { bytesToSize } from '@he-novation/utils/bytes';

import { asyncActionSuccess } from '$helpers/asyncAction';
import { useFeedbackModal } from '$hooks/useFeedbackModal';
import { useSocketIO } from '$hooks/useSocketIO';
import { Translator, useTranslate } from '$hooks/useTranslate';
import { ADD_CONTACT } from '$redux/content/contacts/contactsActions';
import { set } from '$redux/route/routeActions';
import { ACTIVITY_NEW_INCREMENT, dismissToast, openToast } from '$redux/ui/uiActions';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

type LivenotifyEvent = {
    livenotify?: { content: string; icon?: string };
    log: AnyLogPayload;
    request_user_uuid: string;
};
const livenotify = async (
    setRoute: (route: string, silent?: boolean, replace?: boolean) => void,
    data: LivenotifyEvent,
    feedback: (message: string, timeout?: number) => void,
    t: Translator
) => {
    if (data.livenotify) {
        if (Notification.permission === 'default') await Notification.requestPermission();
        if (Notification.permission === 'denied')
            return feedback(
                t(
                    'misc.Desktop notifications have not been authorized, enable them by clicking on the padlock in the address bar'
                ),
                30000
            );

        const div = document.createElement('div');
        div.innerHTML = data.livenotify.content;

        const notificationNew = new Notification('HERAW', {
            icon: data.livenotify.icon,
            body: div.innerText
        });

        notificationNew.onclick = (e) => {
            e.preventDefault();

            notificationNew.close();

            if ('file' in data.log && data.log.file) {
                setRoute(playerLink(data.log.file.uuid, data.log.file.version));
                window.focus();
                return;
            }
            if ('folder' in data.log && data.log.folder) {
                setRoute(
                    data.log.project
                        ? projectFoldersLink(data.log.project.uuid, data.log.folder.uuid)
                        : folderLink(data.log.folder.uuid)
                );
                window.focus();
            }
        };
    }
};

export function useNotifications() {
    const dispatch = useDispatch();
    const { t, locale } = useTranslate();

    const { openFeedbackModal } = useFeedbackModal();

    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    useSocketIO({
        room: currentUserUuid,
        socket: 'activity',
        actions: {
            sioActivityAdd: (data: LivenotifyEvent) => {
                if (currentUserUuid)
                    livenotify(
                        (route: string, silent?: boolean, replace?: boolean) =>
                            dispatch(set(route, silent, replace)),
                        data,
                        (msg: string, timeout?: number) => openFeedbackModal(msg, timeout),
                        t
                    );

                if (data.request_user_uuid === currentUserUuid) {
                    return;
                }

                dispatch({
                    type: ACTIVITY_NEW_INCREMENT
                });
            }
        }
    });
    useSocketIO({
        room: currentUserUuid,
        socket: 'user',
        actions: {
            sioContactCreated({ contact }: { contact: Contact }) {
                dispatch({
                    type: asyncActionSuccess(ADD_CONTACT),
                    contact
                });
            },
            sioArchiveReady: (data: { name: string; size: number; downloadUrl: string }) =>
                dispatch(
                    openToast({
                        title: t('folder.Archive ready'),
                        autoCloseAfterMs: 0,
                        content: `${data.name} (${bytesToSize(data.size, locale)})`,
                        buttons: [
                            {
                                children: t('common.Download'),
                                tone: ButtonTone.Primary,
                                onClick: () => {
                                    window.open(data.downloadUrl, '_blank');
                                    dispatch(dismissToast());
                                }
                            }
                        ]
                    })
                )
        }
    });
}
