import { timeCodeToSeconds } from '@he-novation/lib-timecodes';
import { useSetAtom } from 'jotai';
import { videoAtom } from '../atoms/file-atoms/video-atom';

export function useVideoControls() {
    const setVideoState = useSetAtom(videoAtom);
    return {
        play: () => setVideoState((videoState) => ({ ...videoState, isPlaying: true })),
        pause: () => setVideoState((videoState) => ({ ...videoState, isPlaying: false })),
        togglePlay: () =>
            setVideoState((videoState) => ({ ...videoState, isPlaying: !videoState.isPlaying })),
        setCurrentTime: (seconds: number) =>
            setVideoState((videoState) => ({ ...videoState, targetTime: seconds })),
        setTimecode: (timecode: string) => {
            setVideoState((videoState) => {
                const targetTime =
                    timeCodeToSeconds(timecode, videoState.frameRate) - videoState.secondsOffset;
                return { ...videoState, targetTime };
            });
        },
        toggleMute: (isMuted?: boolean) => {
            setVideoState((videoState) => {
                isMuted = isMuted ?? !videoState.isMuted;
                localStorage.setItem('muted', isMuted ? 'true' : 'false');
                return {
                    ...videoState,
                    isMuted
                };
            });
        },
        setIsLooping: (isLooping: boolean) =>
            setVideoState((videoState) => ({ ...videoState, isLooping })),
        setVolume: (volume: number) => {
            setVideoState((videoState) => {
                localStorage.setItem('volume', volume.toString());
                if (volume > 0 && videoState.isMuted) {
                    videoState.isMuted = false;
                    localStorage.setItem('muted', 'false');
                }
                return { ...videoState, volume };
            });
        },
        setPlaybackRate: (playbackRate: number) =>
            setVideoState((videoState) => ({
                ...videoState,
                isPlaying: true,
                playbackRate: Math.max(-2, Math.min(playbackRate, 3))
            }))
    };
}

export type VideoControls = ReturnType<typeof useVideoControls>;
