export const offset = (
    el: HTMLElement,
    getBoundingClientRect?: boolean,
    parent?: HTMLElement | null
) => {
    if (getBoundingClientRect) {
        const rect = el.getBoundingClientRect();
        const parentRect = parent?.getBoundingClientRect();

        return {
            top: rect.top + document.body.scrollTop - (parentRect ? parentRect.top : 0),
            left: rect.left + document.body.scrollLeft - (parentRect ? parentRect.left : 0)
        };
    }
    // getBoundingClientRect : weird bugs in scrollable elements
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        x += el.offsetLeft - el.scrollLeft;
        y += el.offsetTop - el.scrollTop;
        el = el.offsetParent as HTMLElement;
    }
    return { top: y, left: x };
};

export const offsetTop = (
    el: HTMLElement,
    getBoundingClientRect?: boolean,
    parent?: HTMLElement | null
) => offset(el, getBoundingClientRect, parent).top;
export const offsetLeft = (
    el: HTMLElement,
    getBoundingClientRect?: boolean,
    parent?: HTMLElement | null
) => offset(el, getBoundingClientRect, parent).left;
