import styles from './SidePanel.module.css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import sidePanels from '$components/SidePanel/sidePanels';
import { usePanel } from '$hooks/usePanel';
import { routeSelector } from '$redux/route/routeSelectors';
export const SIDE_PANEL_ANIMATION_DURATION = 300;

export function SidePanel() {
    const { panel, payload, closePanel } = usePanel();
    const { configRoute } = useSelector(routeSelector);
    const Component = panel ? sidePanels[panel].Component : null;

    useEffect(() => {
        closePanel();
    }, [configRoute]);

    return (
        <div id="panel" className={styles.panel}>
            {Component ? <Component payload={payload} closePanel={closePanel} /> : null}
        </div>
    );
}
