import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileFull } from '@he-novation/config/types/file.types';
import { apiFolderContentFileToFrontFolderContentFile } from '@he-novation/front-shared/mappers/file.mappers';
import { FrontFolderContentFile } from '@he-novation/front-shared/types/file.front-types';
import update from 'immutability-helper';
import { useSetAtom } from 'jotai/index';

import { playlistAtom } from '$atoms/file-atoms/playlist-atom';
import combineSelectors from '$helpers/combineSelectors';
import { usePlaylist } from '$hooks/usePlaylist';
import { useSocketIO } from '$hooks/useSocketIO';
import { PubSubEvent, useSubscribe } from '$hooks/useSubscribe';
import { setPage } from '$redux/content/file/fileActions';
import {
    activeAssetSelector,
    fileUuidSelector,
    folderUuidSelector,
    pageAndPageNumberSelector
} from '$redux/content/file/fileSelectors';

type PlaylistTrackerProps = {
    openItemInPlayer: (file: FrontFolderContentFile) => void;
};

export default function PlaylistTracker({ openItemInPlayer }: PlaylistTrackerProps) {
    const dispatch = useDispatch();

    const { fileUuid, page, folderUuid } = useSelector(
        combineSelectors(fileUuidSelector, pageAndPageNumberSelector, folderUuidSelector)
    );

    const activeAsset = useSelector(activeAssetSelector);

    const { isPlaylistPlaying, playlistContent, setPlaylistCurrentFileUuid, setPlaylistIsPlaying } =
        usePlaylist();

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        setActiveIndex(playlistContent.findIndex(({ uuid }) => uuid === fileUuid));
        setPlaylistCurrentFileUuid(fileUuid);
    }, [fileUuid, playlistContent]);

    const playNext = useCallback(
        (index: number) => {
            const currentFile = playlistContent[index];
            const pages = activeAsset?.metadata?.pages || [];

            if (index < playlistContent.length - 1) {
                if (currentFile.mimeType === 'application/pdf' && page < pages.length - 1) {
                    dispatch(setPage(page + 1));
                } else {
                    const target = playlistContent[index + 1];
                    if (target.isPlayable) {
                        openItemInPlayer(target);
                    } else {
                        playNext(index + 1);
                    }
                }
            } else if (currentFile.mimeType === 'application/pdf' && page < pages.length - 1) {
                dispatch(setPage(page + 1));
            } else {
                setPlaylistIsPlaying(false);
            }
        },
        [playlistContent, page]
    );

    useSubscribe(
        PubSubEvent.PlayNextInPlaylist,
        () => {
            if (isPlaylistPlaying) {
                playNext(activeIndex);
            }
        },
        [isPlaylistPlaying, activeIndex]
    );

    const folderSockets = useSocketIO();
    const setPlaylistAtom = useSetAtom(playlistAtom);

    useEffect(() => {
        if (folderUuid) {
            folderSockets.subscribe!({
                socket: 'folder',
                room: folderUuid,
                actions: {
                    sioFileSet: (payload: { file: FileFull }) => {
                        setPlaylistAtom((playlist) => {
                            const index = playlist.folderFiles
                                ? playlist.folderFiles.findIndex(
                                      (file) => file.uuid === payload.file.uuid
                                  )
                                : -1;
                            if (index === -1) return playlist;
                            return update(playlist, {
                                folderFiles: {
                                    [index]: {
                                        $set: apiFolderContentFileToFrontFolderContentFile(
                                            payload.file
                                        )
                                    }
                                }
                            });
                        });
                    }
                }
            });
        }

        return () => {
            folderSockets.unsubscribe();
        };
    }, [folderUuid]);

    return null;
}
