import styles from './SidePanelSubtitlesRow.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { useEffect, useState } from 'react';
import { MappedSubtitleEntry } from '@he-novation/config/types/subtitle.types';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';

import {
    FormSubtitle,
    FormSubtitleOutput
} from '$components/SidePanel/SidePanelFile/SidePanelSubtitles/components/FormSubtitle';
import { useTranslate } from '$hooks/useTranslate';

export type SubtitleRow = {
    subtitleEntries: MappedSubtitleEntry[];
    editing: string[];
    subtitleEntriesInPlayer: string[];
    onClickDelete: (entryUuid: string) => void;
    onClickEdit: (entryUuid: string) => void;
    saveChanges: (data: FormSubtitleOutput) => void;
    goToTime: (time: number) => void;
    frameRate: number;
};

type SidePanelSubtitlesRowProps = {
    index: number;
    style: unknown;
    data: SubtitleRow;
};

export function SidePanelSubtitlesRow({
    index: i,
    style,
    data: {
        subtitleEntries,
        editing,
        subtitleEntriesInPlayer,
        onClickDelete,
        onClickEdit,
        saveChanges,
        goToTime,
        frameRate
    }
}: SidePanelSubtitlesRowProps) {
    const [isBeingEdited, setIsBeingEdited] = useState(editing.includes(subtitleEntries[i].uuid));

    useEffect(() => {
        setIsBeingEdited(editing.includes(subtitleEntries[i].uuid));
    }, [editing]);
    const { t } = useTranslate();

    return (
        <FormSubtitle
            subtitleEntry={subtitleEntries[i]}
            style={style}
            formId={'edit-subtitle-form-' + subtitleEntries[i].uuid}
            visualIdentifier={
                <div
                    className={styles.index}
                    onClick={() => {
                        //ceil to next frame to be sure to display subtitle
                        goToTime(subtitleEntries[i].timeIn + 1 / frameRate);
                    }}
                >
                    #{i + 1}
                </div>
            }
            multiActionsButton={
                <MultiActionButton
                    className={styles.actions}
                    icon={[{ name: 'three_dots', fill: 'white', stroke: 'white' }]}
                    tone={ButtonTone.Hoverable}
                    direction={[DirectionX.RightInner, DirectionY.Top]}
                    onClick={(e) => e.preventDefault()}
                    actions={[
                        {
                            icon: 'trash',
                            children: t('common.Delete'),
                            onClick: (e) => {
                                e.preventDefault();
                                onClickDelete(subtitleEntries[i].uuid);
                            }
                        },
                        {
                            icon: 'pencil',
                            children: t('common.Edit'),
                            onClick: (e) => {
                                e.preventDefault();
                                onClickEdit(subtitleEntries[i].uuid);
                            }
                        }
                    ]}
                />
            }
            disabled={!isBeingEdited}
            onSubmit={(_, data) => saveChanges(data)}
            onCancel={() => onClickEdit(subtitleEntries[i].uuid)}
            className={cn(
                styles.subtitleEntry,
                formStyles.dark,
                subtitleEntriesInPlayer.includes(subtitleEntries[i].uuid) && 'is-displayed'
            )}
        />
    );
}
