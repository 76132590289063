import React, { SyntheticEvent } from 'react';
import cn from 'classnames';
import { Tab, Tabs, TabsMode } from '../../widgets/Tabs/Tabs';
import { Theme } from '../../../enums';

export enum CustomControlType {
    Tab = 'tab'
}

export type CustomControl<T extends CustomControlType = any> = {
    type: T;
    value?: Tab['id'];
    values: Omit<Tab, 'onClick'>[];
    onClick: T extends CustomControlType.Tab
        ? (e: SyntheticEvent, value: Tab['id']) => void
        : undefined;
};

type EditorCustomControlsProps = {
    controls: CustomControl[];
};

export const EditorCustomControls: React.FC<EditorCustomControlsProps> = ({ controls }) => {
    return (
        <>
            {controls.map((c, i) => {
                switch (c.type) {
                    case CustomControlType.Tab:
                        return (
                            <Tabs
                                key={i}
                                className={cn('editor-custom-control', 'cc-tab')}
                                activeTab={c.value}
                                tabs={c.values.map(({ id, label, icon }) => ({
                                    id: id,
                                    label,
                                    icon,
                                    onClick: (e) => c.onClick?.(e, id)
                                }))}
                                mode={TabsMode.ButtonsOnly}
                            />
                        );
                }
            })}
        </>
    );
};
