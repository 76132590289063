import styles from './SidePanelFolderSettingsCheckbox.module.css';
import React, { ReactNode, useEffect, useState } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Theme } from '@he-novation/design-system/enums';
import cn from 'classnames';

type SidePanelFolderSettingsCheckboxProps = {
    label: string;
    children?: ReactNode | ReactNode[];
    checked?: boolean;
    name: string;
    id: string;
    formId: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
};
export function SidePanelFolderSettingsCheckbox({
    label,
    children,
    checked: _checked,
    name,
    id,
    formId,
    onChange,
    disabled
}: SidePanelFolderSettingsCheckboxProps) {
    const [isOpen, toggle] = useState(false);
    const [checked, setChecked] = useState<boolean>(!!_checked);

    useEffect(() => {
        setChecked(!!_checked);
    }, [_checked]);

    return (
        <>
            <header className={styles.settingsCheckboxHeader}>
                <FormField
                    theme={Theme.Dark}
                    type="checkbox"
                    className="is-switch"
                    checked={checked}
                    name={name}
                    onChange={(e) => {
                        setChecked(e.target.checked);
                        onChange?.(e);
                    }}
                    id={id}
                    formId={formId}
                    label={label}
                    disabled={disabled}
                />

                {children && checked ? (
                    <Button
                        type="button"
                        tone={ButtonTone.Hoverable}
                        icon="three_dots"
                        onClick={() => toggle(!isOpen)}
                    />
                ) : null}
            </header>

            <main className={cn(styles.settingsCheckboxSubmenu, isOpen && 'is-open')}>
                {children}
            </main>
        </>
    );
}
