import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FEEDBACK } from '@he-novation/config/paths/modals.constants';
import { Locale } from '@he-novation/config/types/i18n.types';
import type { Folder as ContentToUpload } from '@he-novation/design-system/components/form/DropUpload/DropUpload';
import { fetchFolder } from '@he-novation/front-shared/async/folder.async';
import { fetchStorage } from '@he-novation/front-shared/async/user.async';
import { v4 as uuid } from 'uuid';

import { Uploader } from '$helpers/Uploader';
import { UploaderState, UploadFolder } from '$helpers/Uploader.types';
import { uploadTree } from '$helpers/uploadTree';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { userInfosSelector } from '$redux/user/userSelectors';

export function useUploader() {
    const [state, setState] = useState<UploaderState>({
        uploads: [],
        pending: [],
        finished: [],
        errors: []
    });
    const uploader = Uploader;
    const { userInfos } = useSelector(userInfosSelector);

    const { t } = useTranslate();

    const { openModal } = useModal();

    useEffect(() => {
        Uploader.t = t;
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            if (uploader.uploads.length) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        uploader.register(setState);
        return () => {
            uploader.unregister(setState);
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);

    const checkStorage = useCallback(async (folderUuid: string, size: number) => {
        const { user } = await fetchFolder({ uuid: folderUuid });
        const { available } = await fetchStorage(folderUuid);

        if (available !== -1 && size > available) {
            let message: string;
            if (userInfos.uuid && user.uuid === userInfos.uuid) {
                message = t("folder.You don't have enough available space.");
            } else {
                message = t('folder.The owner of the folder does not have enough available space.');
            }
            openModal(FEEDBACK, {
                title: t('common.Upload failed'),
                message,
                isError: true
            });
            return false;
        }
        return true;
    }, []);

    return {
        ...state,
        uploadFile: async (file: File, folder: UploadFolder, parentFileUuid?: string) => {
            if (!(await checkStorage(folder.uuid, file.size!))) return;

            uploader.resetInvalidFiles();

            return await uploader.uploadFile(userInfos, {
                uploadGroup: uuid(),
                uploadIndex: 0,
                uploadsTotal: 1,
                file,
                folder,
                parentFileUuid
            });
        },
        uploadSubtitles: async (
            file: File,
            fileUuid: string,
            fileVersion: number,
            locale: Locale
        ) => {
            uploader.resetInvalidFiles();

            return await uploader.uploadSubtitles({
                uploadGroup: uuid(),
                uploadIndex: 0,
                uploadsTotal: 1,
                file,
                fileUuid,
                fileVersion,
                locale
            });
        },
        uploadTree: async (
            userUuid: string,
            rootFolder: {
                uuid: string;
                name: string;
                shared: boolean | number;
                metadata: object;
                triggers: string[];
            },
            contentToUpload: ContentToUpload,
            hasPluginFiletree: boolean,
            preferences: object = {}
        ) => {
            if (!(await checkStorage(rootFolder.uuid, contentToUpload.size!))) return;
            uploader.resetInvalidFiles();

            return await uploadTree(
                userInfos,
                userUuid,
                rootFolder,
                { ...contentToUpload, uuid: rootFolder.uuid, name: rootFolder.name, path: '/' },
                hasPluginFiletree,
                preferences,
                t
            );
        }
    };
}
