import './CheckboxDropdownFields.scss';
import React, {
    CSSProperties,
    ReactNode,
    SyntheticEvent,
    useEffect,
    useRef,
    useState
} from 'react';
import ReactDOM from 'react-dom';
import { Button, ButtonTone } from '../../buttons/Button/Button';
import { onClickOutside } from '../../../utils/onClickOutside';
import { DirectionX, DirectionY, getAbsolutePosition } from '../../../utils/getAbsolutePosition';
import { FormField } from '../FormField/FormField';
import cn from 'classnames';
import { Theme } from '../../../enums';

type CheckboxOption = {
    label: string;
    value: any;
};
export type CheckboxDropdownFieldsProps = {
    formId: string;
    options: (CheckboxOption | false | null | undefined)[];
    direction?: [DirectionX, DirectionY];
    name?: string;
    buttonLabel: ReactNode | ((checked: CheckboxOption[]) => ReactNode);
    onChange?: (e: SyntheticEvent | null, checked: CheckboxOption[]) => void;
    className?: string;
    dropdownListClassName?: string;
    theme?: Theme;
    icon?: string;
    buttonTone?: ButtonTone;
    iconAfter?: boolean;
    noRotate?: boolean;
    group?: string;
    groupMin?: number;
    value?: string[];
};

/**
 * @deprecated should be replaced by CheckboxDropDown
 */
export function CheckboxDropdownFields({
    formId,
    buttonLabel,
    options,
    direction,
    name,
    onChange,
    className,
    dropdownListClassName,
    theme = Theme.Light,
    icon,
    iconAfter = true,
    buttonTone = ButtonTone.FormField,
    noRotate,
    group,
    groupMin,
    value
}: CheckboxDropdownFieldsProps) {
    const [errors, setErrors] = useState<string[]>([]);
    const [checked, setChecked] = useState<CheckboxOption[]>(
        options.filter((c) => value && c && value.includes(c.value)) as CheckboxOption[]
    );
    const absoluteRef = useRef<HTMLUListElement | null>(null);
    const triggerRef = useRef();
    const [style, setStyle] = useState<CSSProperties | null>(null);
    const errorHtmlElement = useRef<HTMLDivElement | null>(null);

    const close = () => setStyle(null);

    useEffect(() => {
        const unregister = onClickOutside(
            absoluteRef,
            () => {
                close();
            },
            { excludedElements: [triggerRef.current!] }
        );
        return () => {
            unregister();
        };
    }, []);

    useEffect(() => {
        const toFilter = checked.filter((c) => !!options.find((o) => o && o.value === c.value));
        if (toFilter.length !== checked.length) {
            setChecked(toFilter);
            onChange?.(null, toFilter);
        }
    }, [options, checked]);

    useEffect(() => {
        const newChecked = options.filter(
            (c) => value && c && value.includes(c.value)
        ) as CheckboxOption[];
        setChecked(newChecked);
    }, [value]);

    const open = () => {
        setStyle(
            getAbsolutePosition(
                triggerRef,
                absoluteRef,
                [direction?.[0] || DirectionX.Center, direction?.[1] || DirectionY.Bottom],
                true
            )
        );
    };
    return (
        <div
            className={cn(
                'checkbox-dropdown-fields',
                className,
                theme,
                errors.length && 'has-errors'
            )}
        >
            <Button
                type="button"
                iconAfter={iconAfter}
                className={!noRotate && 'rotate-icon-270'}
                icon={typeof icon === 'undefined' ? 'chevron' : icon}
                onClick={() => (style ? close() : open())}
                ref={triggerRef}
                tone={buttonTone}
                theme={theme}
            >
                {typeof buttonLabel === 'function' ? buttonLabel(checked) : buttonLabel}
            </Button>
            {group && groupMin && (
                <div ref={errorHtmlElement} className="checkbox-dropdown-errors" />
            )}
            {ReactDOM.createPortal(
                <ul
                    ref={absoluteRef}
                    style={
                        style || { visibility: 'hidden', bottom: 0, left: 0, pointerEvents: 'none' }
                    }
                    className={cn('checkbox-dropdown-list', dropdownListClassName, theme)}
                >
                    {options
                        .filter((o) => !!o)
                        .map((o: CheckboxOption, i) => (
                            <li key={o.value}>
                                <FormField
                                    theme={theme}
                                    formId={formId}
                                    type="checkbox"
                                    name={name}
                                    value={o.value}
                                    id={`${formId}-${name}-cb-dd-l-${i}`}
                                    label={o.label}
                                    checked={checked.find(({ value }) => value === o.value)}
                                    validation={
                                        group ? { group: group, groupMin: groupMin } : undefined
                                    }
                                    onErrorChange={(errors) => {
                                        setErrors(errors);
                                    }}
                                    hideErrors={i > 0}
                                    onChange={(e) => {
                                        const _checked = e.currentTarget.checked
                                            ? [...checked, o]
                                            : checked.filter((c) => c.value !== o.value);
                                        setChecked(_checked);
                                        onChange?.(e, _checked);
                                    }}
                                    errorHtmlElement={errorHtmlElement.current}
                                />
                            </li>
                        ))}
                </ul>,
                document.body
            )}
        </div>
    );
}
