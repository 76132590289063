import styles from './FormNote.module.css';
import React from 'react';
import { TeamMember } from '@he-novation/config/types/team.types';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { getDisplayName } from '@he-novation/utils/user';

import Chips from '$components/base/Chips/Chips';

type FormNoteChipsProps = {
    attachments: File[];
    setAttachments: (files: File[]) => void;
    estimatedEndDate?: Date;
    setEstimatedEndDate: (date: Date | undefined) => void;
    teamMembers: TeamMember[];
    setTeamMembers: (members: TeamMember[]) => void;
    tags: string[];
    setTags: (labels: string[]) => void;
};

export function FormNoteChips({
    attachments,
    setAttachments,
    estimatedEndDate,
    setEstimatedEndDate,
    teamMembers,
    setTeamMembers,
    tags,
    setTags
}: FormNoteChipsProps) {
    return (
        <div className={styles.chips}>
            {estimatedEndDate && (
                <Chips onDelete={() => setEstimatedEndDate(undefined)}>
                    {estimatedEndDate.toLocaleDateString()}
                </Chips>
            )}
            {teamMembers.map((member) => (
                <Chips
                    key={member.uuid}
                    onDelete={() =>
                        setTeamMembers(teamMembers.filter((tm) => tm.uuid !== member.uuid))
                    }
                >
                    <Icon icon={'at'} />
                    {getDisplayName(member)}
                </Chips>
            ))}
            {attachments.map((a, i) => (
                <Chips
                    key={a.name + i}
                    onDelete={() =>
                        setAttachments(
                            attachments.filter((attachment) => attachment.name !== a.name)
                        )
                    }
                >
                    <Icon icon="paper_clip" stroke="white" />
                    {a.name}
                </Chips>
            ))}
            {tags.map((l) => (
                <Chips key={l} onDelete={() => setTags(tags.filter((label) => label !== l))}>
                    <Icon icon="label" stroke="white" />
                    {l}
                </Chips>
            ))}
        </div>
    );
}
