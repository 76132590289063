import styles from './MainMenu.module.css';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    adminSettingsLink,
    castsLink,
    clientSettingsLink,
    dashboardLink,
    directoryLink,
    folderLink,
    planningLink,
    projectsLink,
    tasksLink,
    userSettingsLink
} from '@he-novation/config/paths/herawFrontUris';
import { ClientUserRole } from '@he-novation/config/types/client.types';
import { ROLE_OWNER } from '@he-novation/config/utils/acl';
import {
    AvatarSize,
    AvatarUser
} from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { MultiActionsList } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButtonList';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { logout } from '@he-novation/front-shared/async/user.async';
import { UploadTracker } from '../UploadTracker/UploadTracker';

import { HubspotButton } from '$components/Buttons/HubspotButton/HubspotButton';
import { MainMenuAddButton } from '$components/Buttons/MainMenuAddButton/MainMenuAddButton';
import combineSelectors from '$helpers/combineSelectors';
import { useTranslate } from '$hooks/useTranslate';
import { isSmallScreenSelector } from '$redux/config/configSelectors';
import { fileProjectUuidSelector } from '$redux/content/file/fileSelectors';
import { folderRoleSelector } from '$redux/content/folder/folderSelectors';
import { set } from '$redux/route/routeActions';
import { routeSelector } from '$redux/route/routeSelectors';
import {
    hasPluginSelector,
    localeSelector,
    userClientRoleSelector,
    userEventsSelector,
    userInfosSelector,
    userProjectsSelector
} from '$redux/user/userSelectors';

export function MainMenu() {
    const dispatch = useDispatch();

    const {
        hasPluginCast,
        hasPluginContacts,
        hasPluginPlanning,
        hasPluginProjects,
        userInfos,
        route,
        isSmallScreen,
        userProjects,
        userEvents
    } = useSelector(
        combineSelectors(
            hasPluginSelector('cast'),
            hasPluginSelector('contacts'),
            hasPluginSelector('planning'),
            hasPluginSelector('projects'),
            userInfosSelector,
            routeSelector,
            isSmallScreenSelector,
            userProjectsSelector,
            userEventsSelector
        )
    );

    const userClientRole = useSelector(userClientRoleSelector);
    const { folderRole } = useSelector(folderRoleSelector);
    const locale = useSelector(localeSelector);

    const setRoute = useCallback((route: string) => dispatch(set(route)), []);
    const { t } = useTranslate();

    let settingsActions: MultiActionsList = [
        { href: userSettingsLink(), icon: 'user', children: t('common.My account') }
    ];

    if (userClientRole === ClientUserRole.ADMINISTRATOR) {
        settingsActions.push({
            href: clientSettingsLink(),
            icon: 'crown',
            children: t('common.Workspace administration')
        });
    }

    if (userInfos.isSuperAdmin) {
        settingsActions.push({
            href: adminSettingsLink(),
            icon: 'cog',
            children: 'HERAW admin'
        });
    }

    settingsActions = [
        ...settingsActions,
        { separator: true },
        {
            onClick: () => logout(locale),
            icon: 'exit',
            children: t('common.Sign out')
        }
    ];

    const tooltipDirection = isSmallScreen
        ? [DirectionX.LeftInner, DirectionY.Top]
        : [DirectionX.Right, DirectionY.Middle];

    return (
        <nav id="c-main-menu" className={styles.menu}>
            <ul className={styles.list}>
                <li>
                    <MainMenuAddButton />
                </li>

                <li>
                    <Button
                        icon="dashboard"
                        tone={ButtonTone.Hoverable}
                        id="mm-dashboard"
                        href={dashboardLink()}
                        tooltip={t('common.Dashboard')}
                        tooltipOptions={{
                            direction: tooltipDirection
                        }}
                        onDragStart={(e: Event) => {
                            e.preventDefault();
                            return false;
                        }}
                        className={route.startsWith('/dashboard') ? 'is-active' : null}
                    >
                        {t('common.Dashboard')}
                    </Button>
                </li>

                {(hasPluginProjects || userProjects > 0) && (
                    <li>
                        <Button
                            id="mm-project"
                            tone={ButtonTone.Hoverable}
                            className={route.startsWith('/project') ? 'is-active' : null}
                            icon="briefcase"
                            tooltip={t('common.Projects')}
                            tooltipOptions={{
                                direction: tooltipDirection
                            }}
                            href={projectsLink()}
                            onClick={(e: Event) => {
                                if (window.localStorage.previousProject === 'null') {
                                    window.localStorage.removeItem('previousProject');
                                }
                                if (
                                    !/^\/project/.test(window.location.pathname) &&
                                    window.localStorage.previousProject
                                ) {
                                    e.preventDefault();
                                    setRoute(
                                        '/project/' +
                                            window.localStorage.previousProject +
                                            '/' +
                                            (window.localStorage.previousProjectRoute || 'folders')
                                    );
                                }
                            }}
                        >
                            {t('common.Projects')}
                        </Button>
                    </li>
                )}

                {window.location.pathname !== '/index_client' &&
                    (hasPluginPlanning || userEvents > 0) && (
                        <li>
                            <Button
                                id="mm-planning"
                                icon="calendar"
                                tone={ButtonTone.Hoverable}
                                tooltip={t('common.Planning')}
                                tooltipOptions={{
                                    direction: tooltipDirection
                                }}
                                href={planningLink()}
                                className={route.startsWith('/planning') ? 'is-active' : null}
                            >
                                {t('common.Planning')}
                            </Button>
                        </li>
                    )}

                {(hasPluginProjects || userProjects > 0) && (
                    <li>
                        <Button
                            id="mm-tasks"
                            icon={'check_encircled'}
                            tone={ButtonTone.Hoverable}
                            tooltip={t('common.Tasks')}
                            tooltipOptions={{
                                direction: tooltipDirection
                            }}
                            href={tasksLink()}
                            className={route.startsWith('/tasks') ? 'is-active' : null}
                        >
                            {t('common.Tasks')}
                        </Button>
                    </li>
                )}

                <li>
                    <Button
                        id="mm-folder"
                        icon={[{ name: 'pictures', stroke: 'white', fill: 'white' }]}
                        href={folderLink()}
                        tone={ButtonTone.Hoverable}
                        tooltip={'DAM'}
                        tooltipOptions={{
                            direction: tooltipDirection
                        }}
                        className={
                            folderRole === ROLE_OWNER && (route.startsWith('/folder') || !route)
                                ? 'is-active'
                                : null
                        }
                    >
                        DAM
                    </Button>
                </li>

                {hasPluginCast && (
                    <li>
                        <Button
                            id="mm-casts"
                            icon="signal"
                            tone={ButtonTone.Hoverable}
                            href={castsLink()}
                            tooltip={t('common.Casts')}
                            tooltipOptions={{
                                direction: tooltipDirection
                            }}
                            className={route.startsWith('/casts') ? 'is-active' : null}
                        >
                            {t('common.Casts')}
                        </Button>
                    </li>
                )}

                {hasPluginContacts && (
                    <li>
                        <Button
                            id="mm-contacts"
                            icon="shapes"
                            tone={ButtonTone.Hoverable}
                            href={directoryLink()}
                            tooltip={t('common.Directory')}
                            tooltipOptions={{
                                direction: tooltipDirection
                            }}
                            className={route.startsWith('/directory') ? 'is-active' : null}
                        >
                            {t('common.Directory')}
                        </Button>
                    </li>
                )}
            </ul>
            <ul>
                <li>
                    <UploadTracker />
                </li>
                <li>
                    <HubspotButton />
                </li>
                <li className={styles.settings}>
                    <Tooltip
                        content={t('common.Settings')}
                        direction={[DirectionX.Right, DirectionY.Middle]}
                    >
                        <MultiActionButton
                            actions={settingsActions}
                            fireIfSingle={true}
                            direction={[DirectionX.Right, DirectionY.TopInner]}
                        >
                            <AvatarUser size={AvatarSize.Small} {...userInfos} />
                        </MultiActionButton>
                    </Tooltip>
                </li>
            </ul>
        </nav>
    );
}
