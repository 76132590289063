import styles from './KeyValueList.module.css';
import React, { ReactNode } from 'react';

export type KeyValue = {
    key: string;
    value: ReactNode;
};

type KeyValueListProps = {
    list: KeyValue[];
    className?: string;
};

export function KeyValueList({ list, className }: KeyValueListProps) {
    return (
        <ul className={className}>
            {list.map(({ key, value }, i) => (
                <li key={key + '-' + i} className={styles.item}>
                    <span className={styles.key}>{key}</span>
                    <span className={styles.separator} />
                    <span className={styles.value}>{value}</span>
                </li>
            ))}
        </ul>
    );
}
