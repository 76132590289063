import { Cast, PublicCastColors } from '@he-novation/config/types/cast.types';
import { FrontCast } from '@he-novation/front-shared/types/cast.front-types';
import { atom } from 'jotai';

export const castPasswordAtom = atom<string | undefined>(undefined);

export const castColorsAtom = atom<PublicCastColors | null>(null);

export const castUidAtom = atom<string | null>(null);

export const castAtom = atom<FrontCast | null>(null);

export const castsLoadedAtom = atom<boolean>(false);
export const castsAtom = atom<Cast[]>([]);

export const projectCastsLoadedUuidAtom = atom<string | null>(null);
export const projectCastsAtom = atom<Cast[]>([]);
