import styles from './SidePanelFolderSettings.module.css';
import React, { useEffect, useRef, useState } from 'react';
import type { FormStore } from 'react-modular-forms/dist/declarations/FormStore';
import { useSelector } from 'react-redux';
import { admittanceLink } from '@he-novation/config/paths/herawLoginPaths';
import { FolderLiveNotify, FullFolder } from '@he-novation/config/types/folder.types';
import { acl, ROLE_MANAGER, ROLE_OWNER } from '@he-novation/config/utils/acl';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { CopyField } from '@he-novation/design-system/components/form/CopyField/CopyField';
import {
    FormField,
    FormFields,
    ModularForm
} from '@he-novation/design-system/components/form/FormField/FormField';
import { Theme } from '@he-novation/design-system/enums';
import { asyncFolderFetchNotificationSettings } from '@he-novation/front-shared/async/folder.async';
import { FormValues } from '../SidePanelFolder';

import { SidePanelFolderSettingsCheckbox } from '$components/SidePanel/SidePanelFolderSettings/SidePanelFolderSettings/SidePanelFolderSettingsCheckbox/SidePanelFolderSettingsCheckbox';
import combineSelectors from '$helpers/combineSelectors';
import { useTranslate } from '$hooks/useTranslate';
import { folderPluginsSelector } from '$redux/content/folder/folderSelectors';
import { preferencesSelector } from '$redux/user/userSelectors';

type SidePanelFolderSettingsProps = {
    folder: FullFolder;
    onChange: (settings: FormValues) => void;
    onRecursiveButtonClick: (settings: FormValues) => void;
};

export function SidePanelFolderSettings({
    folder,
    onChange,
    onRecursiveButtonClick
}: SidePanelFolderSettingsProps) {
    const { folderPlugins } = useSelector(
        combineSelectors(folderPluginsSelector, preferencesSelector)
    );
    const { t, locale } = useTranslate();

    const form = useRef<FormStore>(null);

    const readOnly = !acl.folders.modify(folder.role);
    const folderAdmittance = folder.metadata?.admittance ? folder.metadata.admittance : null;

    const submit = () => {
        const settings = form.current?.getValues();
        if (settings) {
            onChange(settings);
        }
    };

    const formId = 'folder-settings-form';

    return (
        <ModularForm id={formId} ref={form} className={styles.settings}>
            {!readOnly && (
                <>
                    {folderPlugins.includes('admittance') && (
                        <SidePanelFolderSettingsCheckbox
                            formId={formId}
                            name="admittance"
                            id="folder-settings-form-admittance"
                            label={t('folder.Admittance link')}
                            onChange={() => submit()}
                            checked={!!folder.admittance}
                        >
                            <CopyField
                                id="folder-settings-form-admittance-link"
                                theme={Theme.Dark}
                                value={
                                    process.env.MARKETING_URL +
                                    admittanceLink(locale, folder.client.name, folder.uuid)
                                }
                            />

                            <FormFields
                                formId={formId}
                                theme={Theme.Dark}
                                fields={[
                                    {
                                        name: 'admittance-role',
                                        id: 'folder-admittance-role',
                                        type: 'select',
                                        onChange: () => submit(),
                                        value: folderAdmittance?.role,
                                        children: acl.content.showRoles().map((key) => (
                                            <option value={key} key={key}>
                                                {t(`folder.__${key}`)}
                                            </option>
                                        ))
                                    },
                                    {
                                        name: 'admittance-download',
                                        id: 'folder-admittance-download',
                                        type: 'checkbox',
                                        onChange: () => submit(),
                                        checked: folderAdmittance?.download,
                                        label: t('common.Download')
                                    },
                                    {
                                        name: 'admittance-export',
                                        id: 'folder-admittance-export',
                                        onChange: () => submit(),
                                        checked: folderAdmittance?.export,
                                        type: 'checkbox',
                                        label: t('folder.Export')
                                    }
                                ]}
                            />
                        </SidePanelFolderSettingsCheckbox>
                    )}

                    <SidePanelFolderSettingsCheckbox
                        formId={formId}
                        id="folder-settings-form-export"
                        name="export"
                        onChange={(e) => {
                            if (e.target.checked) {
                                (
                                    document.getElementById(
                                        'folder-option-export'
                                    ) as HTMLInputElement
                                ).value = 'always';
                            }
                            submit();
                        }}
                        label={t('folder.Allow exports')}
                        checked={folder.exportMode !== 'never'}
                    >
                        <FormField
                            formId={formId}
                            name="export_mode"
                            id="folder-option-export"
                            type="select"
                            value={folder.exportMode}
                            onChange={() => submit()}
                        >
                            <option value="always">{t('folder.All files')}</option>
                            <option value="final">{t('folder.Final versions')}</option>
                        </FormField>
                    </SidePanelFolderSettingsCheckbox>

                    {folderPlugins.includes('watermark') && (
                        <SidePanelFolderSettingsCheckbox
                            formId={formId}
                            onChange={() => submit()}
                            label={t('folder.Watermark')}
                            id="folder-plugin-watermark"
                            name="watermark"
                            checked={!!folder.watermark}
                        />
                    )}

                    {folderPlugins.includes('bundles') && (
                        <SidePanelFolderSettingsCheckbox
                            onChange={() => submit()}
                            formId={formId}
                            label={t('folder.Handle HTML bundles')}
                            id="folder-plugin-bundles"
                            name="trigger-bundles"
                            checked={folder.triggers.includes('bundles')}
                        />
                    )}
                </>
            )}

            <Button
                className={styles.recursiveButton}
                type={'button'}
                tone={ButtonTone.Outlined}
                icon="list"
                iconAfter
                onClick={() => {
                    const settings = form.current?.getValues();
                    if (settings) {
                        onRecursiveButtonClick(settings);
                    }
                }}
            >
                {t('folder.Apply to subfolders')}
            </Button>
        </ModularForm>
    );
}
