import {
    AreaSelectionData,
    AreaSelectionOutputData,
    AreaSelectionType
} from '@he-novation/config/types/area-selection.types';
import { atom } from 'jotai';

export const areaSelectionInitialState: AreaSelectionAtom = {
    type: AreaSelectionType.Freehand,
    toggled: false,
    selection: [],
    display: undefined
};

export type AreaSelectionAtom = {
    type: AreaSelectionType;
    toggled: boolean;
    selection: AreaSelectionData[];
    display?: AreaSelectionOutputData[];
};
export const areaSelectionAtom = atom<AreaSelectionAtom>(areaSelectionInitialState);
