import { PROJECT_STATUS, ROLES } from '@he-novation/config/constants/projects.constants';
import { TEAM_STATUS } from '@he-novation/config/constants/teams.constants';
import paths, {
    pathWithParams,
    projectPaths,
    publicApiV1Paths
} from '@he-novation/config/paths/herawApiPaths';
import type { ProjectFolderTree } from '@he-novation/config/types/folder.types';
import { OAProject } from '@he-novation/config/types/open-api/project.open-api.types';
import {
    FolderConvertToProjectBody,
    ProjectCreateBody,
    ProjectSpecificSchema,
    ProjectUpdateBody,
    type ProjectUuidAndClientNameSchema
} from '@he-novation/config/types/payloads/project.payload';
import {
    Member,
    TeamSpecificSchema,
    TeamUpdateBody
} from '@he-novation/config/types/payloads/team.payload';
import { Project, ProjectStats } from '@he-novation/config/types/project.types';
import { Task, TaskChange } from '@he-novation/config/types/task.types';
import { Team, TeamWithMembers } from '@he-novation/config/types/team.types';
import {
    dateAsUTCDate,
    isoStringToUTCDate,
    objectIsoStringsToDates,
    setLatestHour
} from '@he-novation/utils/datetime';
import { isNull, omitBy } from 'lodash/fp';
import { TaskCreateBody } from '../../config/types/payloads/task.payload';
import { apiFetch, fetchAsFormData } from './apiFetch';
import { download } from './file.async';

export function mapFetchedProject(project: Project): Project {
    if (typeof project.created === 'string') project.created = new Date(project.created);
    if (typeof project.updated === 'string') project.updated = new Date(project.updated);
    if (typeof project.startDate === 'string') project.startDate = new Date(project.startDate);
    if (typeof project.endDate === 'string') project.endDate = new Date(project.endDate);
    return project;
}

export function mapFetchedTask(task: Task): Task {
    if (typeof task.created === 'string') task.created = new Date(task.created);
    if (typeof task.updated === 'string') task.updated = new Date(task.updated);
    if (typeof task.estimatedEndDate === 'string')
        task.estimatedEndDate = new Date(task.estimatedEndDate);
    return task;
}

export const asyncProjectCreate = (payload: ProjectCreateBody): Promise<Project> =>
    apiFetch(projectPaths.single, {
        method: 'POST',
        body: payload
    });
export const asyncProjectDelete = (projectUuid: string) =>
    apiFetch(paths.projects.single, {
        method: 'DELETE',
        params: { projectUuid } satisfies ProjectSpecificSchema
    });

export const asyncProjectStatusUpdate = (projectUuid: string, status: PROJECT_STATUS) =>
    apiFetch(paths.projects.updateProjectStatus, {
        method: 'PUT',
        params: { projectUuid } satisfies ProjectSpecificSchema,
        body: {
            status
        }
    });

/**
 * estimatedEndDate will be converted from localized time to UTC
 * If GMT+1 is 23:59:59, it will be converted to  UTC 23:59:59 or GMT+1 22:59:59
 */
export const createTask = ({
    assignees,
    attachments,
    projectUuid,
    teamUuid,
    status,
    description,
    estimatedEndDate
}: Omit<TaskCreateBody, 'estimatedEndDate'> & {
    estimatedEndDate?: Date | null;
}): Promise<Task> => {
    if (estimatedEndDate) estimatedEndDate = setLatestHour(dateAsUTCDate(estimatedEndDate), true);
    return fetchAsFormData(paths.tasks.root, {
        // should use fetchAsFormData to send attachments
        method: 'POST',
        body: omitBy(isNull, {
            assignees: assignees,
            attachments,
            projectUuid,
            teamUuid,
            status,
            description,
            estimatedEndDate: estimatedEndDate?.toISOString()
        })
    }).then((r) => mapFetchedTask(r));
};

export const deleteMember = (projectUuid: string, userUuid: string) => {
    return apiFetch(paths.projects.member, {
        method: 'DELETE',
        params: { projectUuid, userUuid }
    });
};

export const deleteTask = (taskUuid: string) =>
    apiFetch(paths.tasks.single, {
        method: 'DELETE',
        params: { taskUuid }
    });

export const asyncProjectsFetch = (): Promise<Project[]> =>
    apiFetch(projectPaths.multiple, {
        method: 'GET'
    }).then((projects) =>
        projects.map(objectIsoStringsToDates(['created', 'updated', 'startDate', 'endDate']))
    );

export const fetchProject = (projectUuid: string): Promise<Project> =>
    apiFetch(projectPaths.specific, {
        method: 'GET',
        params: { projectUuid } satisfies ProjectSpecificSchema
    }).then((p) => {
        p = objectIsoStringsToDates(['created', 'updated', 'startDate', 'endDate'])(p);
        p.tasks = p.tasks.map((t) => ({
            ...t,
            created: new Date(t.created),
            updated: new Date(t.updated || t.created),
            estimatedEndDate: t.estimatedEndDate && isoStringToUTCDate(t.estimatedEndDate)
        }));
        return p;
    });

export const asyncProjectFolderTreeFetch = (
    clientName: string,
    projectUuid: string
): Promise<ProjectFolderTree> =>
    apiFetch(projectPaths.folderTree, {
        method: 'GET',
        params: { clientName, projectUuid } satisfies ProjectUuidAndClientNameSchema
    });

export const fetchProjectTasks = (projectUuid: string): Promise<Task[]> =>
    apiFetch(paths.projects.tasks, {
        params: { projectUuid } satisfies ProjectSpecificSchema,
        method: 'GET'
    }).then((tasks) => tasks.map((t) => mapFetchedTask(t)));

export const asyncProjectTeamsFetch = (projectUuid: string): Promise<TeamWithMembers[]> =>
    apiFetch(paths.projects.getTeamsByProject, {
        method: 'GET',
        params: { projectUuid } satisfies ProjectSpecificSchema
    });

export const fetchProjectEntities = () =>
    apiFetch(paths.projects.getAvailableCompanies, {
        method: 'GET'
    });

export const asyncProjectMembersInvite = (
    projectUuid: string,
    teamName: string,
    members: Member[],
    castTeamAccess?: boolean,
    message?: string,
    teamColor?: string
): Promise<TeamWithMembers> =>
    apiFetch(paths.teams.inviteToTeam, {
        params: { teamName },
        method: 'POST',
        body: {
            members: members.map(({ uuid, projectRole, email, canDownload, canExport }) => ({
                uuid,
                projectRole,
                email,
                canDownload,
                canExport
            })),
            castTeamAccess,
            message,
            projectUuid,
            teamColor
        }
    });

export const asyncProjectMemberUpdate = (
    projectUuid: string,
    userUuid: string,
    { teamUuid, role, download }: { teamUuid: string; role: ROLES; download: boolean }
) =>
    apiFetch(paths.projects.member, {
        method: 'PUT',
        params: {
            projectUuid,
            userUuid
        },
        body: {
            teamUuid,
            role,
            download
        }
    });

export const asyncProjectUpdate = (payload: ProjectUpdateBody & { uuid: string }) =>
    apiFetch(projectPaths.specific, {
        method: 'PUT',
        params: { projectUuid: payload.uuid } satisfies ProjectSpecificSchema,
        body: payload
    });

/**
 * estimatedEndDate will be converted from localized time to UTC
 * If GMT+1 is 23:59:59, it will be converted to  UTC 23:59:59 or GMT+1 22:59:59
 */
export const updateTask = ({
    taskUuid,
    assignees,
    attachments,
    teamUuid,
    status,
    description,
    estimatedEndDate
}: {
    taskUuid: string;
    assignees?: string[];
    attachments?: string[];
    teamUuid?: string;
    status?: string;
    description?: string;
    estimatedEndDate?: Date;
}): Promise<Task> => {
    if (estimatedEndDate) estimatedEndDate = setLatestHour(dateAsUTCDate(estimatedEndDate), true);
    return fetchAsFormData(pathWithParams(paths.tasks.single, { taskUuid }), {
        method: 'PUT',
        body: {
            assignees: Array.isArray(assignees) ? assignees.join(',') : assignees,
            attachments,
            teamUuid,
            status,
            description,
            estimatedEndDate:
                estimatedEndDate instanceof Date ? estimatedEndDate.toISOString() : estimatedEndDate
        }
    }).then((t) => mapFetchedTask(t));
};

export const deleteTeam = (teamUuid: string, projectUuid: string) =>
    apiFetch(paths.teams.updateStatus, {
        method: 'PUT',
        params: { teamUuid },
        body: JSON.stringify({
            status: TEAM_STATUS.HIDDEN,
            projectUuid
        })
    });

export const asyncTeamUpdate = (
    teamUuid: string,
    { name, projectUuid, castTeamAccess, teamColor }: TeamUpdateBody
): Promise<Team> =>
    apiFetch(paths.teams.single, {
        method: 'PUT',
        params: { teamUuid } satisfies TeamSpecificSchema,
        body: {
            name,
            projectUuid,
            castTeamAccess,
            teamColor
        }
    });

export async function updateMultipleTaskStatusAndOrdering(
    changes: TaskChange
): Promise<{ [taskUuid: string]: { orderingDelta: number; status?: string } }> {
    return await apiFetch(paths.tasks.multipleStatus, {
        method: 'PUT',
        body: JSON.stringify({
            changes
        })
    });
}

export const updateUserProjectMetadata = ({ projectUuid, metadata }) =>
    apiFetch(paths.projects.updateCurrentUserMetadata, {
        method: 'PUT',
        params: { projectUuid } satisfies ProjectSpecificSchema,
        body: JSON.stringify({ metadata })
    });

export const asyncTasksFetch = ({
    startDate,
    endDate
}: { startDate?: Date; endDate?: Date } = {}): Promise<Task[]> =>
    apiFetch(paths.tasks.root, {
        query: {
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString()
        }
    }).then((r) =>
        r.map((t) => ({
            ...t,
            created: new Date(t.created),
            updated: new Date(t.updated || t.created),
            estimatedEndDate: t.estimatedEndDate && isoStringToUTCDate(t.estimatedEndDate)
        }))
    );

export const asyncFetchProjectClientEventLabels = (projectUuid: string) =>
    apiFetch(paths.projects.clientEventLabels, {
        params: { projectUuid } satisfies ProjectSpecificSchema
    });

export const asyncProjectStatsFetch = (projectUuid: string): Promise<ProjectStats> =>
    apiFetch(paths.projects.stats, {
        params: { projectUuid } satisfies ProjectSpecificSchema
    }).then(objectIsoStringsToDates(['updated']));

export const asyncProjectStatsDownload = (projectUuid: string, name: string) =>
    apiFetch(paths.projects.statsXLSX, {
        rawResponse: true,
        params: { projectUuid } satisfies ProjectSpecificSchema
    }).then(async (r) => download(URL.createObjectURL(await r.blob()), name));

export const asyncTaskFetch = (taskUuid: string): Promise<Task> =>
    apiFetch(paths.tasks.single, { params: { taskUuid } }).then((t) => ({
        ...t,
        created: new Date(t.created),
        updated: new Date(t.updated || t.created),
        estimatedEndDate: t.estimatedEndDate && isoStringToUTCDate(t.estimatedEndDate)
    }));

export const asyncOpenApiProjectFetch = (apiKey: string): Promise<OAProject[]> =>
    apiFetch(publicApiV1Paths.projects.multiple, {
        headers: {
            Authorization: 'Bearer ' + apiKey
        }
    });

export function asyncProjectCreateFromFolder(body: FolderConvertToProjectBody) {
    return apiFetch(projectPaths.fromFolder, {
        method: 'POST',
        body
    });
}
