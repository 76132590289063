import isJsonString from './isJsonString';

const cleanUser = (user) => {
    if (user.profile && isJsonString(user.profile)) user.profile = JSON.parse(user.profile);
    else user.profile = {};
    if (user.vcard && isJsonString(user.vcard)) user.vcard = JSON.parse(user.vcard);
    else user.vcard = {};
    return user;
};

export default cleanUser;
