import styles from './SidePanelFileInfo.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { Asset, FFProbeResult } from '@he-novation/config/types/asset.types';
import { acl } from '@he-novation/config/utils/acl';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import cn from 'classnames';

import Accordion from '$components/Accordion/Accordion';
import { FileInfoDescription } from '$components/file/FileInfo/FileInfoDescription';
import { FileInfoGeneral } from '$components/file/FileInfo/FileInfoGeneral';
import { FileInfoMetadataAle } from '$components/file/FileInfo/FileInfoMetadataAle';
import { FileInfoMetadataCodec } from '$components/file/FileInfo/FileInfoMetadataCodec';
import { FileInfoMetadataExif } from '$components/file/FileInfo/FileInfoMetadataExif';
import { FileInfoTags } from '$components/file/FileInfo/FileInfoTags';
import { useTranslate } from '$hooks/useTranslate';
import { stateSelector } from '$redux/content/file/fileSelectors';
import { folderRoleSelector } from '$redux/content/folder/folderSelectors';

export function SidePanelFileInfo() {
    const { t } = useTranslate();

    const fileState = useSelector(stateSelector);
    const { folderRole } = useSelector(folderRoleSelector);

    const sourceAsset: Asset | undefined = fileState?.assets?.find(
        (a) => a.quality === 'source' && a.version === fileState.version
    );
    const ffprobeResult: FFProbeResult | undefined = sourceAsset?.ffprobeResult;

    if (!fileState.uuid) return <Loader />;

    return (
        <div className={cn(styles.sidePanelFileInfo, formStyles.dark)}>
            <Accordion
                className={styles.fileInfoAccordion}
                title={t('folder.General information')}
                icon={['file', 'magnifier']}
                isOpen={true}
            >
                <FileInfoGeneral file={fileState} />
            </Accordion>
            <Accordion
                className={styles.fileInfoAccordion}
                title={t('common.Description')}
                icon={'text_left'}
                isOpen={true}
            >
                <FileInfoDescription file={fileState} readOnly={!acl.files.setInfos(folderRole)} />
            </Accordion>
            <Accordion
                title={t('common.Tags')}
                icon={'label'}
                isOpen={true}
                className={styles.fileInfoAccordion}
            >
                <FileInfoTags file={fileState} readOnly={!acl.files.setInfos(folderRole)} />
            </Accordion>

            {fileState.metadataAle && (
                <Accordion
                    className={styles.fileInfoAccordion}
                    title={'Avid Log Exchange (ALE)'}
                    icon={['list', 'magnifier']}
                >
                    <FileInfoMetadataAle metadataAle={fileState.metadataAle} />
                </Accordion>
            )}

            {ffprobeResult && (
                <Accordion
                    className={styles.fileInfoAccordion}
                    title={t('folder.Codec details')}
                    icon={['pictures', 'magnifier']}
                >
                    <FileInfoMetadataCodec ffprobeResult={ffprobeResult} />
                </Accordion>
            )}

            {sourceAsset?.exifResult && (
                <Accordion
                    className={styles.fileInfoAccordion}
                    title={'EXIF Data'}
                    icon={['list', 'magnifier']}
                >
                    <FileInfoMetadataExif exifResult={sourceAsset.exifResult} />
                </Accordion>
            )}
        </div>
    );
}
