export enum WSCastEventType {
    FolderCreated = 'folder/created'
}

export interface WSCastEventFolderCreated {
    type: WSCastEventType.FolderCreated;
    castUid: string;
    castFolderUuid?: string;
    uuid: string;
    name: string;
    created: Date;
    user: {
        email: string;
        firstname?: string | null;
        lastname?: string | null;
    };
}
