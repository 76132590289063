import { UserType } from './user.types';
import { ROLES } from '../constants/projects.constants';
import { TEAM_STATUS } from '../constants/teams.constants';
import { z } from 'zod';

export const teamSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    color: z.string().nullable(),
    status: z.nativeEnum(TEAM_STATUS).optional(),
    castTeamAccess: z.boolean().optional(),
    ownTeam: z.boolean().optional()
});

export type Team = z.infer<typeof teamSchema>;

export type AccessibleTeam = Team & {
    isAccessible: boolean;
};

export type TeamMember = UserType & {
    role: ROLES;
    canExport?: boolean;
    canDownload?: boolean;
    workspaces: { uuid: string; name: string }[];
};

export type TeamWithMembers = Team & {
    members: TeamMember[];
};

export type FolderTeam = {
    uuid: string;
    name: string;
};
