import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';

import { I18nListsAtom, i18nListsAtom, List, localeAtom } from '$atoms/i18n-atoms';

type TranslateKey = `${List}.${string}`;

const listsFetched: string[] = [];

function splitKey(key: TranslateKey): [List, string] {
    const list = key.split('.')[0] as List;
    const k = key.replace(`${list}.`, '');
    return [list, k];
}

async function asyncListFetch(
    list: List,
    locale: string,
    setI18nLists: (i18nLists: I18nListsAtom | ((prev: I18nListsAtom) => I18nListsAtom)) => void
) {
    listsFetched.push(list);
    const data = await fetch(`/assets/i18n/${locale}/${list}.json`).then((res) => res.json());

    setI18nLists((prev) => {
        return { ...prev, [list]: data };
    });
    return data;
}

export function injectOptions(key: string, options?: Record<string, string | number> | null) {
    if (!options) return key;
    return Object.keys(options).reduce(
        (acc, curr) => acc.replace(`{{${curr}}}`, options[curr] as string),
        key || '__'
    );
}

export type Translator = (
    key: TranslateKey,
    obj?: Record<string, string | number> | null
) => string;

export function useTranslate(): { t: Translator; locale: string } {
    const locale = useAtomValue(localeAtom);
    const [lists, setLists] = useAtom(i18nListsAtom);

    const t = useCallback(
        (key: TranslateKey, obj?: Record<string, string | number> | null) => {
            const [list, k] = splitKey(key);
            if (!lists[list] && !listsFetched.includes(list)) {
                asyncListFetch(list, locale, setLists);
            }
            const value = lists[list]?.[k] || k;
            return injectOptions(value, obj);
        },
        [lists, locale]
    );

    return {
        locale,
        t
    };
}
