import { apiFetch } from '@he-novation/front-shared/async/apiFetch';

export const asyncActionSuccess = (name) => `${name}/SUCCESS`;
export const asyncActionError = (name) => `${name}/ERROR`;
export const asyncActionProgress = (name) => `${name}/PROGRESS`;

const defaultHeaders = {
    Accept: 'application/json'
};

const asyncAction = (actionName, xhrOptions, extraData) => (body) => async (dispatch) => {
    dispatch({ type: actionName, body, extraData });
    try {
        const response = await apiFetch(xhrOptions.url, {
            method: xhrOptions.method,
            data: JSON.stringify(body)
        });
        dispatch({
            type: asyncActionSuccess(actionName),
            body,
            response,
            extraData
        });
        if (typeof xhrOptions.success === 'function') {
            xhrOptions.success(dispatch, response, body);
        }
    } catch (e) {
        dispatch({ type: asyncActionError(actionName), response, body, extraData });
        if (typeof xhrOptions.error === 'function') {
            xhrOptions.error(dispatch, response, body);
        }
    }
};
export default asyncAction;
