import { atom } from 'jotai';

export type List =
    | 'admin'
    | 'calendar'
    | 'cast'
    | 'common'
    | 'entity'
    | 'contact'
    | 'folder'
    | 'item'
    | 'logs'
    | 'misc'
    | 'planning'
    | 'player'
    | 'plugins'
    | 'project'
    | 'settings'
    | 'subtitles';
export type ListRecord<T> = {
    [P in List]?: T;
};

export type I18nListsAtom = ListRecord<Record<string, string>>;
export const i18nListsAtom = atom<I18nListsAtom>({});

export const localeAtom = atom<'en' | 'fr' | 'de'>('en');
