export const stringToHex = (color: string) => {
    const colors = [
        'AliceBlue',
        'AntiqueWhite',
        'Aqua',
        'Aquamarine',
        'Azure',
        'Beige',
        'Bisque',
        'Black',
        'BlanchedAlmond',
        'Blue',
        'BlueViolet',
        'Brown',
        'BurlyWood',
        'CadetBlue',
        'Chartreuse',
        'Chocolate',
        'Coral',
        'CornflowerBlue',
        'Cornsilk',
        'Crimson',
        'Cyan',
        'DarkBlue',
        'DarkCyan',
        'DarkGoldenRod',
        'DarkGray',
        'DarkGrey',
        'DarkGreen',
        'DarkKhaki',
        'DarkMagenta',
        'DarkOliveGreen',
        'DarkOrange',
        'DarkOrchid',
        'DarkRed',
        'DarkSalmon',
        'DarkSeaGreen',
        'DarkSlateBlue',
        'DarkSlateGray',
        'DarkSlateGrey',
        'DarkTurquoise',
        'DarkViolet',
        'DeepPink',
        'DeepSkyBlue',
        'DimGray',
        'DimGrey',
        'DodgerBlue',
        'FireBrick',
        'FloralWhite',
        'ForestGreen',
        'Fuchsia',
        'Gainsboro',
        'GhostWhite',
        'Gold',
        'GoldenRod',
        'Gray',
        'Grey',
        'Green',
        'GreenYellow',
        'HoneyDew',
        'HotPink',
        'IndianRed',
        'Indigo',
        'Ivory',
        'Khaki',
        'Lavender',
        'LavenderBlush',
        'LawnGreen',
        'LemonChiffon',
        'LightBlue',
        'LightCoral',
        'LightCyan',
        'LightGoldenRodYellow',
        'LightGray',
        'LightGrey',
        'LightGreen',
        'LightPink',
        'LightSalmon',
        'LightSeaGreen',
        'LightSkyBlue',
        'LightSlateGray',
        'LightSlateGrey',
        'LightSteelBlue',
        'LightYellow',
        'Lime',
        'LimeGreen',
        'Linen',
        'Magenta',
        'Maroon',
        'MediumAquaMarine',
        'MediumBlue',
        'MediumOrchid',
        'MediumPurple',
        'MediumSeaGreen',
        'MediumSlateBlue',
        'MediumSpringGreen',
        'MediumTurquoise',
        'MediumVioletRed',
        'MidnightBlue',
        'MintCream',
        'MistyRose',
        'Moccasin',
        'NavajoWhite',
        'Navy',
        'OldLace',
        'Olive',
        'OliveDrab',
        'Orange',
        'OrangeRed',
        'Orchid',
        'PaleGoldenRod',
        'PaleGreen',
        'PaleTurquoise',
        'PaleVioletRed',
        'PapayaWhip',
        'PeachPuff',
        'Peru',
        'Pink',
        'Plum',
        'PowderBlue',
        'Purple',
        'RebeccaPurple',
        'Red',
        'RosyBrown',
        'RoyalBlue',
        'SaddleBrown',
        'Salmon',
        'SandyBrown',
        'SeaGreen',
        'SeaShell',
        'Sienna',
        'Silver',
        'SkyBlue',
        'SlateBlue',
        'SlateGray',
        'SlateGrey',
        'Snow',
        'SpringGreen',
        'SteelBlue',
        'Tan',
        'Teal',
        'Thistle',
        'Tomato',
        'Turquoise',
        'Violet',
        'Wheat',
        'White',
        'WhiteSmoke',
        'Yellow',
        'YellowGreen'
    ];
    const hexes = [
        'f0f8ff',
        'faebd7',
        '00ffff',
        '7fffd4',
        'f0ffff',
        'f5f5dc',
        'ffe4c4',
        '000000',
        'ffebcd',
        '0000ff',
        '8a2be2',
        'a52a2a',
        'deb887',
        '5f9ea0',
        '7fff00',
        'd2691e',
        'ff7f50',
        '6495ed',
        'fff8dc',
        'dc143c',
        '00ffff',
        '00008b',
        '008b8b',
        'b8860b',
        'a9a9a9',
        'a9a9a9',
        '006400',
        'bdb76b',
        '8b008b',
        '556b2f',
        'ff8c00',
        '9932cc',
        '8b0000',
        'e9967a',
        '8fbc8f',
        '483d8b',
        '2f4f4f',
        '2f4f4f',
        '00ced1',
        '9400d3',
        'ff1493',
        '00bfff',
        '696969',
        '696969',
        '1e90ff',
        'b22222',
        'fffaf0',
        '228b22',
        'ff00ff',
        'dcdcdc',
        'f8f8ff',
        'ffd700',
        'daa520',
        '808080',
        '808080',
        '008000',
        'adff2f',
        'f0fff0',
        'ff69b4',
        'cd5c5c',
        '4b0082',
        'fffff0',
        'f0e68c',
        'e6e6fa',
        'fff0f5',
        '7cfc00',
        'fffacd',
        'add8e6',
        'f08080',
        'e0ffff',
        'fafad2',
        'd3d3d3',
        'd3d3d3',
        '90ee90',
        'ffb6c1',
        'ffa07a',
        '20b2aa',
        '87cefa',
        '778899',
        '778899',
        'b0c4de',
        'ffffe0',
        '00ff00',
        '32cd32',
        'faf0e6',
        'ff00ff',
        '800000',
        '66cdaa',
        '0000cd',
        'ba55d3',
        '9370db',
        '3cb371',
        '7b68ee',
        '00fa9a',
        '48d1cc',
        'c71585',
        '191970',
        'f5fffa',
        'ffe4e1',
        'ffe4b5',
        'ffdead',
        '000080',
        'fdf5e6',
        '808000',
        '6b8e23',
        'ffa500',
        'ff4500',
        'da70d6',
        'eee8aa',
        '98fb98',
        'afeeee',
        'db7093',
        'ffefd5',
        'ffdab9',
        'cd853f',
        'ffc0cb',
        'dda0dd',
        'b0e0e6',
        '800080',
        '663399',
        'ff0000',
        'bc8f8f',
        '4169e1',
        '8b4513',
        'fa8072',
        'f4a460',
        '2e8b57',
        'fff5ee',
        'a0522d',
        'c0c0c0',
        '87ceeb',
        '6a5acd',
        '708090',
        '708090',
        'fffafa',
        '00ff7f',
        '4682b4',
        'd2b48c',
        '008080',
        'd8bfd8',
        'ff6347',
        '40e0d0',
        'ee82ee',
        'f5deb3',
        'ffffff',
        'f5f5f5',
        'ffff00',
        '9acd32'
    ];
    const colorIndex = colors.findIndex((c) => c.toUpperCase() === color.toUpperCase());
    if (colorIndex > 0) return hexes[colorIndex];
    return null;
};

export const hexToRgb = (c: string) => {
    c = c.replace(/^#/, ''); // strip #
    while (c.length < 6) c += c[c.length - 1];
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue
    return [r, g, b];
};

export const colorToRgb = (c: string) => {
    let rgb;
    if (c.startsWith('rgb')) {
        return c
            .split(/rgba?\(|[,)]/)
            .filter((v) => v !== '')
            .map((v) => parseInt(v));
    } else {
        const hex = stringToHex(c) || c;
        return hexToRgb(hex);
    }
};

export const rgbToHex = (c: string) => {
    const rgb = c
        .replace(/^rgb?\(|\s+|\)$/g, '')
        .split(',')
        .map((v) => parseInt(v));
    return `#${rgb.map((v) => v.toString(16).padStart(2, '0')).join('')}`;
};

export const rgbaToHex = (c: string) => {
    const rgba = c
        .replace(/^rgba?\(|\s+|\)$/g, '')
        .split(',')
        .map((v) => parseFloat(v));
    return `#${rgba
        .slice(0, 3)
        .map((v) => v.toString(16).padStart(2, '0'))
        .join('')}${
        rgba.length === 4
            ? Math.round(rgba[3] * 255)
                  .toString(16)
                  .padStart(2, '0')
            : ''
    }`;
};

export const anyColorToHex = (c: string) => {
    if (c.startsWith('rgba')) return rgbaToHex(c);
    if (c.startsWith('rgb')) return rgbToHex(c);
    if (c.startsWith('#')) return c;
    return c;
};

export const colorToRgbaString = (c: string, alpha?: number, isAlphaModifier?: boolean) => {
    const rgba = colorToRgb(c);
    if (typeof rgba[3] === 'undefined') rgba[3] = 1;
    if (typeof alpha !== 'undefined') rgba[3] = isAlphaModifier ? rgba[3] + alpha : alpha;
    return `rgba(${rgba.join(',')})`;
};

export const colorToInvertedRgbaString = (c: string) => {
    const rgba = colorToRgb(c);
    if (typeof rgba[3] === 'undefined') rgba[3] = 1;
    for (let i = 0; i < 3; i++) rgba[i] = 255 - rgba[i];
    return `rgba(${rgba.join(',')})`;
};

export const colorAverage = (c: string) => {
    const [r, g, b] = colorToRgb(c);
    return (r + g + b) / 3;
};
