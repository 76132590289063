import styles from './NoteLabelButton.module.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';

import { preferencesSelector } from '$redux/user/userSelectors';
export function NoteTagButton({
    tags,
    setTags
}: {
    tags: string[];
    setTags: (tags: string[]) => void;
}) {
    const {
        preferences: { tags: userTags }
    } = useSelector(preferencesSelector);
    return (
        <div className={styles.button}>
            <Button type="button" tone={ButtonTone.Hoverable} icon={['label', 'plus']} />
            <div className={styles.content}>
                <FormField
                    id="note-label-field"
                    type={'react-select'}
                    creatable
                    isMulti
                    isSearchable
                    menuPlacement="top"
                    value={tags}
                    isClearable
                    components={{
                        ClearIndicator: (): null => null,
                        MultiValue: (_: any): null => null
                    }}
                    options={(userTags || []).map((tag) => ({ label: tag, value: tag }))}
                    onChange={(_e: React.ChangeEvent<HTMLInputElement>, value: string[]) => {
                        const _tags = value.map((v) => v.trim());
                        setTags(
                            [...tags, ..._tags].filter((tag, i, self) => self.indexOf(tag) === i)
                        );
                    }}
                />
            </div>
        </div>
    );
}
