import { Asset } from '../asset.types';
import { FileFull } from '../file.types';

export enum WSFolderEventType {
    AssetUploadProgress = 'asset/upload/progress',
    ConvertingFileToVersion = 'file/convert-to-version',
    SourceAssetType = 'source/asset/type',
    AssetReady = 'asset/ready',
    TranscodingPending = 'transcoding/pending',
    TranscodingProgress = 'transcoding/progress',
    TranscodingFinished = 'transcoding/finished'
}

export interface WSFolderEventAssetUploadProgress {
    type: WSFolderEventType.AssetUploadProgress;
    assetUuid: string;
    fileUuid: string;
    progress: { progress: number }; // see UploadProgression in Uploader.types.ts;
}

export interface WSFolderEventSourceAssetType {
    type: WSFolderEventType.SourceAssetType;
    fileUuid: string;
    sourceAssetVersion: number;
    sourceAssetType: string;
}

export interface WSFolderEventAssetReady {
    type: WSFolderEventType.AssetReady;
    fileUuid: string;
    asset: Asset;
    copying?: {
        copied: number;
        total: number;
    };
}

export interface WSFolderEventTranscodingPending {
    type: WSFolderEventType.TranscodingPending;
    fileUuid: string;
    fileVersion: number;
}

export interface WSFolderEventTranscodingProgress {
    type: WSFolderEventType.TranscodingProgress;
    fileUuid: string;
    fileVersion: number;
    estimatedEndDate: string;
}

export interface WSFolderEventTranscodingFinished {
    type: WSFolderEventType.TranscodingFinished;
    fileUuid: string;
    fileVersion: number;
}

export interface WSFolderEventConvertingFileToVersion {
    type: WSFolderEventType.ConvertingFileToVersion;
    sourceFile: FileFull;
    targetFile: FileFull;
}
