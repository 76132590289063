import { secondsToFrame, secondsToTimeCode, timeCodeToSeconds } from '@he-novation/lib-timecodes';

export default (
    currentTime: number,
    frameRate: number,
    timecodestart?: string,
    updatedSilently?: boolean
) => {
    const timeWithOffset = timecodestart
        ? timeCodeToSeconds(timecodestart, frameRate) + currentTime
        : currentTime;
    return {
        currentTime,
        timecode: secondsToTimeCode(timeWithOffset, frameRate),
        frames: secondsToFrame(timeWithOffset, frameRate),
        updatedSilently
    };
};
