import { useState } from 'react';

export function useSearch(): {
    search: string | null;
    setSearch: (search: string) => void;
    resetSearch: (elementToFocus: string) => void;
} {
    const [search, setSearch] = useState<string | null>(null);

    const resetSearch = (elementToFocus: string) => {
        setSearch(null);
        document.querySelector<HTMLInputElement>(elementToFocus)?.focus();
    };

    return {
        search,
        setSearch,
        resetSearch
    };
}
