import { type SyntheticEvent, useEffect, useRef } from 'react';
import { onClickOutside } from '../utils/onClickOutside';

export function useOnClickOutside<T extends HTMLElement>(
    callback: (e: MouseEvent) => void,
    deps?: unknown[]
) {
    const ref = useRef<T>(null);

    useEffect(() => {
        const unregister = onClickOutside(ref, callback);
        return () => {
            unregister();
        };
    }, [ref, ...(deps || [])]);

    return ref;
}
