import styles from './DiscText.module.scss';
import React from 'react';
import cn from 'classnames';

type DiscTextProps = {
    className?: string;
    outline?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    color?: string;
};

export function DiscText({ className, outline, children, color }: DiscTextProps) {
    return (
        <span className={cn(styles.discText)}>
            <span
                className={cn(styles.disc, outline && styles.outline, className)}
                style={{ color }}
            />
            {children && <span>{children}</span>}
        </span>
    );
}
