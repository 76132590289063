import styles from './OptionsButton.module.css';
import React, { ReactNode } from 'react';
import {
    ActionType,
    MultiActionButton
} from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';

import { useTranslate } from '$hooks/useTranslate';

type OptionsButtonProps = {
    options: ActionType[];
    label?: ReactNode;
    selectedLabel?: ReactNode;
    className?: string;
};

export function OptionsButton({ options, label, selectedLabel, className }: OptionsButtonProps) {
    const { t } = useTranslate();

    const noneOption = { label: t('common.None'), key: 'none' };

    return (
        <MultiActionButton
            className={cn(styles.button, className)}
            contentClassName={styles.options}
            closeOnClickOutside={true}
            direction={[DirectionX.Center, DirectionY.Bottom]}
            actions={options}
        >
            <span className={styles.label}>{label}</span>
            <span className={styles.selectedLabel}>({selectedLabel || noneOption.label})</span>
            <Icon icon={'chevron'} />
        </MultiActionButton>
    );
}
