import styles from './PlaylistFilters.module.css';
import React from 'react';
import { FileStatus } from '@he-novation/config/types/db/enums';
import { AbsoluteContentButton } from '@he-novation/design-system/components/buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { DiscText } from '@he-novation/design-system/components/text/DiscText/DiscText';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import { FrontFolderContentFileFilter } from '@he-novation/front-shared/types/file.front-types';

import { fileStatusToColor } from '$components/file/FileStatus/FileStatus';
import { Translator, useTranslate } from '$hooks/useTranslate';

type PlaylistFiltersProps = {
    activatedFilters: FrontFolderContentFileFilter[];
    onChange: (filters: FrontFolderContentFileFilter[]) => void;
};

const getFileStatusFilters = (t: Translator): FrontFolderContentFileFilter[] =>
    Object.values(FileStatus).map((status) => ({
        key: 'status',
        label: (
            <DiscText color={fileStatusToColor(status, 0)}>
                {t(`folder.__FILE_STATUS_${status.toUpperCase()}`)}
            </DiscText>
        ),
        value: status
    }));

export function PlaylistFilters({ activatedFilters, onChange }: PlaylistFiltersProps) {
    const { t } = useTranslate();
    const filtersList = getFileStatusFilters(t).map((filter, index) => (
        <FormField
            type={'checkbox'}
            id={filter.key + '_' + index}
            key={filter.key + '_' + index}
            label={filter.label}
            checked={activatedFilters.find((f) => f.key === filter.key && f.value === filter.value)}
            onChange={(e) => {
                if (e.target.checked) {
                    onChange([...activatedFilters, filter]);
                } else {
                    onChange(
                        activatedFilters.filter(
                            (f) => f.key !== filter.key || f.value !== filter.value
                        )
                    );
                }
            }}
        />
    ));

    return (
        <AbsoluteContentButton
            content={<div className={styles.playlistFilters}>{filtersList}</div>}
            tagName={Button}
            closeOnClickOutside={true}
            triggersOnClick={true}
            triggersOnHover={false}
            tone={ButtonTone.Hoverable}
            icon={'filters'}
            direction={[DirectionX.Right, DirectionY.TopInner]}
        />
    );
}
