import styles from './NoteList.module.css';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { CONFIRM } from '@he-novation/config/paths/modals.constants';
import type { Comment } from '@he-novation/config/types/note.types';
import { acl } from '@he-novation/config/utils/acl';
import {
    AvatarSize,
    AvatarUser
} from '@he-novation/design-system/components/avatars/Avatar/AvatarUser';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { RichText } from '@he-novation/design-system/components/text/RichText/RichText';
import { asyncAssetFetch } from '@he-novation/front-shared/async/asset.async';
import {
    FORMAT_SHORT_DATETIME_TRANSLATED,
    formatDate
} from '@he-novation/front-shared/utils/datetime';
import { getDisplayName } from '@he-novation/utils/user';

import Chips from '$components/base/Chips/Chips';
import { FormComment } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormComment/FormComment';
import { useModal } from '$hooks/useModal';
import { useCreateNotesAndComments } from '$hooks/useNotes';
import { useTranslate } from '$hooks/useTranslate';
import { folderRoleSelector } from '$redux/content/folder/folderSelectors';
import { currentUserUuidSelector } from '$redux/user/userSelectors';

type NoteCommentProps = {
    children?: ReactNode | ReactNode[];
    comment: Omit<Comment, 'note' | 'file'>;
    noteUuid: string;
    editing?: string | null;
    setEditing: (editing: string | null) => void;
    isFirstNoteComment?: boolean;
};

export function NoteComment({
    comment,
    children,
    noteUuid,
    editing,
    setEditing,
    isFirstNoteComment
}: NoteCommentProps) {
    const { t } = useTranslate();
    const { openModal, closeModal } = useModal();

    const { currentUserUuid } = useSelector(currentUserUuidSelector);
    const { folderRole } = useSelector(folderRoleSelector);
    const { noteDelete, commentDelete } = useCreateNotesAndComments();
    const [hovered, setHovered] = useState(false);

    return (
        <div
            className={styles.comment}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {editing !== comment.uuid ? (
                <>
                    <RichText html={comment.content} />
                    {comment.assets.length > 0 && (
                        <ul className={styles.chips}>
                            {comment.assets.map((asset) => (
                                <li key={asset.uuid}>
                                    <Chips
                                        onClick={async () => {
                                            if (asset.url) return window.open(asset.url);
                                            const a = await asyncAssetFetch(asset.uuid);
                                            window.open(a.url);
                                        }}
                                    >
                                        <Icon icon="paper_clip" stroke="white" />
                                        {asset.name}
                                    </Chips>
                                </li>
                            ))}
                        </ul>
                    )}
                    {comment.tags && comment.tags.length > 0 && (
                        <ul className={styles.chips}>
                            {comment.tags.map((tag) => (
                                <li key={tag}>
                                    <Chips>
                                        <Icon icon="label" stroke="white" />
                                        {tag}
                                    </Chips>
                                </li>
                            ))}
                        </ul>
                    )}
                    {children}
                </>
            ) : (
                <FormComment
                    noteUuid={noteUuid}
                    comment={comment}
                    cancel={() => setEditing(null)}
                />
            )}
            <footer className={styles.footer}>
                <AvatarUser {...comment.user} size={AvatarSize.Small} />{' '}
                {getDisplayName(comment.user)} •{' '}
                {formatDate(comment.created, FORMAT_SHORT_DATETIME_TRANSLATED)}
                {hovered && comment.user.uuid === currentUserUuid && (
                    <Button
                        className={styles.editButton}
                        icon="pencil"
                        onClick={() => setEditing(comment.uuid)}
                    />
                )}
                {hovered &&
                    (comment.user.uuid === currentUserUuid ||
                        acl.files.modify(folderRole ?? null)) && (
                        <Button
                            className={styles.editButton}
                            icon="trash"
                            onClick={() => {
                                openModal(CONFIRM, {
                                    title: t('common.Delete'),
                                    message: t('common.Are you sure you want to delete this?'),
                                    displayLoader: true,
                                    onSubmit: async () => {
                                        if (isFirstNoteComment) {
                                            await noteDelete(noteUuid);
                                        } else {
                                            await commentDelete(noteUuid, comment.uuid);
                                        }
                                        closeModal();
                                    }
                                });
                            }}
                        />
                    )}
            </footer>
        </div>
    );
}
