import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';

import LineBreakString from '$components/base/LineBreakString/LineBreakString';
import { useTranslate } from '$hooks/useTranslate';
import { fileUpdate } from '$redux/content/file/fileActions';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';

type FileInfoDescriptionProps = {
    file: {
        uuid: string;
        version: number;
        description?: string | null;
    };
    label?: string;
    readOnly?: boolean;
};

export function FileInfoDescription({ file, label, readOnly }: FileInfoDescriptionProps) {
    const dispatch = useDispatch();

    const { t } = useTranslate();

    const [description, setDescription] = React.useState(file.description ?? undefined);

    if (readOnly) {
        return (
            <>
                <h3>{label}</h3>
                {file.description ? (
                    <LineBreakString string={file.description} />
                ) : (
                    <p>{t('folder.No description')}</p>
                )}
            </>
        );
    }

    return (
        <FormField
            type={'textarea'}
            label={label}
            id={'file-info-description'}
            value={description}
            onFocus={() => dispatch(toggleKeyboardListeners(false))}
            onBlur={(e: SyntheticEvent<HTMLTextAreaElement, FocusEvent>) => {
                const target = e.target as HTMLTextAreaElement;
                setDescription(target.value);
                dispatch(
                    fileUpdate(file.uuid, file.version, {
                        description: target.value
                    })
                );
                dispatch(toggleKeyboardListeners(true));
            }}
        />
    );
}
