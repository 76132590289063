import styles from './SidePanelNotesHeader.module.css';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React from 'react';
import { Team } from '@he-novation/config/types/team.types';
import { AbsoluteContentButton } from '@he-novation/design-system/components/buttons/AbsoluteContentButton/AbsoluteContentButton';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';

export type TeamFilterProps = {
    teams: Team[];
    teamFilters: string[];
    setTeamFilters: (teamFilters: string[]) => void;
};
export function TeamFilter({ teams, teamFilters, setTeamFilters }: TeamFilterProps) {
    if (!teams.length) return null;
    return (
        <AbsoluteContentButton
            tagName={Button}
            icon={[
                {
                    name: 'users',
                    stroke: 'white'
                }
            ]}
            direction={[DirectionX.RightInner, DirectionY.Bottom]}
            contentClassName={formStyles.dark}
            content={
                <div className={styles.menu} style={{ width: 200 }}>
                    {teams.map((t) => (
                        <FormField
                            key={t.uuid}
                            id={`task-status-filter-${t.uuid}`}
                            value={t.uuid}
                            label={t.name}
                            type="checkbox"
                            onChange={(e) => {
                                if (e.currentTarget.checked)
                                    setTeamFilters([...teamFilters, t.uuid]);
                                else setTeamFilters(teamFilters.filter((team) => team !== t.uuid));
                            }}
                            checked={teamFilters.includes(t.uuid)}
                        />
                    ))}
                </div>
            }
        />
    );
}
