import React from 'react';
import { Asset } from '@he-novation/config/types/asset.types';
import { FORMAT_DDMMYYHHMMSS, formatDate } from '@he-novation/front-shared/utils/datetime';
import { bytesToSize } from '@he-novation/utils/bytes';

import { KeyValueList } from '$components/base/KeyValueList/KeyValueList';
import { useTranslate } from '$hooks/useTranslate';

type FileInfoGeneralProps = {
    file: {
        version: number;
        name: string;
        assets: Asset[];
        created: Date | null;
        updated: Date | null;
        metadataAle?: Record<string, any>;
    };
    className?: string;
};

export function FileInfoGeneral({ file, className }: FileInfoGeneralProps) {
    const { t, locale } = useTranslate();

    const sourceAsset = file.assets.find(
        (a) => a.quality === 'source' && a.version === file.version
    )!;

    return (
        <KeyValueList
            className={className}
            list={[
                { key: t('common.Name'), value: file.name },
                {
                    key: t('common.Creation date'),
                    value: file.created
                        ? formatDate(file.created, FORMAT_DDMMYYHHMMSS, locale)
                        : undefined
                },
                {
                    key: t('common.Last updated'),
                    value: file.updated
                        ? formatDate(file.updated, FORMAT_DDMMYYHHMMSS, locale)
                        : undefined
                },
                { key: t('folder.Mime-type'), value: sourceAsset?.mimeType },
                { key: t('common.Size'), value: bytesToSize(sourceAsset.size, locale) }
            ]}
        />
    );
}
