export const objectToFormData = (obj: any): FormData => {
    const formData = new FormData();
    if (obj) {
        for (const key in obj) {
            if (typeof obj[key] !== 'undefined') {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach((value) => {
                        formData.append(`${key}[]`, value);
                    });
                } else {
                    formData.append(key, obj[key]);
                }
            }
        }
    }
    return formData;
};
