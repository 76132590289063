import { eventPaths } from '@he-novation/config/paths/herawApiPaths';
import { HerawEvent } from '@he-novation/config/types/event.types';
import { EventCreateBody, EventUpdateBody } from '@he-novation/config/types/payloads/event.payload';
import { apiFetch } from './apiFetch';

export function asyncEventCreate(body: EventCreateBody): Promise<HerawEvent> {
    return apiFetch(eventPaths.root, {
        method: 'POST',
        body: body
    });
}

export async function asyncEventDelete(eventUuid: string): Promise<void> {
    await apiFetch(eventPaths.specific, {
        method: 'DELETE',
        params: {
            eventUuid
        }
    });
}

export async function asyncEventUpdate(eventUuid: string, body: EventUpdateBody): Promise<void> {
    return await apiFetch(eventPaths.specific, {
        method: 'PUT',
        body,
        params: { eventUuid }
    });
}
export function asyncEventCreateDeletedDay(eventUuid: string, date: Date): Promise<HerawEvent> {
    return apiFetch(eventPaths.date, {
        method: 'POST',
        params: {
            eventUuid
        },
        body: {
            date: date.toISOString()
        }
    });
}
export async function asyncEventUserDelete(eventUuid: string): Promise<void> {
    await apiFetch(eventPaths.user, {
        method: 'DELETE',
        params: { eventUuid }
    });
}

export async function asyncEventSendInvitationEmail(eventUuid: string): Promise<void> {
    await apiFetch(eventPaths.email, {
        method: 'POST',
        params: {
            eventUuid
        }
    });
}

export async function asyncEventsFetch(
    start?: Date,
    end?: Date,
    projectUuid?: string
): Promise<HerawEvent[]> {
    return await apiFetch(eventPaths.root, {
        query: {
            start: start?.toISOString(),
            end: end?.toISOString(),
            projectUuid
        }
    }).then((events) => events.map((e: HerawEvent) => mapEvent(e)));
}

export function mapEvent(event: HerawEvent): HerawEvent {
    const startDate = new Date(event.startDate);

    const endDate = new Date(event.endDate);

    event.reminders =
        event.reminders?.map(({ datetime, uuid }) => ({
            uuid,
            datetime: new Date(datetime)
        })) || [];

    if (event.deletedDays) {
        event.deletedDays = event.deletedDays.map((date) => new Date(date));
    }

    return { ...event, startDate, endDate };
}

export async function asyncEventReminderCreate(
    eventUuid: string,
    datetime: string
): Promise<{ insertUuid: string }> {
    return await apiFetch(eventPaths.reminders, {
        method: 'POST',
        params: {
            eventUuid
        },
        body: JSON.stringify({
            datetime
        })
    });
}

export async function asyncEventReminderDelete(
    eventUuid: string,
    reminderUuid: string
): Promise<void> {
    await apiFetch(eventPaths.reminderSpecific, {
        method: 'DELETE',
        params: {
            eventUuid,
            reminderUuid
        }
    });
}
