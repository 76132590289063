import styles from './ContactPicker.module.scss';
import React, { useCallback, useState } from 'react';
import { Contact, ContactType, GroupWithContacts } from '@he-novation/config/types/contact.types';
import { FieldComponentProps } from 'react-modular-forms';
import { FormField } from '../../FormField';
import { Icon } from '../../../../graphics/Icon/Icon';
import { ReactSelectLabel } from '../ReactSelectLabel/ReactSelectLabel';
import { ContactOption } from './ContactOption';
import { EmailChipsList } from '../../../../lists/EmailChipsList/EmailChipsList';
import { ContactSchema } from '@he-novation/config/types/payloads/contact.payload';
import { Loader, LoaderSize } from '../../../../widgets/Loader/Loader';

export type SelectedContact =
    | ContactType
    | { email: string; userUuid?: string; workspaces: { uuid: string; name: string }[] };

export type SelectedContactsListProps = {
    selected: SelectedContact[];
    onUpdate: (contacts: SelectedContact[]) => void;
};

export type ContactPickerProps = FieldComponentProps & {
    contacts: ContactType[];
    contactGroups?: GroupWithContacts[];
    createContact?: (payload: ContactSchema) => Promise<Contact>;
    onCreateFilter?: (newContact: SelectedContact) => boolean;
    ListComponent?: React.ComponentType<SelectedContactsListProps>;
};

export function ContactPicker({
    formId,
    name,
    createContact,
    contacts,
    contactGroups = [],
    onChange,
    onCreateFilter = (_: SelectedContact) => true,
    ListComponent = EmailChipsList
}: ContactPickerProps) {
    const [selectedContacts, setSelectedContacts] = useState<SelectedContact[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const options = contactsToOptions(contacts, selectedContacts);

    const groups = contactGroups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(
            (group) =>
                ({
                    label: (
                        <ReactSelectLabel size="little">
                            <Icon icon="users" />
                            {group.name}
                        </ReactSelectLabel>
                    ),
                    searchValue: group.name,
                    value: group.uuid,
                    items: contactsToOptions(group.contacts, selectedContacts)
                } as ContactGroupOption)
        );

    const updateContacts = useCallback(
        (newSelectedContacts: SelectedContact[]) => {
            if (selectedContacts !== newSelectedContacts && onChange) {
                onChange(newSelectedContacts);
            }
            setSelectedContacts(newSelectedContacts);
        },
        [onChange, selectedContacts]
    );

    const components = {
        ClearIndicator: (): null => null,
        MultiValue: (_: any): null => null
    };

    if (isLoading) {
        components['ValueContainer'] = () => (
            <Loader className={styles.loader} size={LoaderSize.Small} />
        );
    }

    return (
        <FormField
            formId={formId}
            name={name}
            id={`${formId}-${name}-select`}
            type="react-select"
            isMulti
            menuClassName="is-contact-picker"
            hideSelectedOptions
            isSearchable
            creatable={!!createContact}
            disabled={isLoading}
            onChange={(e, emails: string[]) => {
                const newSelectedContacts = [
                    ...selectedContacts,
                    ...emails
                        .map((email) => contacts.find((c) => c.email === email))
                        .filter((c) => c !== undefined)
                ];
                updateContacts(newSelectedContacts);
            }}
            onCreateOption={async (value: string) => {
                if (!createContact) return;

                const newContact = { email: value, workspaces: [] };

                if (onCreateFilter(newContact)) {
                    setIsLoading(true);
                    const createdContact = await createContact(newContact);
                    const newSelectedContacts = [...selectedContacts, createdContact];
                    updateContacts(newSelectedContacts);
                    setIsLoading(false);
                }
            }}
            coerceType={'array'}
            value={selectedContacts.map((c) => c.email)}
            filterBy={'searchValue'}
            components={components}
            options={options}
            groups={groups}
            after={<ListComponent selected={selectedContacts} onUpdate={updateContacts} />}
        />
    );
}

type ContactGroupOption = ContactOption & {
    items: ContactOption[];
};

type ContactOption = {
    searchValue: string;
    label: React.ReactNode;
    value: string;
};

function contactsToOptions(
    contacts: ContactType[],
    selectedContacts: Partial<ContactType>[]
): ContactOption[] {
    return contacts
        .filter((c) => selectedContacts.find((sc) => sc.email === c.email) === undefined)
        .sort((a, b) => a.email.localeCompare(b.email))
        .map((contact) => ({
            searchValue: contact.email,
            label: <ContactOption contact={contact} key={`contact-option-${contact.email}`} />,
            value: contact.email
        }));
}
