export enum Locale {
    DE = 'de',
    EN = 'en',
    FR = 'fr'
}

export enum IconValue {
    User = 'user'
}

export const iconValueArray = Object.entries(IconValue).map(([_key, value]) => value);
