import {
    castNotePaths,
    notePaths,
    publicApiV1Paths
} from '@he-novation/config/paths/herawApiPaths';
import type { Comment } from '@he-novation/config/types/note.types';
import { Note } from '@he-novation/config/types/note.types';
import {
    CommentCreateBody,
    CommentEditBody,
    CommentSpecificParams,
    NoteAttachmentCreateBody,
    NoteAttachmentParams,
    NoteCreateBody
} from '@he-novation/config/types/payloads/note.payload';
import { dateAsUTCDate, setLatestHour } from '@he-novation/utils/datetime';
import { apiFetch, fetchAsFormData } from './apiFetch';

export const deleteNote = (noteUuid: string) =>
    apiFetch(notePaths.single, {
        params: { noteUuid },
        method: 'DELETE'
    });

export const fetchNotes = ({ uuid, version }): Promise<Record<string, Note>> =>
    apiFetch(notePaths.file, {
        params: { fileUuid: uuid, fileVersion: version },
        method: 'GET'
    });

export const fetchCastNotes = ({
    castUid,
    castFileUuid,
    fileVersion
}: {
    castUid: string;
    castFileUuid: string;
    fileVersion: number;
}): Promise<Record<string, Note>> =>
    apiFetch(castNotePaths.root, {
        params: {
            castUid,
            castFileUuid,
            fileVersion
        }
    });

export const asyncNoteCreate = (
    {
        assignees,
        content,
        draft,
        estimatedEndDate,
        fileUuid,
        fileVersion,
        castFileUuid,
        isTask,
        metadata,
        notify,
        teamUuid,
        type,
        imageData,
        postedAs,
        recaptcha,
        tags
    }: NoteCreateBody,
    isCastFile?: boolean,
    password?: string
) => {
    if (estimatedEndDate)
        estimatedEndDate = setLatestHour(dateAsUTCDate(estimatedEndDate as Date), true);
    return apiFetch(isCastFile ? castNotePaths.single : notePaths.root, {
        method: 'POST',
        query: { p: password },
        body: {
            assignees,
            content,
            draft,
            estimatedEndDate,
            fileUuid,
            fileVersion,
            castFileUuid,
            isTask,
            metadata: JSON.stringify(metadata),
            notify,
            teamUuid,
            type,
            imageData,
            postedAs,
            recaptcha,
            tags
        }
    });
};

export const asyncCommentCreate = (
    noteUuid: string,
    { content, draft, castFileUuid, notify, postedAs }: CommentCreateBody,
    password?: string
): Promise<Comment> =>
    apiFetch(castFileUuid ? castNotePaths.comment : notePaths.comment, {
        method: 'POST',
        params: { noteUuid },
        query: { p: password },
        body: {
            content,
            draft,
            castFileUuid,
            notify,
            postedAs
        }
    });

export const asyncCommentEdit = (
    noteUuid: string,
    commentUuid: string,
    { content, draft, tags }: CommentEditBody
): Promise<Omit<Comment, 'note' | 'file'>> =>
    apiFetch(notePaths.commentSingle, {
        method: 'PUT',
        params: { noteUuid, commentUuid } satisfies CommentSpecificParams,
        body: {
            content,
            draft,
            tags
        } satisfies CommentEditBody
    });

export function asyncCommentDelete(noteUuid: string, commentUuid: string) {
    return apiFetch(notePaths.commentSingle, {
        method: 'DELETE',
        params: { noteUuid, commentUuid }
    });
}

export const asyncUploadAttachments = ({
    noteUuid,
    commentUuid,
    attachments,
    castFileUuid
}: {
    noteUuid: string;
    commentUuid: string;
    attachments: File[];
    castFileUuid?: string;
}) =>
    fetchAsFormData(notePaths.commentAttachment, {
        method: 'POST',
        params: { noteUuid, commentUuid } satisfies NoteAttachmentParams,
        body: {
            files: attachments,
            castFileUuid
        } satisfies NoteAttachmentCreateBody
    });

export const asyncOpenApiNotesFetch = (apiKey: string, fileUuid: string, fileVersion: number) =>
    apiFetch(publicApiV1Paths.note.multiple, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${apiKey}`
        },
        params: { fileUuid, fileVersion }
    });
