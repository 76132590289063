import { ProjectsState } from '@he-novation/config/types/project.types';
import { pick, pipe, property } from 'lodash/fp';
import { CONTENT } from '../contentActions';
import { PROJECTS } from './projectsActions';

import { ReduxState } from '$redux/store';

const stateSelector: (state: ReduxState) => ProjectsState = pipe(
    property(CONTENT),
    property(PROJECTS)
);

export const currentProjectSelector = () =>
    pipe(stateSelector, property('currentProject'), (currentProject) => {
        return { currentProject };
    });

export const currentProjectUuidSelector = pipe(
    stateSelector,
    property('currentProject.uuid'),
    (currentProjectUuid) => ({ currentProjectUuid })
);

export const projectOwnerSelector = pipe(
    currentProjectSelector(),
    property('currentProject.user'),
    (projectOwner) => ({ projectOwner })
);

export const selectedTeamSelector = pipe(
    stateSelector,
    property('currentProject'),
    pick('selectedTeam')
);

export const userTeamSelector = pipe(
    stateSelector,
    property('currentProject.team_uuid'),
    (team_uuid) => ({ userTeam: team_uuid })
);
export const defaultCastStyleSelector = pipe(
    stateSelector,
    property('currentProject.defaultCastStyle'),
    (defaultCastStyle) => ({
        defaultCastStyle
    })
);

export const planningTasksSelector = pipe(stateSelector, pick('planningTasks'));
