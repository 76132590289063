import styles from './CheckboxDropdown.module.scss';
import React, { useState } from 'react';
import cn from 'classnames';
import { Direction, DirectionX, DirectionY } from '../../../utils/getAbsolutePosition';
import type { Checkbox } from '../../../types';
import { Icon, IconProp } from '../../graphics/Icon/Icon';
import { Button } from '../../buttons/Button/Button';
import { AbsoluteContentButton } from '../../buttons/AbsoluteContentButton/AbsoluteContentButton';
import { CheckboxSearchableList } from '../CheckboxList/CheckboxSearchableList';

type CheckboxDropdownProps = {
    checkboxes: Checkbox[];
    formId?: string;
    label?: React.ReactNode | ((checked: Checkbox[]) => React.ReactNode);
    icon?: IconProp;
    onChange?: (checked: Checkbox[]) => void;
    className?: string;
    dropdownClassName?: string;
    checkboxClassName?: string;
    direction?: Direction;
    search?: {
        value?: string | null;
        setValue: (s: string) => void;
        reset: (elementToFocus: string) => void;
    };
    hasSelectAllButton?: boolean;
};

export function CheckboxDropdown({
    direction = [DirectionX.Center, DirectionY.Bottom],
    className,
    dropdownClassName,
    icon,
    label,
    onChange,
    ...rest
}: CheckboxDropdownProps) {
    const [checked, setChecked] = useState<Checkbox[]>([]);

    let chevronDirection: string;
    if (direction[1] === DirectionY.Top) {
        chevronDirection = 'top';
    } else if (direction[1] === DirectionY.Bottom) {
        chevronDirection = 'bottom';
    } else if (direction[0].startsWith('left')) {
        chevronDirection = 'left';
    } else {
        chevronDirection = 'right';
    }

    const chevronIcon = (
        <Icon className={cn(styles.chevron, chevronDirection)} icon="chevron" stroke="white" />
    );

    return (
        <AbsoluteContentButton
            className={cn(styles.checkboxDropdown, 'c-checkbox-dropdown', className)}
            tagName={Button}
            closeOnClickOutside={true}
            triggersOnClick={true}
            triggersOnHover={false}
            direction={direction}
            content={
                <CheckboxSearchableList
                    {...rest}
                    className={cn(styles.dropdown, 'c-checkbox-dropdown-list', dropdownClassName)}
                    onChange={(checked) => {
                        setChecked(checked);
                        onChange?.(checked);
                    }}
                />
            }
        >
            {chevronDirection === 'left' && chevronIcon}
            {icon && <Icon icon={icon} />}
            {typeof label === 'function' ? label(checked) : label}
            {chevronDirection !== 'left' && chevronIcon}
        </AbsoluteContentButton>
    );
}
