import { useEffect, useState } from 'react';
import { castLink, projectCastLink } from '@he-novation/config/paths/herawFrontUris';
import { PASSWORD_FORM } from '@he-novation/config/paths/modals.constants';
import type { PublicCastColors } from '@he-novation/config/types/cast.types';
import { WSCastEventFolderCreated } from '@he-novation/config/types/websockets/cast.ws.types';
import { colorAverage } from '@he-novation/config/utils/colors';
import {
    asyncCastFileDelete,
    asyncCastFolderDelete,
    asyncPrivateCastSelect,
    asyncPublicCastSelect
} from '@he-novation/front-shared/async/cast.async';
import {
    FrontCast,
    FrontCastFolderChild,
    FrontCastFolderItemFolder
} from '@he-novation/front-shared/types/cast.front-types';
import update from 'immutability-helper';
import { useAtom, useSetAtom } from 'jotai';

import { castAtom, castColorsAtom, castPasswordAtom, castUidAtom } from '$atoms/cast-atoms';
import { useModal } from '$hooks/useModal';
import { useSocketIO } from '$hooks/useSocketIO';

export function useCast() {
    const { openModal, closeModal } = useModal();

    const setCastUid = useSetAtom(castUidAtom);
    const [cast, setCast] = useAtom(castAtom);
    const [castPassword, setCastPassword] = useAtom(castPasswordAtom);
    const [castColors, setCastColors] = useAtom(castColorsAtom);

    const [expired, setExpired] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);

    return {
        cast,
        setCast,
        expired,
        notFound,
        colors: castColors || {
            itemBackgroundColor: 'rgba(0,0,0,0.5)',
            textColor: '#ffffff',
            textColorFaded: 'rgba(255,255,255,0.5)',
            background: 'var(--color-bg-2)',
            backgroundGradient: null,
            logo: null
        },
        fetchPrivateCast(castUid: string, castFolderUuid?: string, projectUuid?: string) {
            setCastUid(castUid);
            asyncPrivateCastSelect(castUid, castFolderUuid, projectUuid)
                .then(setCast)
                .catch((e) => {
                    if (e.name === 'CAST_NOT_FOUND') setNotFound(true);
                });
        },

        fetchPublicCast(castUid: string, castFolderUuid?: string) {
            setCastUid(castUid);
            asyncPublicCastSelect(castUid, castFolderUuid, false, castPassword)
                .then((c) => {
                    setCast(c);
                    setCastColors(computeCastColors(c));
                })
                .catch((e) => {
                    if (e.name === 'PASSWORD') {
                        openModal(PASSWORD_FORM, {
                            onSubmit: (data) => {
                                setCastPassword(data.password);
                                return asyncPublicCastSelect(
                                    castUid,
                                    castFolderUuid,
                                    false,
                                    data.password
                                ).then((c) => {
                                    setCast(c);
                                    setCastColors(computeCastColors(c));
                                    closeModal();
                                });
                            }
                        });
                    }
                    if (e.name === 'EXPIRED') setExpired(true);
                    if (e.name === 'CAST_NOT_FOUND') setNotFound(true);
                });
        },

        deleteCastContent(castUid: string, items: FrontCastFolderChild[]) {
            const promises: Promise<void>[] = [];
            const toRestore: FrontCastFolderChild[] = [];
            for (const item of items) {
                if (item.type === 'file') {
                    promises.push(
                        asyncCastFileDelete(castUid, item.castFileUuid).catch(() => {
                            toRestore.push(item);
                        })
                    );
                } else {
                    promises.push(
                        asyncCastFolderDelete(castUid, item.uuid).catch(() => {
                            toRestore.push(item);
                        })
                    );
                }
            }
            setCast((c) => {
                if (!c) return c;
                return update(c, {
                    content: {
                        $set: c.content.filter(
                            ({ uuid }) => !items.find((item) => item.uuid === uuid)
                        )
                    }
                });
            });

            Promise.allSettled(promises).then(() => {
                if (toRestore.length) {
                    setCast((c) => {
                        if (!c) return c;
                        return update(c, {
                            content: {
                                $push: toRestore
                            }
                        });
                    });
                }
            });
        }
    };
}

export function computeCastColors(cast?: FrontCast): PublicCastColors {
    const castBackground = cast?.backgroundColor || '#0d111a';
    const avg = colorAverage(castBackground);

    const background = cast?.bgUserFile?.url
        ? `url('${cast.bgUserFile.url}') center  top / cover no-repeat`
        : 'var(--color-bg-2)';
    const colors =
        avg < 127
            ? {
                  itemBackgroundColor: 'rgba(0,0,0,0.5)',
                  textColor: '#ffffff',
                  textColorFaded: 'rgba(255,255,255,0.5)'
              }
            : {
                  itemBackgroundColor: 'rgba(255,255,255,0.5)',
                  textColor: '#000000',
                  textColorFaded: 'rgba(0,0,0,0.5)'
              };

    return {
        ...colors,
        background,
        backgroundGradient: cast?.backgroundColor
            ? `linear-gradient(to top, ${cast?.backgroundColor}, rgb(from ${cast.backgroundColor} r g b / 20%)`
            : null,
        logo: cast?.logoUserFile?.url
    };
}

export function useCastSockets(castUid: string, castFolderUuid?: string, projectUuid?: string) {
    const { subscribe, unsubscribe } = useSocketIO();
    const setCast = useSetAtom(castAtom);

    useEffect(() => {
        subscribe!({
            socket: 'casts',
            room: castFolderUuid || castUid,
            actions: {
                onFolderCreated(data: WSCastEventFolderCreated) {
                    setCast((c) => {
                        if (!c) return c;
                        const folder: FrontCastFolderItemFolder = {
                            name: data.name,
                            uuid: data.uuid,
                            user: data.user,
                            created: new Date(data.created),
                            href: projectUuid
                                ? projectCastLink(projectUuid, castUid, data.uuid)
                                : castLink(castUid, data.uuid),
                            type: 'folder'
                        };
                        return update(c, {
                            content: {
                                $push: [folder]
                            }
                        });
                    });
                }
            }
        });
        return () => {
            unsubscribe();
        };
    }, [castUid, castFolderUuid]);
}
