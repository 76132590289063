const combineSelectors =
    (...selectors) =>
    (state, props) =>
        selectors.reduce(
            (acc, selector) =>
                typeof selector !== 'function'
                    ? acc
                    : {
                          ...acc,
                          ...selector(state, props, acc)
                      },
            {}
        );

export default combineSelectors;
