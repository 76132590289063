import {
    itemsFetch as _itemsFetch,
    itemKitsFetch as _itemKitsFetch,
    itemsWithProjectItemsFetch as _itemsWithProjectItemsFetch
} from '@he-novation/front-shared/async/item.async';
import { asyncActionSuccess } from '$helpers/asyncAction';
import { WS_PREFIX_IN } from '$constants/webSocket.constants';
export const ITEMS = 'ITEMS';
export const ITEMS_FETCH = `${ITEMS}/FETCH`;
export const ITEMS_KITS_FETCH = `${ITEMS}/KITS_FETCH`;
export const ITEMS_WITH_PROJECT_ITEMS_FETCH = `${ITEMS}/ITEMS_WITH_PROJECT_ITEMS_FETCH`;

export const ITEM_SET_PICTURE_ASSET = `${ITEMS}/SET_PICTURE_ASSET`;
export const WS_ITEMS_CREATE = `${WS_PREFIX_IN}${ITEMS}/CREATE`;
export const WS_ITEMS_UPDATE = `${WS_PREFIX_IN}${ITEMS}/UPDATE`;
export const WS_ITEMS_DELETE = `${WS_PREFIX_IN}${ITEMS}/DELETE`;
export const WS_ITEM_KIT_CREATE = `${WS_PREFIX_IN}${ITEMS}/KIT_CREATE`;
export const WS_ITEM_KIT_UPDATE = `${WS_PREFIX_IN}${ITEMS}/KIT_UPDATE`;
export const WS_ITEM_KIT_DELETE = `${WS_PREFIX_IN}${ITEMS}/KIT_DELETE`;
export const WS_ITEM_KIT_ADD_ITEM = `${WS_PREFIX_IN}${ITEMS}/KIT_ADD_ITEM`;
export const WS_ITEM_KIT_REMOVE_ITEM = `${WS_PREFIX_IN}${ITEMS}/KIT_REMOVE_ITEM`;

export const itemsFetch = () => async (dispatch) => {
    dispatch({ type: ITEMS_FETCH });
    const items = await _itemsFetch();
    dispatch({ type: asyncActionSuccess(ITEMS_FETCH), items });
};

export const itemKitsFetch = () => async (dispatch) => {
    dispatch({ type: ITEMS_KITS_FETCH });
    const itemKits = await _itemKitsFetch();
    dispatch({ type: asyncActionSuccess(ITEMS_KITS_FETCH), itemKits });
};

export const itemsWithProjectItemsFetch = () => async (dispatch) => {
    dispatch({ type: ITEMS_WITH_PROJECT_ITEMS_FETCH });
    const items = await _itemsWithProjectItemsFetch();
    dispatch({ type: asyncActionSuccess(ITEMS_WITH_PROJECT_ITEMS_FETCH), items });
};

export const itemSetPictureAsset = ({ itemUuid, url, key }) => ({
    type: ITEM_SET_PICTURE_ASSET,
    itemUuid,
    url,
    key
});

export const itemsSocketActions = {
    sioItemCreate: (item) => ({ type: WS_ITEMS_CREATE, item }),
    sioItemUpdate: (item) => ({ type: WS_ITEMS_UPDATE, item }),
    sioItemDelete: ({ uuid }) => ({ type: WS_ITEMS_DELETE, uuid }),
    sioItemKitCreate: (kit) => ({ type: WS_ITEM_KIT_CREATE, kit }),
    sioItemKitUpdate: (kit) => ({ type: WS_ITEM_KIT_UPDATE, kit }),
    sioItemKitDelete: ({ uuid }) => ({ type: WS_ITEM_KIT_DELETE, uuid }),
    sioItemKitAddItem: (data) => ({ type: WS_ITEM_KIT_ADD_ITEM, data }),
    sioItemKitRemoveItem: (data) => ({ type: WS_ITEM_KIT_REMOVE_ITEM, data })
};
