import React from 'react';

import { KeyValueList } from '$components/base/KeyValueList/KeyValueList';

type FileInfoMetadataAleProps = {
    metadataAle: Record<string, any>;
    className?: string;
};

export function FileInfoMetadataAle({ metadataAle, className }: FileInfoMetadataAleProps) {
    return (
        <KeyValueList
            className={className}
            list={Object.keys(metadataAle)
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .map((key) => ({
                    key,
                    value: metadataAle[key]
                }))}
        />
    );
}
