import React from 'react';

const ModalFormCast = React.lazy(() => import('./modals/ModalFormCast/ModalFormCast'));
const ModalCast = React.lazy(() => import('./modals/ModalCast/ModalCast'));
const ModalCastShare = React.lazy(() => import('./modals/ModalCastShare/ModalCastShare'));
const ModalCastFolderTrigger = React.lazy(
    () => import('./modals/ModalCastFolderTrigger/ModalCastFolderTrigger')
);
const ModalClientDomain = React.lazy(() => import('./modals/ModalClientDomain/ModalClientDomain'));
const ModalEntity = React.lazy(() => import('$components/modal/modals/ModalEntity/ModalEntity'));
const ModalConfirm = React.lazy(() => import('./modals/ModalConfirm/ModalConfirm'));
const ModalConfirmDelete = React.lazy(
    () => import('./modals/ModalConfirmDelete/ModalConfirmDelete')
);
const ModalContactForm = React.lazy(() => import('./modals/ModalContactForm/ModalContactForm'));
const ModalDeleteTeam = React.lazy(() => import('./modals/ModalDeleteTeam/ModalDeleteTeam'));
const ModalEvent = React.lazy(() => import('./modals/ModalEvent/ModalEvent'));
const ModalFeedback = React.lazy(() => import('./modals/ModalFeedback/ModalFeedback'));
const ModalFileAdd = React.lazy(() => import('./modals/ModalFileAdd'));
const ModalFileInfo = React.lazy(() => import('./modals/ModalFileInfo/ModalFileInfo'));
const ModalFileVersions = React.lazy(() => import('./modals/ModalFileVersions/ModalFileVersions'));
const ModalFileVersionsComparator = React.lazy(
    () => import('./modals/ModalFileVersions/ModalFileVersionsComparatorContainer')
);
const ModalFolderAdd = React.lazy(() => import('./modals/ModalFolderAdd/ModalFolderAdd'));
const ModalFolderConvertToProject = React.lazy(
    () => import('./modals/ModalFolderConvertToProject')
);
const ModalFolderDownload = React.lazy(
    () => import('./modals/ModalFolderDownload/ModalFolderDownload')
);
const ModalFolderMembers = React.lazy(
    () => import('./modals/ModalFolderMembers/ModalFolderMembers')
);
const ModalFolderShare = React.lazy(() => import('./modals/ModalFolderShare/ModalFolderShare'));
const ModalFolderTeams = React.lazy(() => import('./modals/ModalFolderTeams/ModalFolderTeams'));
const ModalFormClient = React.lazy(() => import('./modals/ModalFormClient'));

const ModalFormClientLabel = React.lazy(() => import('./modals/ModalFormClientLabel'));
const ModalFormClientUser = React.lazy(
    () => import('$components/modal/modals/ModalFormClientUser/ModalFormClientUser')
);
const ModalFormClientUserPassword = React.lazy(
    () => import('./modals/ModalFormClientUserPassword')
);
const ModalMigrateFolderAssets = React.lazy(() => import('./modals/ModalMigrateFolderAssets'));
const ModalProfilePicture = React.lazy(() => import('./modals/ModalProfilePicture'));
const ModalFormProject = React.lazy(() => import('./modals/ModalFormProject/ModalFormProject'));

const ModalGroupForm = React.lazy(() => import('./modals/ModalGroupForm/ModalGroupForm'));
const ModalImportContact = React.lazy(
    () => import('./modals/ModalImportContact/ModalImportContact')
);
const ModalImportContactError = React.lazy(
    () => import('./modals/ModalImportContactError/ModalImportContactError')
);
const ModalItem = React.lazy(() => import('./modals/ModalItem/ModalItem'));
const ModalItemBookingStats = React.lazy(
    () => import('./modals/ModalItemBookingStats/ModalItemBookingStats')
);
const ModalItemKit = React.lazy(() => import('./modals/ModalItemKit/ModalItemKit'));

const ModalNoteAttachments = React.lazy(
    () => import('$components/modal/modals/ModalAttachment/ModalNoteAttachments')
);

const ModalPassword = React.lazy(() => import('./modals/ModalPassword/ModalPassword'));
const ModalPasswordUpdate = React.lazy(
    () => import('./modals/ModalPasswordUpdate/ModalPasswordUpdate')
);

const ModalPluginConfiguration = React.lazy(
    () => import('./modals/ModalPluginConfiguration/ModalPluginConfiguration')
);

const ModalProjectMembers = React.lazy(
    () => import('./modals/ModalProjectMembers/ModalProjectMembers')
);
const ModalProjectNotifications = React.lazy(
    () => import('./modals/ModalProjectNotifications/ModalProjectNotifications')
);
const ModalProjectTeams = React.lazy(
    () =>
        import(
            '$components/modal/modals/ModalProjectTeamMembersInvitation/ModalProjectTeamMembersInvitation'
        )
);
const ModalProjectTaskForm = React.lazy(
    () => import('./modals/ModalProjectTaskForm/ModalProjectTaskForm')
);

const ModalRenameFile = React.lazy(() => import('./modals/ModalRenameFile/ModalRenameFile'));
const ModalRenameFolder = React.lazy(() => import('./modals/ModalRenameFolder/ModalRenameFolder'));
const ModalRenameTeam = React.lazy(() => import('./modals/ModalRenameTeam/ModalRenameTeam'));
const ModalReviewersList = React.lazy(
    () => import('./modals/ModalReviewersList/ModalReviewersList')
);
const ModalSubtitlesUpload = React.lazy(
    () => import('./modals/ModalSubtitlesUpload/ModalSubtitlesUpload')
);
const ModalSubtitlesBurn = React.lazy(
    () => import('./modals/ModalSubtitlesBurn/ModalSubtitlesBurn')
);
const ModalSubtitlesCreate = React.lazy(
    () => import('./modals/ModalSubtitlesCreate/ModalSubtitlesCreate')
);
const ModalSubtitlesGenerate = React.lazy(
    () => import('./modals/ModalSubtitlesGenerate/ModalSubtitlesGenerate')
);
const ModalTaskForm = React.lazy(() => import('./modals/ModalTaskForm/ModalTaskForm'));
const ModalTranscoderSpawn = React.lazy(
    () => import('./modals/ModalTranscoderSpawn/ModalTranscoderSpawn')
);
const ModalUploadManager = React.lazy(
    () => import('./modals/ModalUploadManager/ModalUploadManager')
);

const ModalUserProfile = React.lazy(() => import('./modals/ModalUserProfileForm/ModalUserProfile'));
const ModalUserTransfer = React.lazy(() => import('./modals/ModalUserTransfer/ModalUserTransfer'));
const ModalVersionAdd = React.lazy(() => import('./modals/ModalVersionAdd/ModalVersionAdd'));
const ModalRecurseSettings = React.lazy(
    () => import('./modals/ModalRecurseSettings/ModalRecurseSettings')
);
const ModalViews = React.lazy(() => import('./modals/ModalViews/ModalViewsContainer'));
const ModalWait = React.lazy(() => import('./modals/ModalWait/ModalWaitContainer'));

const ModalCopyPaste = React.lazy(() => import('./modals/ModalCopyPaste'));

const ModalTwoFactorAuthentication = React.lazy(
    () => import('./modals/ModalTwoFactorAuthentication/ModalTwoFactorAuthentication')
);

import {
    ADD_CONTACT,
    ADD_GROUP,
    ATTACHMENT_NOTE,
    CAST,
    CAST_FOLDER_TRIGGERS,
    CAST_FORM,
    CAST_FORM_FOLDER,
    CAST_SHARE,
    CLIENT,
    CLIENT_DOMAIN,
    CLIENT_LABEL,
    CLIENT_USER,
    CLIENT_USER_PASSWORD,
    CONFIRM,
    DELETE_CONFIRM,
    DELETE_TEAM,
    EDIT_CONTACT,
    EDIT_GROUP,
    ENTITY,
    EVENT,
    FEEDBACK,
    FILE_ADD,
    FILE_INFO,
    FILE_VERSIONS,
    FILE_VERSIONS_COMPARATOR,
    FOLDER_ADD,
    FOLDER_CONVERT_TO_PROJECT,
    FOLDER_DOWNLOAD,
    FOLDER_MEMBERS,
    FOLDER_SHARE,
    FOLDER_TEAMS,
    IMPORT_CONTACT,
    IMPORT_CONTACT_ERROR,
    ITEM,
    ITEM_BOOKING_STATS,
    ITEM_KIT,
    MIGRATE_FOLDER_ASSETS,
    PASSWORD_FORM,
    PASSWORD_UPDATE_FORM,
    PLUGIN_CONFIGURATION,
    PROJECT_FORM,
    PROJECT_MEMBERS,
    PROJECT_NOTIFICATIONS,
    PROJECT_TASK_FORM,
    PROJECT_TEAMS,
    RECURSIVE_SETTINGS,
    RENAME_FILE,
    RENAME_FOLDER,
    RENAME_TEAM,
    REVIEWERS_LIST,
    SUBTITLES,
    SUBTITLES_BURN,
    SUBTITLES_CREATE,
    SUBTITLES_GENERATE,
    TASK_FORM,
    TRANSCODER_SPAWN,
    TWO_FACTOR_AUTHENTICATION,
    UPLOAD_MANAGER,
    USER_PROFILE_FORM,
    USER_TRANSFER,
    VERSION_ADD,
    VIEWS,
    WAIT
} from '@he-novation/config/paths/modals.constants';
import { PROFILE_PICTURE } from '@he-novation/config/paths/modals.constants';

import { COPY_PASTE } from '$components/modal/modals/ModalCopyPaste';
import ModalFormCastFolder from '$components/modal/modals/ModalFormCastFolder';

export default {
    [ADD_CONTACT]: ModalContactForm,
    [ADD_GROUP]: ModalGroupForm,
    [ATTACHMENT_NOTE]: ModalNoteAttachments,
    [CAST]: ModalCast,
    [CAST_FOLDER_TRIGGERS]: ModalCastFolderTrigger,
    [CAST_FORM]: ModalFormCast,
    [CAST_FORM_FOLDER]: ModalFormCastFolder,
    [CAST_SHARE]: ModalCastShare,
    [CLIENT]: ModalFormClient,
    [CLIENT_DOMAIN]: ModalClientDomain,
    [CLIENT_LABEL]: ModalFormClientLabel,
    [CLIENT_USER]: ModalFormClientUser,
    [CLIENT_USER_PASSWORD]: ModalFormClientUserPassword,
    [CONFIRM]: ModalConfirm,
    [ENTITY]: ModalEntity,
    [COPY_PASTE]: ModalCopyPaste,
    [PROFILE_PICTURE]: ModalProfilePicture,
    [DELETE_CONFIRM]: ModalConfirmDelete,
    [DELETE_TEAM]: ModalDeleteTeam,
    [EDIT_CONTACT]: ModalContactForm,
    [EDIT_GROUP]: ModalGroupForm,
    [EVENT]: ModalEvent,
    [FEEDBACK]: ModalFeedback,
    [FILE_ADD]: ModalFileAdd,
    [FILE_INFO]: ModalFileInfo,
    [FILE_VERSIONS_COMPARATOR]: ModalFileVersionsComparator,
    [FILE_VERSIONS]: ModalFileVersions,
    [FOLDER_ADD]: ModalFolderAdd,
    [FOLDER_CONVERT_TO_PROJECT]: ModalFolderConvertToProject,
    [FOLDER_DOWNLOAD]: ModalFolderDownload,
    [FOLDER_MEMBERS]: ModalFolderMembers,
    [FOLDER_SHARE]: ModalFolderShare,
    [FOLDER_TEAMS]: ModalFolderTeams,
    [IMPORT_CONTACT_ERROR]: ModalImportContactError,
    [IMPORT_CONTACT]: ModalImportContact,
    [ITEM_KIT]: ModalItemKit,
    [ITEM]: ModalItem,
    [ITEM_BOOKING_STATS]: ModalItemBookingStats,
    [MIGRATE_FOLDER_ASSETS]: ModalMigrateFolderAssets,
    [PASSWORD_FORM]: ModalPassword,
    [PASSWORD_UPDATE_FORM]: ModalPasswordUpdate,
    [PLUGIN_CONFIGURATION]: ModalPluginConfiguration,
    [PROJECT_FORM]: ModalFormProject,
    [PROJECT_MEMBERS]: ModalProjectMembers,
    [PROJECT_NOTIFICATIONS]: ModalProjectNotifications,
    [PROJECT_TEAMS]: ModalProjectTeams,
    [PROJECT_TASK_FORM]: ModalProjectTaskForm,
    [RENAME_FILE]: ModalRenameFile,
    [RENAME_FOLDER]: ModalRenameFolder,
    [RENAME_TEAM]: ModalRenameTeam,
    [RECURSIVE_SETTINGS]: ModalRecurseSettings,
    [REVIEWERS_LIST]: ModalReviewersList,
    [SUBTITLES_BURN]: ModalSubtitlesBurn,
    [SUBTITLES_CREATE]: ModalSubtitlesCreate,
    [SUBTITLES_GENERATE]: ModalSubtitlesGenerate,
    [SUBTITLES]: ModalSubtitlesUpload,
    [TASK_FORM]: ModalTaskForm,
    [TRANSCODER_SPAWN]: ModalTranscoderSpawn,
    [TWO_FACTOR_AUTHENTICATION]: ModalTwoFactorAuthentication,
    [UPLOAD_MANAGER]: ModalUploadManager,
    [USER_PROFILE_FORM]: ModalUserProfile,
    [USER_TRANSFER]: ModalUserTransfer,
    [VERSION_ADD]: ModalVersionAdd,
    [VIEWS]: ModalViews,
    [WAIT]: ModalWait
};
