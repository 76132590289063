import { useEffect, useRef } from 'react';
import * as sockets from '@he-novation/config/utils/sockets/sockets.client';
import type { Action, Dispatch } from '@reduxjs/toolkit';

export const mapActionsObjectWithDispatch = (
    dispatch: Dispatch,
    actions: Record<string, (...args: unknown[]) => Action>
) =>
    Object.keys(actions).reduce((acc, key) => {
        acc[key] = (...args: unknown[]) => dispatch(actions[key](...args));
        return acc;
    }, {} as Record<string, (...args: unknown[]) => void>);

type SocketOptions = {
    socket: string;
    room: string;
    actions: Record<string, (...args: unknown[]) => void>;
};
export function useSocketIO(options?: SocketOptions) {
    const unsubscribe = useRef<(() => void)[]>([]);

    if (!options)
        return {
            unsubscribe: () => {
                unsubscribe.current.forEach((unsub) => unsub());
                unsubscribe.current = [];
            },
            subscribe: (options: SocketOptions) => {
                unsubscribe.current.push(
                    sockets[options.socket].clientActionsInit(options.room, options.actions)
                );
            }
        };

    useEffect(() => {
        unsubscribe.current.push(
            sockets[options.socket].clientActionsInit(options.room, options.actions)
        );
        return () => {
            unsubscribe.current.forEach((unsub) => unsub());
            unsubscribe.current = [];
        };
    }, [options?.room, options?.socket]);

    return {
        unsubscribe: () => console.error('If options are passed unsubscribing is handled by hook')
    };
}
