import styles from './Loader.module.css';
import React, { CSSProperties } from 'react';
import { Theme } from '../../../enums';
import classNames from 'classnames';
import { __ } from '../../../utils/i18n';
import { colorText } from '../../../constants/constants.style';
import { useTranslate } from '../../../hooks/useTranslate';

export enum LoaderSize {
    Big = 'big',
    Medium = 'medium',
    Small = 'small'
}

type LoaderProps = {
    theme?: Theme;
    size?: LoaderSize;
    style?: CSSProperties;
    circleColor?: string;
    className?: string;
};

export const Loader: React.FC<LoaderProps> = ({
    size = LoaderSize.Big,
    className,
    style,
    circleColor
}) => {
    const { t } = useTranslate();
    return (
        <div className={classNames(styles.loader, 'c-loader', size, className)} style={style}>
            <div
                className={classNames(styles.spinner, 'c-loader-spinner')}
                style={{ color: circleColor || colorText }}
            >
                <span className="visually-hidden">{t('Loading')}</span>
            </div>
        </div>
    );
};
