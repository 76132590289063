import { UserPrefs, UserState } from '@he-novation/config/types/user.types';
import plugins from '@he-novation/config/utils/plugins';
import { compose, find, includes, pick, pipe, property } from 'lodash/fp';
import { camelCase, upperFirst } from 'lodash/fp';
import { CLIENT_PRIVATE_NAME } from '../config/constants';
import { USER } from './userActions';

import combineSelectors from '$helpers/combineSelectors';
import { clientPreferencesSelector } from '$redux/client/clientSelectors';
import { folderRightsSelector, isSharedSelector } from '$redux/content/folder/folderSelectors';
import { ReduxState } from '$redux/store';

const stateSelector: (state: ReduxState) => UserState = property(USER);

export const storageSelector = pipe(stateSelector, (state) => ({ storage: state.storage }));

export const localeSelector = pipe(stateSelector, ({ locale }) => locale);

export const userLicensesSelector = pipe(stateSelector, ({ licenses }) => licenses);

export const clientNameSelector = pipe(stateSelector, ({ clientName }) => ({ clientName }));
export const clientUuidSelector = pipe(stateSelector, ({ clientUuid }) => ({ clientUuid }));

export const currentUserSelector = pipe(stateSelector, (user) => ({ currentUser: user }));

export const currentUserUuidSelector = pipe(stateSelector, ({ uuid }) => ({
    currentUserUuid: uuid
}));
export const currentUserClientNameSelector = pipe(clientNameSelector, ({ clientName }) => ({
    currentUserClientName: clientName
}));
export const userUuidSelector = pipe(stateSelector, property('uuid'), (userUuid: string) => ({
    userUuid
}));

export const pluginsSelector = pipe(stateSelector, pick('plugins'));
export const pluginsEnabledSelector = pipe(stateSelector, pick('pluginsEnabled'));

export const hasPluginSelector = (plugin: string) =>
    pipe(stateSelector, property('plugins'), includes(plugin), (hasPlugin: boolean) => ({
        [`hasPlugin${upperFirst(camelCase(plugin))}`]: hasPlugin
    }));

export const hasAnExportPluginSelector = pipe(
    stateSelector,
    property('plugins'),
    (_plugins: string[]) => {
        const exportPlugins = plugins.getTypes.export();
        for (let i = 0, iLength = exportPlugins.length; i < iLength; i++) {
            if (_plugins.includes(exportPlugins[i])) {
                return true;
            }
        }
        return false;
    },
    (hasAnExportPlugin) => ({ hasAnExportPlugin })
);

export const isPrivateSelector = compose(
    ({ clientName }) => ({
        isPrivate: clientName === CLIENT_PRIVATE_NAME
    }),
    stateSelector
);

export const pluginEnabledSelectorFromProperties = (
    type: string,
    propertyAccessor: string,
    propertyValue: string | number
) =>
    pipe(
        stateSelector,
        property('pluginsEnabled'),
        find(
            (p: Record<string, string | number>) =>
                p.type === type && property(propertyAccessor)(p) === propertyValue
        )
    );

export const preferencesSelector: (state: ReduxState) => { preferences: UserPrefs } = pipe(
    stateSelector,
    property('preferences'),
    (preferences?: UserPrefs) => {
        if (!preferences) preferences = { ui: {} };
        if (!preferences.ui) preferences.ui = {};
        return { preferences };
    }
);

export const preferencesSortersAndGroupersSelector = (preferencesGrouperAndSorterName: string) =>
    pipe(
        stateSelector,
        property(`preferences.ui.sortersAndGroupers.${preferencesGrouperAndSorterName}`),
        (preferencesSortersAndGroupers) => ({
            preferencesSortersAndGroupers: preferencesSortersAndGroupers || {}
        })
    );

export const rightsPreferencesSelector = pipe(
    stateSelector,
    property('preferences.rights'),
    (rightsPreferences = {}) => ({ rightsPreferences })
);

export const uiPreferencesSelector = pipe(
    stateSelector,
    property('preferences.ui'),
    (uiPreferences) => ({ uiPreferences })
);

export const totpEnabledSelector = pipe(stateSelector, property('totp_enabled'), (totpEnabled) => ({
    totpEnabled: !!totpEnabled
}));

export const requestAndAccessTokenSelector = pipe(
    stateSelector,
    pick(['accessToken', 'requestToken'])
);

export const clientRightsSelector = pipe(stateSelector, pick('clientRights'));

export const canCreateProjectsSelector = pipe(
    combineSelectors(clientRightsSelector, pluginsSelector, stateSelector),
    ({ storage, storageExpires, clientRights, plugins: _plugins }) => {
        const hasStorage =
            (storage && storage.total !== 0 && !storageExpires) ||
            new Date().getTime() < new Date(storageExpires).getTime();
        const canCreateClientProject =
            clientRights && clientRights.projectAdd && _plugins.indexOf('projects') > -1;
        return {
            canCreateProjects: hasStorage && canCreateClientProject
        };
    }
);

export const hasClientRightSelector = (right: string) =>
    pipe(stateSelector, property('clientRights'), property(right), (hasClientRight) => ({
        [`hasClientRight${upperFirst(right)}`]: hasClientRight
    }));

export const canCreateEntitySelector = pipe(
    hasClientRightSelector('companyAdd'),
    ({ hasClientRightCompanyAdd }) => ({
        canCreateEntity: hasClientRightCompanyAdd
    })
);

export const canCreateItemSelector = pipe(
    combineSelectors(hasPluginSelector('items'), hasClientRightSelector('itemAdd')),
    ({ hasPluginItems, hasClientRightItemAdd }) => ({
        canCreateItem: hasPluginItems && hasClientRightItemAdd
    })
);

export const canDisplayCompaniesSelector = pipe(
    clientNameSelector,
    ({ clientName }) => clientName !== 'private',
    (canDisplayCompanies: boolean) => ({ canDisplayCompanies })
);

export type UserInfos = {
    city?: string;
    clientName: string;
    clientUuid: string;
    country?: string;
    created: Date;
    email: string;
    firm?: string;
    firstname?: string;
    isSuperAdmin?: boolean;
    lastname?: string;
    passwordReset: boolean;
    phone?: string;
    picture?: string;
    role?: string;
    uuid: string;
};

export const userInfosSelector = pipe(
    stateSelector,
    pick([
        'city',
        'clientName',
        'clientUuid',
        'country',
        'created',
        'email',
        'firm',
        'firstname',
        'isSuperAdmin',
        'lastname',
        'passwordReset',
        'phone',
        'picture',
        'role',
        'uuid'
    ]),
    (userInfos: UserInfos) => ({ userInfos })
);

export const emailSelector = pipe(stateSelector, pick('email'));

export const userInfosAsInfosSelector = pipe(userInfosSelector, ({ userInfos }) => ({
    infos: userInfos
}));

export const userProjectsSelector = pipe(stateSelector, property('projects'), (userProjects) => ({
    userProjects
}));

export const userEventsSelector = pipe(stateSelector, property('events'), (userEvents) => ({
    userEvents
}));

export const isClientSelector = pipe(stateSelector, property('isClient'));
export const userClientRoleSelector = pipe(stateSelector, property('clientRole'));

export const rootFolderUuidSelector = pipe(stateSelector, pick('rootFolderUuid'));

export const commentRightsSelector = pipe(
    combineSelectors(isSharedSelector, clientPreferencesSelector, folderRightsSelector),
    ({ isShared, folderRights, clientPreferences }) =>
        isShared ? folderRights : clientPreferences?.rights,
    (commentRights) => ({ commentRights })
);
