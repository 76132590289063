import { asyncActionSuccess, asyncActionError } from '../../../helpers/asyncAction';

import { FETCH_USER_TEAMS } from './teamsActions';

export const teamsInitialState = {
    teams: [],
    currentTeam: {}
};

export default function teamsReducer(state = teamsInitialState, action = {}) {
    switch (action.type) {
        case asyncActionSuccess(FETCH_USER_TEAMS):
            return {
                ...state,
                teams: action.response.result
            };
        case asyncActionError(FETCH_USER_TEAMS):
            return {
                ...state,
                teams: []
            };

        default:
            return state;
    }
}
