import type { Note } from '../types/note.types';
export const applyNoteFilters = (
    filters: { tags: string[]; members: string[]; types: string[]; casts?: string[] },
    notes: Note[],
    displayCastNotes?: boolean
): Note[] => {
    return !filters || !Object.keys(filters).length
        ? notes
        : notes
              .map((n) => ({
                  ...n,
                  comments: n.comments.filter(({ user, assets }) => {
                      if (filters.members && filters.members.length) {
                          if (!user?.uuid || !filters.members.includes(user.uuid)) return false;
                      }

                      if (filters.types && filters.types.length) {
                          if (filters.types.includes('attachments') && !assets.length) return false;
                      }
                      return true;
                  })
              }))
              .filter(({ comments }) => comments.length)
              .filter(({ cast }) => {
                  if (displayCastNotes || (!cast && !filters.casts?.length)) return true;
                  return cast && filters.casts?.length && filters.casts.includes(cast.uuid);
              })
              .filter(
                  ({ task }) =>
                      !filters.types ||
                      !filters.types.length ||
                      !filters.types.includes('task') ||
                      task
              );
};

export function noteTypeToI18n(type: string) {
    switch (type) {
        case 'sequence':
            return 'player.Sequence';
        case 'global':
            return 'player.Global';
        case 'areaselection':
        default:
            return 'player.Zone';
    }
}
