import { pipe, property } from 'lodash/fp';

import { i18nReducerName } from '$redux/i18n/i18nReducer';
import type { I18nState } from '$redux/i18n/i18nTypes';
import type { ReduxState } from '$redux/store';

export const i18nStateSelector: (state: ReduxState) => I18nState = property(i18nReducerName);

export const i18nLanguageAndCountryCodesSelector = pipe(
    i18nStateSelector,
    property('languagesAndCountryCodes')
);
