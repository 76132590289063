/* eslint-disable */
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as JotaiProvider } from 'jotai';

export default (store) => (Component) => (props) =>
    (
        <ReduxProvider store={store}>
            <JotaiProvider>
                <Component {...props} />
            </JotaiProvider>
        </ReduxProvider>
    );
