import './UserPicker.scss';
import React, { useEffect, useState } from 'react';
import { FieldComponentProps } from 'react-modular-forms';
import { FormField } from '../../FormField';
import { AvatarSize, AvatarUser } from '../../../../avatars/Avatar/AvatarUser';
import { Group, Option } from '../ReactSelect/ReactSelect';
import { ReactSelectLabel } from '../ReactSelectLabel/ReactSelectLabel';
import { Icon } from '../../../../graphics/Icon/Icon';
import { userToUuid } from '../../../../../utils/userToUuid';
import { Tooltip } from '../../../../widgets/Tooltip/Tooltip';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { getDisplayName } from '@he-novation/utils/user';

type User = {
    email: string;
    firstname?: string;
    lastname?: string;
    picture?: string;
    labels?: { name: string }[];
} & (
    | {
          user_uuid: string;
      }
    | {
          userUuid: string;
      }
    | { uuid: string }
);

type UserGroup = {
    uuid: string;
    name: string;
    users: User[];
};
type AvatarCircleFunction = (user: User) => { color: string; tooltip: string } | undefined;

type UserPickerProps = FieldComponentProps & {
    users: User[];
    userGroups?: UserGroup[];
    canRemove?: (user: User) => boolean;
    currentUserUuid?: string;
    getAvatarCircleColor?: AvatarCircleFunction;
    menuPlacement?: 'top' | 'auto';
    sortUsers?: (userA: User, userB: User) => number;
    renderList?: (props: { uuids: string[] }) => React.ReactNode;
};

export function UserPicker({
    formId,
    name,
    theme,
    users,
    userGroups = [],
    canRemove,
    currentUserUuid,
    sortUsers,
    getAvatarCircleColor,
    menuIsOpen,
    menuPlacement,
    placeholder,
    value: _value,
    renderList,
    disabled,
    onChange
}: UserPickerProps) {
    const { options, groups, maxDisplayName } = getOptions(
        users,
        userGroups,
        currentUserUuid,
        sortUsers,
        getAvatarCircleColor
    );

    const [value, setValue] = useState<string[]>();

    useEffect(() => {
        setValue(_value || []);
    }, [_value]);

    const maxWidth = maxDisplayName * 8 + 50;

    return (
        <FormField
            theme={theme}
            placeholder={placeholder}
            formId={formId}
            name={name}
            id={`${formId}-${name}-select`}
            type="react-select"
            isMulti
            menuIsOpen={menuIsOpen}
            menuClassName="is-user-picker"
            hideSelectedOptions
            isSearchable
            disabled={disabled}
            onChange={(e, v) => {
                setValue(v);
                onChange?.(e, v);
            }}
            coerceType={'array'}
            value={value}
            menuPlacement={menuPlacement}
            filterBy={'searchValue'}
            getMenuWidth={(w: any) => {
                return Math.max(maxWidth, w);
            }}
            components={{
                ClearIndicator: (): null => null,
                MultiValue: ({ data, removeProps, innerRef }: any): null => null
            }}
            options={options}
            groups={groups}
            after={
                renderList ? (
                    renderList({ uuids: value || [] })
                ) : (
                    <ul className={'deletables'}>
                        {value?.map((uuid) => {
                            const user = users.find((u) => userToUuid(u) === uuid);
                            if (!user) return null;
                            const isRemovable = !canRemove || canRemove(user);

                            return (
                                <li key={uuid}>
                                    <UserSelectLabel
                                        user={user}
                                        currentUserUuid={currentUserUuid}
                                        getAvatarCircle={getAvatarCircleColor}
                                        onAvatarClick={
                                            isRemovable
                                                ? () =>
                                                      setValue(
                                                          value.filter(
                                                              (uuid) => uuid !== userToUuid(user)
                                                          )
                                                      )
                                                : undefined
                                        }
                                    />
                                </li>
                            );
                        })}
                    </ul>
                )
            }
        />
    );
}

const getOptions = (
    users: User[],
    userGroups: UserGroup[],
    currentUserUuid?: string,
    sortUsers?: UserPickerProps['sortUsers'],
    avatarBullet?: AvatarCircleFunction
) => {
    let maxDisplayName = 0;
    const options = users
        .sort(
            sortUsers
                ? sortUsers
                : (a, b) => {
                      if (userToUuid(a) === currentUserUuid) return -1;
                      if (userToUuid(b) === currentUserUuid) return 1;

                      const displayNameA = getDisplayName(a, true);

                      if (displayNameA.length > maxDisplayName)
                          maxDisplayName = displayNameA.length;

                      return displayNameA.toLowerCase() < getDisplayName(b, true).toLowerCase()
                          ? -1
                          : 1;
                  }
        )
        .map((u) => userToOption(u, currentUserUuid, avatarBullet));

    const groups = userGroups
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((u) => userGroupToGroup(u, avatarBullet));

    return { options, groups, maxDisplayName };
};

const userGroupToGroup = (group: UserGroup, getAvatarCircle?: AvatarCircleFunction): Group => {
    return {
        label: (
            <ReactSelectLabel>
                <Icon icon="users" />
                {group.name}
            </ReactSelectLabel>
        ),
        searchValue: group.name,
        value: group.uuid,
        items: group.users.map((g) => userToOption(g, null, getAvatarCircle))
    };
};

const userToOption = (
    u: User,
    currentUserUuid?: string | null,
    getAvatarCircle?: AvatarCircleFunction
): Option => {
    const searchableName = getDisplayName(u, true);

    return {
        searchValue: searchableName + ' ' + (u.labels || []).map((l) => l.name).join(' '),
        label: (
            <UserSelectLabel
                user={u}
                currentUserUuid={currentUserUuid}
                getAvatarCircle={getAvatarCircle}
            />
        ),
        value: userToUuid(u)
    };
};

type UserSelectLabelProps = {
    user: User;
    currentUserUuid?: string | null;
    getAvatarCircle?: AvatarCircleFunction;
    onAvatarClick?: () => void;
};

function UserSelectLabel({
    user,
    currentUserUuid,
    getAvatarCircle,
    onAvatarClick
}: UserSelectLabelProps) {
    const circle = getAvatarCircle?.(user);

    return (
        <ReactSelectLabel>
            <AvatarUser
                size={AvatarSize.Small}
                style={{ outlineColor: circle?.color }}
                onClick={onAvatarClick}
                tooltip={!!circle}
                tooltipClassName={'user-picker-tooltip'}
                getTooltipContent={circle ? () => circle.tooltip : undefined}
                {...user}
            />
            <UserName user={user} currentUserUuid={currentUserUuid} />
        </ReactSelectLabel>
    );
}

type UserNameProps = { user: User; currentUserUuid?: string | null };

function UserName({ user, currentUserUuid }: UserNameProps) {
    const { t } = useTranslate();

    let userName: string | undefined;

    if (user.firstname && user.lastname) {
        userName = `${user.firstname} ${user.lastname}`;
    }

    if (userToUuid(user) === currentUserUuid) {
        userName = t('You');
    }

    if (userName) {
        return (
            <Tooltip
                className={'user-name'}
                tooltipClassName={'user-picker-tooltip'}
                content={user.email}
            >
                {userName}
            </Tooltip>
        );
    } else {
        return user.email;
    }
}
