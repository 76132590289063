import type { FieldComponentProps } from 'react-modular-forms';
import { config, registerType } from 'react-modular-forms';
import React, { RefObject } from 'react';
import { EditorWrapper } from '../Editor/Editor';
import { QuillOptions } from 'quill';
import {
    CheckboxDropdownFields,
    CheckboxDropdownFieldsProps
} from '../CheckboxDropdownFields/CheckboxDropdownFields';
import { UserPicker } from './components/UserPicker/UserPicker';
import { DatePicker } from './components/DatePicker/DatePicker';
import { ReactSelect } from './components/ReactSelect/ReactSelect';
import { ItemPicker } from './components/ItemPicker/ItemPicker';
import { RichNumber } from './components/RichNumber/RichNumber';
import { ReactInputMask } from './components/ReactInputMask/ReactInputMask';
import { ContactPicker } from './components/EmailPicker/ContactPicker';
import { GraphicPicker } from './components/GraphicPicker/GraphicPicker';
import { RadioToggle } from './components/RadioTab/RadioToggle';
import { TimeCode } from '../TimeCode/TimeCode';
import { FormRecaptcha } from './components/FormRecaptcha';
import { ColorPicker } from './components/ColorPicker/ColorPicker';

config.fieldClassName = 'form-field';
config.errorClassName = 'form-field-error';
config.sendEmptyStringsAs = undefined;

export const configureReactModularForms = () => {
    registerType('checkbox-dropdown', {
        Component: ({
            options,
            theme,
            formId,
            buttonLabel,
            direction,
            onChange,
            group,
            name,
            groupMin,
            value
        }: FieldComponentProps & CheckboxDropdownFieldsProps) => {
            return (
                <CheckboxDropdownFields
                    formId={formId}
                    name={name}
                    options={options}
                    theme={theme}
                    buttonLabel={buttonLabel}
                    direction={direction}
                    onChange={onChange}
                    group={group}
                    groupMin={groupMin}
                    value={value}
                />
            );
        },
        isStatic: true
    });

    registerType('date-picker', {
        Component: DatePicker,
        getValue: (ref) => {
            return ref.current;
        }
    });

    registerType('color-picker', {
        Component: ColorPicker,
        isStatic: true
    });

    registerType('contact-picker', {
        Component: ContactPicker,
        isStatic: true
    });

    registerType('graphic-picker', {
        Component: GraphicPicker,
        isStatic: true
    });

    registerType('item-picker', {
        Component: ItemPicker,
        isStatic: true
    });

    registerType('masked-field', {
        Component: ReactInputMask,
        labelBefore: true,
        getValue: (ref: RefObject<HTMLInputElement>) => {
            return ref.current?.value;
        }
    });

    registerType('radio-toggle', {
        Component: RadioToggle,
        isStatic: true
    });

    registerType('react-select', {
        Component: ReactSelect,
        getValue: (ref: RefObject<string | undefined>) => ref.current
    });

    registerType('recaptcha', {
        Component: FormRecaptcha,
        isStatic: true
    });

    registerType('rich-number', {
        Component: RichNumber,
        getValue: (ref: RefObject<HTMLInputElement>) => ref.current?.value
    });

    registerType('rich-text', {
        Component: ({
            componentRef,
            readOnly,
            quillOptions,
            theme,
            ...sharedProps
        }: FieldComponentProps & {
            readOnly?: boolean;
            quillOptions?: QuillOptions;
        }) => {
            return (
                <EditorWrapper
                    ref={componentRef}
                    {...sharedProps}
                    options={{ readOnly, ...(quillOptions || {}) }}
                    theme={theme}
                />
            );
        },
        getValue: (ref) => {
            return ref.current.quill && ref.current.quill.getText().trim()
                ? ref.current.quill.root.innerHTML
                : null;
        }
    });

    registerType('timecode', {
        Component: TimeCode,
        isStatic: true
    });

    registerType('user-picker', {
        Component: UserPicker,
        isStatic: true
    });
};
