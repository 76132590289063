import { pathWithParams } from '@he-novation/config/paths/herawApiPaths';
import axios from 'axios';
import { objectToFormData } from '../utils/form';

const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-requested-with': 'XMLHttpRequest'
};

// axios reads XSRF-TOKEN cookie automatically
const uploadHeaders = {
    Accept: 'application/json',
    'x-requested-with': 'XMLHttpRequest'
};

const removeContentTypeHeaders = (headers) => {
    delete headers['Content-Type'];
    return headers;
};

export const buildOptions = (options: any = {}, removeContentType) => {
    const headers = options.noDefaultHeaders
        ? options.headers
        : options.headers
        ? { ...defaultHeaders, ...options.headers }
        : defaultHeaders;
    if (removeContentType) removeContentTypeHeaders(headers);

    let controller;
    let signal: AbortSignal | undefined = options.signal;
    if (options.getAbortController) {
        controller = new AbortController();
        signal = controller.signal;
        options.getAbortController(controller);
    }

    if (typeof options.body === 'object') {
        for (const key in options.body) {
            if (options.body[key] instanceof Date)
                options.body[key] = options.body[key].toISOString();
        }
        options.body =
            options.headers?.['content-type'] === 'application/octet-stream'
                ? options.body
                : JSON.stringify(options.body);
    }
    const { redirect, ..._options } = options;
    return {
        headers,
        mode: 'cors',
        credentials: 'include',
        signal,
        ..._options
    };
};

export const fetchCallback = (options) => async (r) => {
    if (options.rawResponse) return r;
    if (r.status > 299) {
        const response = await r.json();
        if (response?.data?.redirect) {
            window.location.href = response?.data?.redirect;
        }
        throw response;
    } else {
        return r.status !== 204 ? r.text().then((r) => (r ? JSON.parse(r) : r)) : null;
    }
};

export const apiFetch = (url, options: any = {}, removeContentType?: boolean) => {
    if (options.query || options.params) {
        url = pathWithParams(url, options.params || {}, options.query || {});
    }
    return fetch(
        url.startsWith('http') || options.rawUrl
            ? url
            : `${process.env.API_URL}/${url.replace(/^\//, '')}`,
        buildOptions(options, removeContentType)
    ).then(fetchCallback(options));
};

// uses axios since fetch doesn't handle progress
export const fetchAsFormData = (
    url,
    {
        body,
        method,
        useUrlSearchParams = false,
        useRawError = false,
        useRawResponse = false,
        ...options
    }: any = {}
) => {
    if (options.query || options.params) {
        url = pathWithParams(url, options.params || {}, options.query || {});
    }

    if (typeof body === 'string') {
        throw new Error('File upload requires body to be passed as object instead of string');
    }
    if (useUrlSearchParams) {
        const params = new URLSearchParams();
        Object.keys(body).forEach((k) => params.append(k, body[k]));
        body = params;
    }

    body =
        body instanceof FormData || body instanceof URLSearchParams ? body : objectToFormData(body);
    return axios({
        url: url.startsWith('http') ? url : `${process.env.API_URL}/${url.replace(/^\//, '')}`,
        method,
        ...options,
        data: body,
        withCredentials: true,
        headers: options.headers ? { ...uploadHeaders, ...options.headers } : uploadHeaders
    })
        .then((r) => (useRawResponse ? r : r.data))
        .catch((e) => {
            throw useRawError ? e : e.response.data;
        });
};
