import styles from './SidePanelSubtitlesHeader.module.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subtitlesFileLink } from '@he-novation/config/paths/herawFrontUris';
import {
    SUBTITLES,
    SUBTITLES_CREATE,
    SUBTITLES_GENERATE
} from '@he-novation/config/paths/modals.constants';
import { Subtitle } from '@he-novation/config/types/subtitle.types';
import { ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { MultiActionButton } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Tooltip } from '@he-novation/design-system/components/widgets/Tooltip/Tooltip';
import { Theme } from '@he-novation/design-system/enums';
import { downloadLink } from '@he-novation/front-shared/utils/downloadLink';
import cn from 'classnames';
import { useAtom } from 'jotai';

import { activeSubtitlesAtom } from '$atoms/file-atoms/subtitle-atoms';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { subtitlesFetch } from '$redux/content/file/fileActions';
import { i18nLanguageAndCountryCodesSelector } from '$redux/i18n/i18nSelectors';
import { hasPluginSelector } from '$redux/user/userSelectors';
import { LanguagesAndCountryCode } from '$types/localeTypes';

type SidePanelSubtitlesHeaderProps = {
    fileUuid: string;
    fileVersion: number;
    activeSubtitle?: Subtitle;
    subtitles: Subtitle[];
    frameRate: number;
    className?: string;
};

export function SidePanelSubtitlesHeader({
    fileUuid,
    fileVersion,
    activeSubtitle,
    subtitles,
    frameRate,
    className
}: SidePanelSubtitlesHeaderProps) {
    const dispatch = useDispatch();

    const { openModal } = useModal();

    const { hasPluginSpotl } = useSelector(hasPluginSelector('spotl'));
    const languagesAndCountryCodes = useSelector(i18nLanguageAndCountryCodesSelector);
    const { t } = useTranslate();

    const [_, setActiveSubtitles] = useAtom(activeSubtitlesAtom);

    return (
        <div id="side-panel-subtitles-header" className={cn(styles.subtitlesHeader, className)}>
            <FormField
                type={'react-select'}
                id="subtitles-panel-select"
                theme={Theme.Dark}
                onChange={(_e, value) => {
                    setActiveSubtitles(value !== 'null' ? [value] : []);
                }}
                value={activeSubtitle ? activeSubtitle.uuid : 'null'}
                isOptionDisabled={(option: { disabled?: boolean }) => option.disabled}
                className={styles.select}
                menuClassName="subtitles-select-menu"
                options={[
                    ...subtitles.map(({ name, uuid, spotlTranslation, locale }) => ({
                        disabled: spotlTranslation && spotlTranslation.status !== 'done',
                        label: (
                            <span className="name">
                                {name} - {locale}
                            </span>
                        ),
                        value: uuid
                    }))
                ]}
            />

            {activeSubtitle && languagesAndCountryCodes && (
                <Tooltip
                    className={styles.language}
                    content={getSubtitleLocale(activeSubtitle.locale, languagesAndCountryCodes)}
                >
                    {activeSubtitle.locale}
                </Tooltip>
            )}

            <MultiActionButton
                className={styles.addSubtitles}
                icon={['subtitles', 'plus']}
                tone={ButtonTone.Hoverable}
                actions={[
                    {
                        icon: 'upload',
                        children: t('subtitles.Upload subtitles'),
                        onClick: () =>
                            openModal(
                                SUBTITLES,
                                {
                                    onClose: () => dispatch(subtitlesFetch(fileUuid, fileVersion))
                                },
                                {
                                    uuid: fileUuid,
                                    v: fileVersion
                                },
                                false
                            )
                    },
                    {
                        icon: ['subtitles', 'pencil'],
                        children: t('subtitles.Create new subtitles'),
                        onClick: () =>
                            openModal(
                                SUBTITLES_CREATE,
                                {
                                    onClose: () => {}
                                },
                                {
                                    uuid: fileUuid,
                                    v: fileVersion
                                }
                            )
                    },
                    hasPluginSpotl && {
                        icon: ['subtitles', 'cog'],
                        children: t('subtitles.Generate subtitles'),
                        onClick: () => openModal(SUBTITLES_GENERATE, { fileUuid, fileVersion })
                    }
                ]}
            />

            {activeSubtitle && (
                <MultiActionButton
                    className={styles.downloadSubtitles}
                    icon={'download'}
                    tone={ButtonTone.Hoverable}
                    actions={[
                        activeSubtitle.assetUrl
                            ? {
                                  children: t('common..Download {{file}}', { file: 'source' }),
                                  href: activeSubtitle.assetUrl,
                                  external: true,
                                  download: activeSubtitle.name
                              }
                            : null,
                        {
                            children: t('player.Export {{format}}', { format: 'SRT' }),
                            onClick: () =>
                                downloadLink(
                                    subtitlesFileLink(activeSubtitle.uuid, 'srt', frameRate),
                                    activeSubtitle.name.replace(/\.[a-zA-Z0-9]*$/, '.srt'),
                                    true
                                )
                        },
                        {
                            children: t('player.Export {{format}}', { format: 'STL' }),
                            onClick: () =>
                                downloadLink(
                                    subtitlesFileLink(activeSubtitle.uuid, 'stl-ebu', frameRate),
                                    activeSubtitle.name.replace(/\.[a-zA-Z0-9]*$/, '.stl'),
                                    true
                                )
                        },
                        {
                            children: t('player.Export {{format}}', { format: 'VTT' }),
                            onClick: () =>
                                downloadLink(
                                    subtitlesFileLink(activeSubtitle.uuid, 'vtt', frameRate),
                                    activeSubtitle.name.replace(/\.[a-zA-Z0-9]*$/, '.vtt'),
                                    true
                                )
                        }
                    ]}
                />
            )}
        </div>
    );
}

function getSubtitleLocale(
    locale: string,
    languagesAndCountryCodes: LanguagesAndCountryCode[]
): string {
    let languagesAndCountryCode = languagesAndCountryCodes.find((l) => l.value === locale);
    if (!languagesAndCountryCode) {
        const lang = locale.replace(/-[a-z]{2}$/, '');
        languagesAndCountryCode = languagesAndCountryCodes.find((l) => l.languageCode === lang);
    }
    return languagesAndCountryCode?.text || locale;
}
