import React, { SyntheticEvent } from 'react';
import Avatar from './Avatar';
export { AvatarSize } from './Avatar';

type AvatarItemProps = {
    uuid: string;
    name: string;
    picture?: string | null;
    onClick?: (e: SyntheticEvent<Element>, data: { uuid: string; name: string }) => void;
    tooltip?: boolean;
    [p: string]: any;
};

export class AvatarItem extends React.Component<AvatarItemProps> {
    public static propsToTooltip = ({ name }: { name: string }) => name;
    render() {
        const { uuid, name, picture, onClick, tooltip, ...avatarProps } = this.props;
        return (
            <Avatar
                tooltip={tooltip && AvatarItem.propsToTooltip({ name })}
                onClick={
                    typeof onClick === 'function' ? (e) => onClick(e, { uuid, name }) : undefined
                }
                picture={picture}
                initials={name[0] + name[1]}
                roundedSquare
                {...avatarProps}
            />
        );
    }
}
