import { FolderRole } from '../types/folder.types';
import { ROLES } from '../constants/projects.constants';

export const ROLE_OWNER = FolderRole.ROLE_OWNER;
export const ROLE_MANAGER = FolderRole.ROLE_MANAGER;
export const ROLE_REPORTER = FolderRole.ROLE_REPORTER;
export const ROLE_PROVIDER = FolderRole.ROLE_PROVIDER;
export const ROLE_SPECTATOR = FolderRole.ROLE_SPECTATOR;

export const acl = {
    player: {
        show: (role?: FolderRole | null) =>
            !!role &&
            [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR].indexOf(
                role
            ) !== -1
    },

    content: {
        show: (role?: FolderRole | null) =>
            !!role &&
            [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR].indexOf(
                role
            ) !== -1,

        showRoles: () => [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR],

        download: (role: FolderRole) =>
            [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR].indexOf(
                role
            ) !== -1,

        export: (role: FolderRole) =>
            [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR].indexOf(
                role
            ) !== -1
    },

    subtitles: {
        manage: (role?: FolderRole | null) =>
            !!role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].includes(role)
    },

    summary: {
        show: () => false,

        download: () => false
    },

    comments: {
        show: (role?: FolderRole) =>
            !!role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER].includes(role),

        add: (role?: FolderRole) =>
            role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER].includes(role),

        modify: (role: FolderRole) =>
            [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER].indexOf(role) !== -1
    },

    members: {
        show: (role: FolderRole) =>
            [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER].indexOf(role) !== -1,

        showRolesAll: () => [
            ROLE_OWNER,
            ROLE_MANAGER,
            ROLE_PROVIDER,
            ROLE_REPORTER,
            ROLE_SPECTATOR
        ],

        add: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        modify: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        del: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1
    },

    files: {
        add: (role?: FolderRole | null) =>
            !!role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].indexOf(role) !== -1,

        modify: (role: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),

        setFinal: (role?: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),

        setStatus: (role?: FolderRole | null) =>
            role && [ROLE_OWNER, ROLE_MANAGER, ROLE_REPORTER, ROLE_PROVIDER].includes(role),

        voteFinal: (role?: FolderRole | null) =>
            role && [ROLE_PROVIDER, ROLE_REPORTER].indexOf(role) !== -1,

        move: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        setInfos: (role?: FolderRole) =>
            role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].includes(role),
        del: (role?: FolderRole | null) =>
            !!role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].indexOf(role) !== -1,

        sendEmail: (role: FolderRole) => [ROLE_MANAGER, ROLE_OWNER].includes(role)
    },

    folders: {
        add: (role?: FolderRole | null) =>
            !!role && [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER].includes(role),

        cast: (role?: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),

        modify: (role?: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),

        modifyRoles: () => [ROLE_OWNER, ROLE_MANAGER],

        move: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        moveRoles: () => [ROLE_OWNER, ROLE_MANAGER],

        copyTree: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        del: (role?: FolderRole | null) => !!role && [ROLE_OWNER, ROLE_MANAGER].includes(role),

        castSync: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].includes(role),

        sendEmail: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].includes(role),
        share: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].includes(role)
    },

    logs: {
        show: (role: FolderRole) =>
            [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER].indexOf(role) !== -1
    },

    rights: {
        all: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1
    },

    projects: {
        add: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        list: (role: FolderRole) => [ROLE_OWNER, ROLE_MANAGER].indexOf(role) !== -1,

        addEvent: (role?: ROLES | null) =>
            role && [ROLES.MANAGER, ROLES.PROVIDER, ROLES.REPORTER].includes(role)
    },

    plugin: {
        free: (): FolderRole[] => [],

        basic: () => [ROLE_REPORTER],

        basicDefault: () => ROLE_REPORTER,

        premium: () => [ROLE_OWNER, ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR],

        premiumDefault: () => ROLE_REPORTER
    },

    getAvailableRoles: (hasPluginRoles: boolean) => {
        let roles = [ROLE_REPORTER];
        if (hasPluginRoles) {
            roles = [ROLE_MANAGER, ROLE_PROVIDER, ROLE_REPORTER, ROLE_SPECTATOR];
        }

        return roles;
    }
};
