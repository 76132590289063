let _isTouchDevice = false;
if (typeof window !== 'undefined') {
    const onTouchStart = () => {
        _isTouchDevice = true;
        window.removeEventListener('touchstart', onTouchStart);
    };
    window.addEventListener('touchstart', onTouchStart);
}
export function isTouchDevice() {
    return _isTouchDevice;
}
