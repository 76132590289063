export const keyToKeyCode = (key: number | string) => {
    switch (key) {
        case 1:
            return 49;
        case 2:
            return 50;
        case 3:
            return 51;
        case 'backspace':
            return 8;
        case 'down':
            return 40;
        case 'c':
            return 67;
        case 'enter':
            return 13;
        case 'escape':
            return 27;
        case 'left':
            return 37;
        case 'right':
            return 39;
        case 'shift':
            return 16;
        case 'space':
            return 32;
        case 'tab':
            return 9;
        case 'up':
            return 38;
        case 'd':
            return 68;
        case 'del':
            return 46;
        case 'e':
            return 69;
        case 'i':
            return 73;
        case 'm':
            return 77;
        case 'n':
            return 78;
        case 'o':
            return 79;
        case 'pageUp':
            return 33;
        case 'pageDown':
            return 34;
        case 's':
            return 83;
        case 't':
            return 84;
        case 'v':
            return 86;
        case 'x':
            return 88;
    }
};
