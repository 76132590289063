import { pluginPaths } from '@he-novation/config/paths/herawApiPaths';
import {
    PluginConfigurationCreationSchema,
    PluginConfigurationSchema
} from '@he-novation/config/types/payloads/plugin.payload';
import { PluginConfigurationType } from '@he-novation/config/types/plugin.types';
import { apiFetch } from './apiFetch';

export const asyncPluginConfigurationCreate = async (body: PluginConfigurationCreationSchema) =>
    apiFetch(pluginPaths.single, {
        method: 'POST',
        body
    });

export const asyncPluginConfigurationUpdate = (
    pluginUuid: string,
    body: PluginConfigurationSchema
): Promise<PluginConfigurationType> =>
    apiFetch(pluginPaths.specific, {
        params: {
            pluginUuid
        },
        method: 'PATCH',
        body
    });

export const asyncPluginConfigurationDelete = async (pluginUuid: string) =>
    apiFetch(pluginPaths.specific, {
        method: 'DELETE',
        params: {
            pluginUuid
        }
    });
export const asyncPluginAuthUrlFetch = async (type: string) =>
    apiFetch(pluginPaths.auth, {
        method: 'GET',
        params: {
            type
        }
    }).then((r: { authUrl: string; state?: string }) => {
        if (r?.authUrl) window.location.href = r.authUrl;
        return r;
    });
