import {
    AreaSelectionData,
    AreaSelectionOutputData,
    AreaSelectionType
} from '@he-novation/config/types/area-selection.types';
import { useSetAtom } from 'jotai';
import { areaSelectionAtom } from '../atoms/file-atoms/area-selection-atom';

export function useAreaSelection() {
    const setAreaSelection = useSetAtom(areaSelectionAtom);

    return {
        toggleAreaSelection: (toggle?: boolean) => {
            setAreaSelection((prev) => ({
                ...prev,
                toggled: typeof toggle === 'undefined' ? !prev.toggled : toggle
            }));
        },
        startShape(shape: AreaSelectionType) {
            setAreaSelection((prev) => ({
                ...prev,
                toggled: true,
                type: shape
            }));
        },
        pushShape(data: AreaSelectionData) {
            setAreaSelection((prev) => ({
                ...prev,
                selection: [...prev.selection, data]
            }));
        },
        spliceShape(index: number) {
            setAreaSelection((prev) => ({
                ...prev,
                selection: prev.selection.filter((_, i) => i !== index)
            }));
        },
        resetShapes() {
            setAreaSelection((prev) => ({
                ...prev,
                display: undefined,
                selection: []
            }));
        },

        displayShape(data: AreaSelectionOutputData[]) {
            setAreaSelection((prev) => ({
                ...prev,
                display: data
            }));
        }
    };
}
