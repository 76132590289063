import { msTimeCodeToSeconds } from '@he-novation/lib-timecodes';
import { useSetAtom } from 'jotai';
import { audioAtom } from '../atoms/file-atoms/audio-atom';

export function useAudioControls() {
    const setAudioState = useSetAtom(audioAtom);

    return {
        play: () => setAudioState((audioState) => ({ ...audioState, isPlaying: true })),
        pause: () => setAudioState((audioState) => ({ ...audioState, isPlaying: false })),
        togglePlay: () =>
            setAudioState((audioState) => ({ ...audioState, isPlaying: !audioState.isPlaying })),
        setCurrentTime: (seconds: number) =>
            setAudioState((audioState) => ({ ...audioState, targetTime: seconds })),
        setTimecode: (timecode: string) => {
            const targetTime = msTimeCodeToSeconds(timecode);
            setAudioState((audioState) => ({ ...audioState, targetTime }));
        },
        toggleMute: (isMuted?: boolean) =>
            setAudioState((audioState) => {
                isMuted = isMuted ?? !audioState.isMuted;
                localStorage.setItem('muted', isMuted ? 'true' : 'false');
                return { ...audioState, isMuted };
            }),
        setIsLooping: (isLooping: boolean) =>
            setAudioState((audioState) => ({ ...audioState, isLooping })),
        setVolume: (volume: number) =>
            setAudioState((audioState) => {
                if (volume > 0 && audioState.isMuted) {
                    audioState.isMuted = false;
                    localStorage.setItem('muted', 'false');
                }
                return { ...audioState, volume };
            }),
        setSelectedRange: (range: [number, number] | null) =>
            setAudioState((as) => ({
                ...as,
                selectedRange: range
            })),
        setTimecodeRange: ([tcIn, tcOut]: [string, string]) =>
            setAudioState((as) => ({
                ...as,
                selectedRange: [msTimeCodeToSeconds(tcIn), msTimeCodeToSeconds(tcOut)]
            }))
    };
}
